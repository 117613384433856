import React from 'react'
import moment from 'moment'
import ReactStars from 'react-stars'

import Button from '../../atoms/Button/Button.jsx'
import AnswerForm from '../../molecules/Answers/AnswerForm.jsx'
import '../Feedback/Feedback.scss'

const Feedback = ({
    culture,
    feedback,
    deleteFeedbackHandler,
    answerFeedbackHandler,
    getUserInfoHandler,
    approveFeedbackHandler,
}) => {
    const momentDate = moment(feedback.creationDate).format('lll')
    return (
        <div className="feedbackContainer" key={feedback.id}>
            <div className="feedback">
                <div className="info">
                    <Button text={feedback.userNickName} onClick={() => getUserInfoHandler(feedback.userId)} />
                    <h4>{momentDate}</h4>
                </div>
                <div className="description">
                    <div>
                        <h3>{feedback.title}</h3>
                    </div>

                    <div className="ratingContainer">
                        <em className="ratingDescription">Overall ranking</em>
                        <ReactStars
                            count={5}
                            size={24}
                            edit={false}
                            color2={'#ffd700'}
                            value={feedback.useExperienceVote}
                        />
                        <em className="ratingDescription">Quality/price ratio</em>
                        <ReactStars
                            count={5}
                            size={24}
                            edit={false}
                            color2={'#ffd700'}
                            value={feedback.serviceQualityVote}
                        />
                    </div>
                    <p>
                        <strong>{feedback.description}</strong>
                    </p>
                </div>

                <div className="actions">
                    <Button text="Read" onClick={() => approveFeedbackHandler(feedback.id)} />
                    <Button text="Delete" onClick={() => deleteFeedbackHandler(feedback.id)} />
                </div>
            </div>
            <AnswerForm culture={culture} answerQuestionHandler={answerFeedbackHandler} questionId={feedback.id} />
        </div>
    )
}

export default Feedback
