import React from 'react'

import DestinationNote from '../../molecules/Destinations/DestinationNote.jsx'
import '../DestinationList/DestinationNotesList.scss'

const DestinationNotesList = ({
    list,
    culture,
    clearDestinationNote,
    setDestinationNote,
    changeDestinationNote,
    selectDestinationNote,
}) => {
    const destinationNotes = list.map((destination, index) => {
        return (
            <li className="destinationNotesListItem" key={index}>
                <DestinationNote
                    culture={culture}
                    destination={destination}
                    key={destination.code}
                    clearDestinationNoteHandler={clearDestinationNote}
                    setDestinationNoteHandler={setDestinationNote}
                    changeDestinationNoteHandler={changeDestinationNote}
                    selectDestinationNoteHandler={selectDestinationNote}
                />
            </li>
        )
    })

    return (
        <div>
            <ul className="destinationNotesList">{destinationNotes}</ul>
        </div>
    )
}

export default DestinationNotesList
