import React, { useRef, useEffect, useState } from 'react'
import { replace, forEach, isEmpty, filter, isNil, keys } from 'lodash'
import Dropzone from 'react-dropzone'
import './BaseTemplate.scss'
import './BaseBannerCategoryTemplate.scss'
import categoryBanners from '../../../model/categoryBanners'

const baseStyleLong = {
    width: 200,
    height: 100,
    borderWidth: 2,
    borderColor: '#666',
    borderStyle: 'dashed',
    borderRadius: 5,
}
const baseStyleSquare = {
    width: 100,
    height: 100,
    borderWidth: 2,
    borderColor: '#666',
    borderStyle: 'dashed',
    borderRadius: 5,
}
const activeStyle = {
    borderStyle: 'solid',
    borderColor: '#6c6',
    backgroundColor: '#eee',
}
const rejectStyle = {
    borderStyle: 'solid',
    borderColor: '#c66',
    backgroundColor: '#eee',
}

const BaseBannerCategoryTemplate = ({ _files, _html, onChangeTemplate }) => {
    const _template = `
        <div class="grid_ads-container">
            <div class="grid_ads-medium">
                <a
                class="banner__link"
                href="[[href0]]"
                title="[[title0]]"
                >
                <figure class="banner__figure">
                    <img
                    class="banner__img"
                    src="[[src0]]"
                    alt="[[alt0]]"
                    />
                </figure>
                </a>
            </div>
            <div class="grid_ads-medium">
                <a
                class="banner__link"
                href="[[href1]]"
                title="[[title1]]"
                >
                <figure class="banner__figure">
                    <img
                    class="banner__img"
                    src="[[src1]]"
                    alt="[[alt1]]"
                    />
                </figure>
                </a>
            </div>
            <div class="grid_ads-small">
                <a
                class="banner__link"
                href="[[href2]]"
                title="[[title2]]"
                >
                <figure class="banner__figure">
                    <img
                    class="banner__img"
                    src="[[src2]]"
                    alt="[[alt2]]"
                    />
                </figure>
                </a>
            </div>
            <div class="grid_ads-small">
                <a
                class="banner__link"
                href="[[href3]]"
                title="[[title3]]"
                >
                <figure class="banner__figure">
                    <img
                    class="banner__img"
                    src="[[src3]]"
                    alt="[[alt3]]"
                    />
                </figure>
                </a>
            </div>
            <div class="grid_ads-small">
                <a
                class="banner__link"
                href="[[href4]]"
                title="[[title4]]"
                >
                <figure class="banner__figure">
                    <img
                    class="banner__img"
                    src="[[src4]]"
                    alt="[[alt4]]"
                    />
                </figure>
                </a>
            </div>
        </div>
        `

    const [html, setHtml] = useState(_html !== undefined ? _html : _template)
    const [files, setFiles] = useState(_files === undefined ? {} : _files)

    const didMountRef = useRef(false)
    useEffect(() => {
        if (didMountRef.current) {
            if (onChangeTemplate) {
                onChangeTemplate({ html, files: files.map(f => f.fileName) })
            }
        } else didMountRef.current = true

        return () => {
            files.forEach(file => URL.revokeObjectURL(file.preview))
            setFiles(files)
        }
    }, [])

    const handleUpload = (files, index) => {
        const file = files[0]
        const idx = index
        const name = 'image'

        categoryBanners.upload(name, file).then(res => {
            const updatedFiles = updateKeyByObject({
                index: idx,
                objectToSet: { fileName: res.fileName, link: res.link, src: res.link },
            })
            updateHtml({ updatedFiles })
        })
    }

    const updateHtml = ({ updatedFiles }) => {
        let templateCopy = _template || ''

        let newHtml = templateCopy

        for (let index = 0; index < keys(updatedFiles).length; index++) {
            const file = updatedFiles[index]
            forEach(['href', 'title', 'src', 'alt'], currentKey => {
                const newValue = file[currentKey]
                if (!isNil(newValue) && !isEmpty(newValue)) {
                    newHtml = replace(newHtml, `[[${currentKey}${index}]]`, newValue)
                }
            })
        }
        setHtml(newHtml)
        if (onChangeTemplate && updatedFiles) {
            onChangeTemplate({
                html: newHtml,
                files: filter(updatedFiles, uf => !isNil(uf.fileName) && !isEmpty(uf.fileName)).map(f => f.fileName),
            })
        }
    }

    const updateKeyByObject = ({ index, objectToSet }) => {
        let newFiles = isNil(files) ? {} : { ...files }
        const prevFile = files[index] || {}
        newFiles[index] = { ...prevFile, ...objectToSet }
        setFiles(newFiles)
        return newFiles
    }

    const updateKey = ({ index, key, value }) => {
        let newFiles = isNil(files) ? {} : { ...files }
        const prevFile = files[index] || {}
        let objectToSet = {}
        objectToSet[key] = value
        newFiles[index] = { ...prevFile, ...objectToSet }
        setFiles(newFiles)
        return newFiles
    }

    const onChangeHref = (e, index) => {
        const updatedFiles = updateKey({ index, key: 'href', value: e.target.value })
        updateHtml({ updatedFiles })
    }

    const onChangeTitle = (e, index) => {
        const updatedFiles = updateKey({ index, key: 'title', value: e.target.value })
        updateHtml({ updatedFiles })
    }

    const onChangeSrc = (e, index) => {
        const updatedFiles = updateKey({ index, key: 'src', value: e.target.value })
        updateHtml({ updatedFiles })
    }

    const onChangeAlt = (e, index) => {
        const updatedFiles = updateKey({ index, key: 'alt', value: e.target.value })
        updateHtml({ updatedFiles })
    }

    const thumbs = index => {
        const _file = files[index]
        return _file && _file.src ? (
            <figure className="banner__figure" key={_file.src}>
                <img className="banner__img" src={_file.src} />
            </figure>
        ) : (
            <></>
        )
    }

    const renderUploader = ({ index }) => {
        return (
            <Dropzone onDrop={files => handleUpload(files, index)}>
                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
                    let styles = { ...baseStyleLong }
                    styles = isDragActive ? { ...styles, ...activeStyle } : styles
                    styles = isDragReject ? { ...styles, ...rejectStyle } : styles
                    return (
                        <div {...getRootProps()} style={styles}>
                            <input {...getInputProps()} />
                            <div>{isDragAccept ? 'Drop' : 'Drag'} files here...</div>
                            <div>({index})</div>
                            {isDragReject && <div>Unsupported file type...</div>}
                        </div>
                    )
                }}
            </Dropzone>
        )
    }

    const renderLinkTitleAltInputs = index => {
        return (
            <>
                <div>
                    <label>Link:</label>
                    <input type="text" onChange={e => onChangeHref(e, index)} />
                </div>

                <div>
                    <label>Title:</label>
                    <input type="text" onChange={e => onChangeTitle(e, index)} />
                </div>
                <div>
                    <label>Alt image:</label>
                    <input type="text" onChange={e => onChangeAlt(e, index)} />
                </div>
            </>
        )
    }

    const renderMediumInput = ({ index }) => (
        <div className="grid_ads-medium">
            {renderUploader({ index })}
            {renderLinkTitleAltInputs(index)}
        </div>
    )

    const renderSquareInput = ({ index }) => (
        <div className="grid_ads-small">
            {renderUploader({ index })}
            {renderLinkTitleAltInputs(index)}
        </div>
    )
    return (
        <div className="baseBannerCategoryTemplate banner__details">
            <div className="insertArea">
                <div className="preview">
                    <div className="grid_ads-container">
                        {renderMediumInput({
                            index: 0,
                        })}
                        {renderMediumInput({
                            index: 1,
                        })}
                        {renderSquareInput({
                            index: 2,
                        })}
                        {renderSquareInput({
                            index: 3,
                        })}
                        {renderSquareInput({
                            index: 4,
                        })}
                    </div>
                </div>
            </div>
            <div className="baseBannerCategoryTemplatePreview">
                <div className="grid_ads-container">
                    <div className="grid_ads-medium">
                        <div className="banner__link">{thumbs(0)}</div>
                    </div>
                    <div className="grid_ads-medium">
                        <div className="banner__link">{thumbs(1)}</div>
                    </div>
                    <div className="grid_ads-small">
                        <div className="banner__link">{thumbs(2)}</div>
                    </div>
                    <div className="grid_ads-small">
                        <div className="banner__link">{thumbs(3)}</div>
                    </div>
                    <div className="grid_ads-small">
                        <div className="banner__link">{thumbs(4)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BaseBannerCategoryTemplate
