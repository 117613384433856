import { delay } from 'redux-saga'
import { call, put, takeEvery } from 'redux-saga/effects'

import * as commonActions from '../common/common.actions'
import * as modalActions from '../modal/modal.actions'
import modal from '../modal/modal.types'
import messages from '../../helpers/messages'

import { ACTION_TYPES } from './reassortmentNotifications.actions'
import reassortmentNotifications from '../../model/reassortmentNotifications'
import * as actions from './reassortmentNotifications.actions'
import { logout } from '../user/user.actions'

export function* search(action) {
    try {
        const result = yield call(reassortmentNotifications.searchReassortmentNotifications, action.searchTerm)
        yield put(actions.reassortmentNotificationsReceived(result))
    } catch (e) {
        if (e.status == 401) {
            yield put(logout())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else if (e.status == 400) {
            // Instead of yield call(pjControl.turnOn(ip, port, model)); use the signature I suggested: yield call([pjControl,'turnOn'],ip, port, model)
            var errorMessage = yield call([e, 'text'])
            yield put(commonActions.showMessage(JSON.parse(errorMessage).message))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* deleteLine(action) {
    try {
        yield call(reassortmentNotifications.deleteReassortmentNotification, action.payload)
        yield put(modalActions.showModal(modal.types.LOADING))
        yield delay(500)
        yield put(actions.reassortmentNotificationsDeleted(action.payload))
        yield put(commonActions.showMessage(messages.getSuccessMessage('Reassortment notification line deleted')))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        commonActions.handleUnauthorized(e, logout())
    }
}

export default function*() {
    yield takeEvery(ACTION_TYPES.SEARCH_REASSORTMENTNOTIFICATIONS_REQUEST, search)
    yield takeEvery(ACTION_TYPES.DELETE_REASSORTMENTNOTIFICATIONS_REQUEST, deleteLine)
}
