import React, { Component } from 'react'
import { replace } from 'lodash'

import Dropzone from 'react-dropzone'

import './SingleTemplate.scss'
import './SingleBannerCategoryTemplate.scss'
import categoryBanners from '../../../model/categoryBanners'

const baseStyleSuperLong = {
    width: 400,
    height: 200,
    borderWidth: 2,
    borderColor: '#666',
    borderStyle: 'dashed',
    borderRadius: 5,
}

class SingleBannerCategoryTemplate extends Component {
    constructor(props) {
        super(props)
        this.template = `
        <div class="grid_ads-container">
            <div class="grid_ads-large">
                <a
                class="banner__link"
                href="[[href0]]"
                title="[[title0]]"
                >
                <figure class="banner__figure">
                    <img
                    class="banner__img"
                    src="[[src0]]"
                    alt="[[alt0]]"
                    />
                </figure>
                </a>
            </div>
        </div>
        `
        this.state = {
            html: props.html !== '' ? props.html : this.template,
            files: props.files || [],
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const mappedFiles = prevState.files.map(f => f.fileName)
        this.props.onChangeTemplate({ html: prevState.html, files: mappedFiles })
    }

    componentWillUnmount() {
        // Make sure to revoke the data uris to avoid memory leaks
        this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
    }

    handleUpload = (files, index) => {
        const file = files[0]
        const idx = index
        const name = 'image'

        categoryBanners.upload(name, file).then(res => {
            this.setStateByObject({
                index: idx,
                objectToSet: {
                    fileName: res.fileName,
                    preview: URL.createObjectURL(file),
                },
            })
            this.onChangeSrc({ target: { value: res.link } }, idx)

            this.updateHtml()
        })
    }

    updateHtml() {
        let templateCopy = this.template || ''
        this.setState(prevState => {
            let newHtml = templateCopy
            ;['href', 'title', 'src', 'alt'].forEach(currentKey => {
                const newValue =
                    prevState.files[0] === undefined || prevState.files[0][currentKey] === undefined
                        ? `[[${currentKey}0]]`
                        : prevState.files[0][currentKey]
                newHtml = replace(newHtml, `[[${currentKey}0]]`, newValue)
            })

            return {
                ...prevState,
                html: newHtml,
            }
        })
    }
    setStateByObject({ index, objectToSet }) {
        this.setState(prevState => {
            const newFiles = prevState == undefined || prevState.files === undefined ? [] : [...prevState.files]
            const prevFile = newFiles[index] || {}
            newFiles[index] = Object.assign(prevFile, objectToSet)
            return {
                ...prevState,
                files: newFiles,
            }
        })
    }

    onChangeHref(e, index) {
        const val = e.target.value
        const idx = index
        this.setStateByObject({
            index: idx,
            objectToSet: {
                href: val,
            },
        })

        this.updateHtml()
    }

    onChangeTitle(e, index) {
        const val = e.target.value
        const idx = index
        this.setStateByObject({
            index: idx,
            objectToSet: {
                title: val,
            },
        })

        this.updateHtml()
    }

    onChangeSrc(e, index) {
        const val = e.target.value
        const idx = index
        this.setStateByObject({
            index: idx,
            objectToSet: {
                src: val,
            },
        })

        this.updateHtml()
    }

    onChangeAlt(e, index) {
        const val = e.target.value
        const idx = index
        this.setStateByObject({
            index: idx,
            objectToSet: {
                alt: val,
            },
        })

        this.updateHtml()
    }

    render() {
        const { files } = this.state

        const thumbs = files.map(file => (
            <figure className="banner__figure" key={file.fileName}>
                <img className="banner__img" src={file.preview} />
            </figure>
        ))

        const renderLargeInput = index => {
            const activeStyle = {
                borderStyle: 'solid',
                borderColor: '#6c6',
                backgroundColor: '#eee',
            }
            const rejectStyle = {
                borderStyle: 'solid',
                borderColor: '#c66',
                backgroundColor: '#eee',
            }
            let styles = { ...baseStyleSuperLong }
            return (
                <div className="grid_ads-large">
                    <div name="image">
                        <Dropzone onDrop={files => this.handleUpload(files, index)} style={styles}>
                            {({ getRootProps, getInputProps, isDragAccept, isDragActive, isDragReject }) => {
                                styles = isDragActive ? { ...styles, ...activeStyle } : styles
                                styles = isDragReject ? { ...styles, ...rejectStyle } : styles
                                return (
                                    <div {...getRootProps()} style={styles}>
                                        <input {...getInputProps()} />
                                        <div>{isDragAccept ? 'Drop' : 'Drag'} files here...</div>
                                        <div>({index})</div>
                                        {isDragReject && <div>Unsupported file type...</div>}
                                    </div>
                                )
                            }}
                        </Dropzone>
                    </div>

                    <div>
                        <label>Link:</label>
                        <input type="text" onChange={e => this.onChangeHref(e, index)} />
                    </div>

                    <div>
                        <label>Title:</label>
                        <input type="text" onChange={e => this.onChangeTitle(e, index)} />
                    </div>
                    <div>
                        <label>Alt image:</label>
                        <input type="text" onChange={e => this.onChangeAlt(e, index)} />
                    </div>
                </div>
            )
        }
        return (
            <div className="singleBannerCategoryTemplate banner__details">
                <div className="insertArea">
                    <div className="preview">
                        <div className="grid_ads-container">{renderLargeInput(0)}</div>
                    </div>
                </div>
                <div className="singleBannerCategoryTemplatePreview">
                    <div className="grid_ads-container">
                        <div className="grid_ads-large">
                            <div className="banner__link">{thumbs[0]}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SingleBannerCategoryTemplate
