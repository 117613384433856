import React from 'react'
import { connect } from 'react-redux'

import * as actions from '../../redux/reviews/reviews.actions'
import ReviewList from '../organisms/ReviewList/ReviewList.jsx'
import * as modalActions from '../../redux/modal/modal.actions'
import modal from '../../redux/modal/modal.types'
import * as userActions from '../../redux/user/user.actions'
import Header from '../organisms/Header/Header.jsx'
import Button from '../atoms/Button/Button.jsx'

class ReviewsPage extends React.Component {
    componentDidMount() {
        this.props.getReviews(this.props.culture)
    }

    componentDidUpdate(prevProps) {
        if (this.props.culture !== prevProps.culture) {
            this.props.getReviews(this.props.culture)
        }
    }

    render() {
        return (
            <div>
                <Header>
                    <div className="title">
                        <h2>Reviews ({this.props.list.length})</h2>
                    </div>
                    <div className="cta">
                        <Button text="Give me more reviews" onClick={() => this.props.getReviews(this.props.culture)} />
                    </div>
                </Header>
                <ReviewList
                    list={this.props.list}
                    culture={this.props.culture}
                    deleteReviewHandler={this.props.deleteReview}
                    answerReviewHandler={this.props.answerReview}
                    getUserInfoHandler={this.props.getUserInfo}
                    approveReviewHandler={this.props.approveReview}
                    openImageHandler={this.props.openImage}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ...state.reviews,
    culture: state.common.culture,
})

const mapDispatchToProps = dispatch => ({
    deleteReview: reviewId =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.deleteReview(reviewId)),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
    answerReview: (reviewId, answer, culture) => dispatch(actions.answerReviewRequest(reviewId, answer, culture)),
    getReviews: culture => dispatch(actions.searchReviews(culture)),
    getUserInfo: nickname => dispatch(userActions.getUserInfo(nickname)),
    approveReview: reviewId =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.approveReviewRequest(reviewId)),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
    openImage: filename =>
        dispatch(
            modalActions.showModal(modal.types.OPEN_IMAGE, {
                filename,
                isOpen: true,
                closeHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsPage)
