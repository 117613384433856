import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Link } from 'react-router-dom'

import * as actions from '../../redux/infobox/infobox.actions'
import * as countriesActions from '../../redux/countries/countries.actions'
import Header from '../organisms/Header/Header.jsx'
import Button from '../atoms/Button/Button.jsx'
import InfoboxList from '../organisms/Infobox/InfoboxList.jsx'
import InfoboxForm from '../molecules/Infoboxes/InfoboxForm.jsx'

class InfoboxPage extends React.Component {
    componentDidMount() {
        this.props.getInfoboxes(this.props.culture)
        this.props.getCountries(this.props.culture)
    }

    componentDidUpdate(prevProps) {
        if (this.props.culture !== prevProps.culture) {
            this.props.getInfoboxes(this.props.culture)
            this.props.getCountries(this.props.culture)
        }
    }

    render() {
        return (
            <div>
                <Header>
                    <div className="title">
                        <h2>Infobox</h2>
                    </div>
                    <div className="cta">
                        <Link to={`${this.props.match.url}/create`}>
                            <Button text="Create new infobox" />
                        </Link>
                    </div>
                </Header>

                <Switch>
                    <Route
                        exact
                        path={this.props.match.url}
                        render={() => (
                            <InfoboxList
                                infoboxes={this.props.list}
                                editMode={this.props.editMode}
                                countries={this.props.countries}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={`${this.props.match.url}/create`}
                        render={() => (
                            <ul className="infobox">
                                <li className="infobox-item">
                                    <InfoboxForm culture={this.props.culture} countries={this.props.countries} />
                                </li>
                            </ul>
                        )}
                    />
                </Switch>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getInfoboxes: culture => dispatch(actions.readInfoboxes(culture)),
    getCountries: culture => dispatch(countriesActions.readCountries(culture)),
})

const mapStateToProps = state => ({
    ...state.infobox,
    ...state.countries,
    culture: state.common.culture,
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoboxPage)
