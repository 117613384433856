import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

const get = culture =>
    fetch(`${config.apiRoot}/v1/Feedbacks/ToApprove/${culture}`, {
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })

const deleteFeedback = feedbackId =>
    fetch(`${config.apiRoot}/v1/Feedbacks/${feedbackId}`, {
        method: 'DELETE',
        headers: authHeader(),
    }).then(r => {
        if (!r.ok) throw new ServerError(r)
    })

const answerFeedback = (feedbackId, answer, culture) =>
    fetch(`${config.apiRoot}/v1/Feedbacks/${feedbackId}/answer`, {
        method: 'POST',
        headers: new Headers({
            ...authHeader(),
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            answer: answer,
            culture: culture,
        }),
    }).then(r => {
        if (!r.ok) throw new ServerError(r)
    })

const approveFeedback = feedbackId =>
    fetch(`${config.apiRoot}/v1/Feedbacks/${feedbackId}/approve`, {
        method: 'PUT',
        headers: new Headers({
            ...authHeader(),
            'Content-Type': 'application/json',
        }),
    }).then(r => {
        if (!r.ok) throw new ServerError(r)
    })
export default {
    get,
    deleteFeedback,
    answerFeedback,
    approveFeedback,
}
