/* eslint-disable no-class-assign */
import config from '../../../config.js'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Field, reduxForm } from 'redux-form'
import { BlockPicker } from 'react-color'

import * as actions from '../../../redux/infobox/infobox.actions'
import Button from '../../atoms/Button/Button.jsx'

import './Infobox.scss'
import colors from './infoboxColors.js'

class InfoboxForm extends React.Component {
    state = {
        startDate: this.props.validFrom,
        endDate: this.props.validTo,
        info: this.props.infoSecondary,
        countdownTitle: this.props.countdownTitle,
        textColor: this.props.colorInfoSecondary
            ? this.props.colorInfoSecondary
            : colors[config.currentSite].textDefaultColor.split('#')[1],
        bgColor: this.props.backgroundColorInfoSecondary
            ? this.props.backgroundColorInfoSecondary
            : colors[config.currentSite].bgDefaultColor.split('#')[1],
        countries: [],
        allCountriesSelected: false,
    }

    handleTextColorChange = color => {
        this.setState(prevState => ({
            ...prevState,
            textColor: color.hex.split('#')[1],
        }))
    }

    handleBgColorChange = color => {
        this.setState(prevState => ({
            ...prevState,
            bgColor: color.hex.split('#')[1],
        }))
    }

    onStartDateChange = date => {
        this.setState(prevState => ({
            ...prevState,
            startDate: date.format(),
        }))
    }

    onEndDateChange = date => {
        this.setState(prevState => ({
            ...prevState,
            endDate: date.format(),
        }))
    }

    handleInfoChange = obj => {
        this.setState(prevState => ({
            ...prevState,
            info: obj.currentTarget.value,
        }))
    }

    handleCountdownTitleChange = obj => {
        this.setState(prevState => ({
            ...prevState,
            countdownTitle: obj.currentTarget.value,
        }))
    }

    handleSave = values => {
        if (!this.state.startDate || !this.state.endDate) {
            this.props.handleIncorrectDate()
            return
        }

        const info = values.infoSecondary ? values.infoSecondary : this.state.info
        let destList = []

        if (!values.all) {
            for (let val in values) {
                if (val !== 'all' && val !== 'infoSecondary' && values[val]) {
                    const code = val.split('code-')[1]
                    destList.push(code)
                }
            }
        }

        const dataToPost = {
            info: '',
            infoSecondary: info,
            countdownTitle: this.state.countdownTitle,
            id: this.props.id,
            validFrom: this.state.startDate,
            validTo: this.state.endDate,
            culture: this.props.culture,
            colorInfoSecondary: this.state.textColor,
            backgroundColorInfoSecondary: this.state.bgColor,
            destinations: destList,
        }

        if (this.props.id) {
            this.props.handleSaveEdit(this.props.id, dataToPost, this.props.culture)
        } else {
            this.props.handleSaveNew(dataToPost, this.props.culture)
        }
    }

    toggleCheckboxes = () => {
        let allCheckboxesSelected = !this.state.allCountriesSelected
        let newCountries = this.state.countries

        const { change } = this.props

        for (let item of newCountries) {
            item.selected = allCheckboxesSelected
            change(`code-${item.code}`, allCheckboxesSelected)
        }
        change('all', allCheckboxesSelected)

        this.setState(prevState => ({
            ...prevState,
            countries: newCountries,
            allCountriesSelected: allCheckboxesSelected,
        }))
    }

    handleCheckboxClick = n => {
        const newCountries = this.state.countries
        const clickedCountry = newCountries.find(element => element.code === n.split('code-')[1])
        clickedCountry.selected = !clickedCountry.selected

        let allCheckboxesSelected = true
        for (let item of newCountries) {
            allCheckboxesSelected = allCheckboxesSelected && item.selected
            if (allCheckboxesSelected === false) break
        }

        const { change } = this.props
        change(n, clickedCountry.selected)
        change('all', allCheckboxesSelected)

        this.setState(prevState => ({
            ...prevState,
            countries: newCountries,
            allCountriesSelected: allCheckboxesSelected,
        }))
    }

    renderDestinationCheckbox({ input, label, type, value, handleChange }) {
        // LOGGING STATE TO CONSOLE: STATE IS UNDEFINED
        return (
            <div className="destination">
                <label>
                    <input
                        {...input}
                        type={type}
                        value={value}
                        onChange={() => {
                            handleChange(input.name)
                        }}
                    />{' '}
                    {label}
                </label>
            </div>
        )
    }

    renderCheckAll({ input, label, type, value, selectAll }) {
        return (
            <div className="destination">
                <label>
                    <input {...input} type={type} value={value} onChange={selectAll} /> {label}
                </label>
            </div>
        )
    }

    renderTextarea({ input, disabled, label, type, value, meta: { touched, error, warning } }) {
        return (
            <div className="infobox-form_field">
                <label>{label}:</label>
                <textarea {...input} type={type} disabled={disabled} value={value} />
                <div className="validationError">
                    {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
                </div>
            </div>
        )
    }

    renderDate = ({ input, disabled, selectedDate, onDateChange, label }) => {
        return (
            <div>
                <label>{label}:</label>
                <DatePicker
                    name={input.name}
                    className="calendar"
                    todayButton={'Today'}
                    selected={selectedDate}
                    onChange={onDateChange}
                    disabled={disabled}
                    utcOffset={0}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="lll"
                    withPortal
                    shouldCloseOnSelect={false}
                />
            </div>
        )
    }

    // change countries from { COUNTRYCODE: true/false } to { code: COUNTRYCODE, selected: true/false }
    componentDidMount() {
        let countries = []
        const { change } = this.props

        this.props.countries.forEach(c => {
            let destSelected =
                this.props.destinations !== undefined ? this.props.destinations.find(d => d === c.code) : undefined

            if (destSelected !== undefined) {
                countries.push({
                    code: c.code,
                    selected: true,
                })
                change(`code-${c.code}`, true)
            } else {
                countries.push({
                    code: c.code,
                    selected: false,
                })
            }
        })

        this.setState(prevState => ({
            ...prevState,
            countries: countries,
        }))
    }

    render() {
        const { handleSubmit, submitting } = this.props

        const momentStartDate = this.state.startDate ? moment(this.state.startDate).utc() : null
        const momentStartDateLocal = momentStartDate ? ' (' + moment(this.state.startDate).format('LT') + ')' : ''

        const momentEndDate = this.state.endDate ? moment(this.state.endDate).utc() : null
        const momentEndDateLocal = momentEndDate ? ' (' + moment(this.state.endDate).format('LT') + ')' : ''

        const cancelButton = () => {
            if (this.props.id) {
                return <Button text="Cancel" onClick={() => this.props.handleCancel(this.props.id)} />
            }
            return (
                <Link to="/infobox">
                    <Button text="Cancel" />
                </Link>
            )
        }

        const renderDestinationSelection = destinationsArray => {
            const result = destinationsArray
                .sort(function(a, b) {
                    if (a.name > b.name) {
                        return 1
                    }
                    return -1
                })
                .map((item, idx) => {
                    // now this.state.countries is like [{code: "ITA", selected: false}, {code: "FRA", selected: true}]
                    const found = this.state.countries.find(el => el.code === item.code)
                    let isSelected = found !== undefined ? found.selected : false

                    return (
                        <Field
                            key={idx}
                            name={`code-${item.code}`}
                            label={item.name}
                            type="checkbox"
                            component={this.renderDestinationCheckbox}
                            props={{ handleChange: this.handleCheckboxClick, value: isSelected }}
                        />
                    )
                })

            return (
                <div className="infobox-form_destinations">
                    <Field
                        name="all"
                        label="Select all"
                        type="checkbox"
                        component={this.renderCheckAll}
                        props={{ value: this.state.allCheckboxesSelected, selectAll: this.toggleCheckboxes }}
                    />
                    {result}
                </div>
            )
        }

        return (
            <>
                <form className="infobox-grid infobox-form" onSubmit={handleSubmit(this.handleSave)}>
                    <div className="infobox-date">
                        <Field
                            name="startDate"
                            type="date"
                            placeholder="Start date"
                            component={this.renderDate}
                            label={`Start date ${momentStartDateLocal}`}
                            props={{
                                disabled: false,
                                selectedDate: momentStartDate,
                                onDateChange: this.onStartDateChange,
                            }}
                        />
                        <Field
                            name="endDate"
                            type="date"
                            placeholder="End date"
                            component={this.renderDate}
                            label={`End date ${momentEndDateLocal}`}
                            props={{
                                disabled: false,
                                selectedDate: momentEndDate,
                                onDateChange: this.onEndDateChange,
                            }}
                        />
                    </div>
                    <div className="infobox-infos">
                        <div className="infobox-info secondary">
                            <Field
                                props={{
                                    disabled: false,
                                    value: !this.state.info ? '' : this.state.info,
                                }}
                                name="infoSecondary"
                                component={this.renderTextarea}
                                label="Info secondary"
                                onChange={this.handleInfoChange}
                            />
                            <Field
                                props={{
                                    disabled: false,
                                    value: !this.state.countdownTitle ? '' : this.state.countdownTitle,
                                }}
                                name="countdownTitle"
                                component={this.renderTextarea}
                                label="Countdown title"
                                onChange={this.handleCountdownTitleChange}
                            />

                            <div className="additional-info">
                                <div className="colors pick-colors">
                                    <div className="color-picker">
                                        <p>
                                            <strong>Text color:</strong>
                                        </p>
                                        <div className="color-picker_select">
                                            <span>{'#' + this.state.textColor}</span>
                                            <span
                                                className="swatch"
                                                style={{ backgroundColor: '#' + this.state.textColor }}
                                            />
                                        </div>
                                        <BlockPicker
                                            color={'#' + this.state.textColor}
                                            colors={colors[config.currentSite].colors}
                                            onChange={this.handleTextColorChange}
                                            triangle="hide"
                                        />
                                    </div>
                                    <div className="color-picker">
                                        <p>
                                            <strong>Background color:</strong>
                                        </p>
                                        <div className="color-picker_select">
                                            <span>{'#' + this.state.bgColor}</span>
                                            <span
                                                className="swatch"
                                                style={{ backgroundColor: '#' + this.state.bgColor }}
                                            />
                                        </div>
                                        <BlockPicker
                                            color={'#' + this.state.bgColor}
                                            colors={colors[config.currentSite].colors}
                                            onChange={this.handleBgColorChange}
                                            triangle="hide"
                                        />
                                    </div>
                                </div>
                                <div className="destinations">
                                    <p>
                                        <strong>Destinations: </strong>
                                    </p>
                                    {renderDestinationSelection(this.props.countries)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="infobox-buttons">
                        <Button text="Save" type="submit" disabled={submitting} />
                        {cancelButton()}
                    </div>
                </form>
            </>
        )
    }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
    handleIncorrectDate: () => dispatch(actions.incorrectDate()),
    handleCancel: infoboxId => dispatch(actions.cancelEditInfobox(infoboxId)),
    handleSaveEdit: (infoboxId, infobox, culture) =>
        dispatch(actions.saveEditInfoboxRequest(infoboxId, infobox, culture)),
    handleSaveNew: (infobox, culture) => dispatch(actions.saveNewInfoboxRequest(infobox, culture)),
})

const validate = () => {}

InfoboxForm = connect(mapStateToProps, mapDispatchToProps)(InfoboxForm)

export default reduxForm({
    form: 'example',
    validate,
})(InfoboxForm)
