/* eslint-disable react/no-unescaped-entities */
import { map } from 'lodash'
import React from 'react'
import Courier from '../../molecules/Couriers/Courier.jsx'

import './CouriersList.scss'

const CouriersList = ({ couriers, url, handleDelete, updateList }) => {
    return couriers.length > 0 ? (
        <div className="container couriers">
            <div className="couriers-table">
                <div className="couriers-table-row header">
                    <div>Courier logo</div>
                    <div>Courier name</div>
                    <div>Actions</div>
                </div>
                {map(couriers, (c, idx) => (
                    <div key={idx} className="couriers-table-row">
                        <Courier courier={c} url={url} handleDelete={handleDelete} updateList={updateList} />
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <div className="container couriers">
            <p>There are no couriers. Create one by clicking on the button "Create new courier".</p>
        </div>
    )
}

export default CouriersList
