var messages = {
    MESSAGE_UNAUTHORIZED: 'I must have left it in my other pants. Unauthorized.',
    MESSAGE_ERROR_GENERIC: 'Insert Disk 22 and Press Button to Continue. Generic error. Check user permissions.',
    MESSAGE_USER_NOT_FOUND: 'User not found',
    MESSAGE_TYPE_ERROR_FAILEDTOFETCH: 'Failed to fetch',
    MESSAGE_INCORRECT_DATE: 'Date is empty or in wrong format. Try again.',
    MESSAGE_INVALID_REASON_FOR_REJECTION: 'Reason for rejection cannot be empty.',
    MESSAGE_INVALID_AMOUNT: 'The amount refunded cannot be 0. Use a decimal dot (e.g. 9.99 instead of 9,99).',
    getSuccessMessage: (param = '') =>
        param === '' ? ' successfully!! PEW PEW PEW!!' : `${param} successfully!! PEW PEW PEW!!`,
    getApprovedSuccessMessage: (param = '') =>
        param === '' ? 'Approved with success!! PEW PEW PEW!!' : `${param} approved with success!! PEW PEW PEW!!`,
    getDeletedSuccessMessage: (param = '') =>
        param === '' ? 'Deleted with success!! PEW PEW PEW!!' : `${param} deleted with success!! PEW PEW PEW!!`,
    getSetSuccessMessage: (param = '') =>
        param === '' ? 'Set with success!! PEW PEW PEW!!' : `${param} set with success!! PEW PEW PEW!!`,
    getModifiedSuccessMessage: (param = '') =>
        param === '' ? 'Modified with success!! HOORAY!!' : `${param} modified with success!! HOORAY!!`,
    getCreatedSuccessMessage: (param = '') =>
        param === '' ? 'Created with success!! PEW PEW PEW!!' : `${param} created with success!! PEW PEW PEW!!`,
}
export default messages
