const NAMESPACE = 'USER'

export const ACTION_TYPES = {
    LOGIN_REQUEST: `${NAMESPACE}/LOGIN_REQUEST`,
    LOGIN_SUCCESS: `${NAMESPACE}/LOGIN_SUCCESS`,
    LOGOUT_REQUEST: `${NAMESPACE}/LOGOUT_REQUEST`,
    LOGOUT: `${NAMESPACE}/LOGOUT`,
    USER_INFO_REQUEST: `${NAMESPACE}/USER_INFO_REQUEST`,
    SEARCH_USERS_REQUEST: `${NAMESPACE}/SEARCH_USERS_REQUEST`,
    USERS_RECEIVED: `${NAMESPACE}/USERS_RECEIVED`,
    USER_LOCKED_REQUEST: `${NAMESPACE}/USER_LOCKED_REQUEST`,
    USER_UNLOCKED_REQUEST: `${NAMESPACE}/USER_UNLOCKED_REQUEST`,
    USER_DELETED_REQUEST: `${NAMESPACE}/USER_DELETED_REQUEST`,
    USER_LOCKED: `${NAMESPACE}/USER_LOCKED`,
    USER_UNLOCKED: `${NAMESPACE}/USER_UNLOCKED`,
    USER_DELETED: `${NAMESPACE}/USER_DELETED`,
}

export const loginRequest = (username, password) => ({
    type: ACTION_TYPES.LOGIN_REQUEST,
    payload: { username, password },
})
export const loginSuccess = user => ({
    type: ACTION_TYPES.LOGIN_SUCCESS,
    payload: user,
})
export const logoutRequest = () => ({
    type: ACTION_TYPES.LOGOUT_REQUEST,
})
export const logout = () => ({
    type: ACTION_TYPES.LOGOUT,
})
export const getUserInfo = userId => ({
    type: ACTION_TYPES.USER_INFO_REQUEST,
    payload: userId,
})
export const searchUsers = searchTerm => ({
    type: ACTION_TYPES.SEARCH_USERS_REQUEST,
    searchTerm,
})
export const usersReceived = list => ({
    type: ACTION_TYPES.USERS_RECEIVED,
    payload: list,
})
export const lockUser = userId => ({
    type: ACTION_TYPES.USER_LOCKED_REQUEST,
    payload: userId,
})
export const unlockUser = userId => ({
    type: ACTION_TYPES.USER_UNLOCKED_REQUEST,
    payload: userId,
})
export const deleteUser = userId => ({
    type: ACTION_TYPES.USER_DELETED_REQUEST,
    payload: userId,
})
export const userLocked = updatedUser => ({
    type: ACTION_TYPES.USER_LOCKED,
    payload: updatedUser,
})
export const userUnlocked = updatedUser => ({
    type: ACTION_TYPES.USER_UNLOCKED,
    payload: updatedUser,
})
export const userDeleted = userId => ({
    type: ACTION_TYPES.USER_DELETED,
    payload: userId,
})
