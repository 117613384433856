import React from 'react'
import { map } from 'lodash'
import Button from '../../atoms/Button/Button.jsx'
import ImageClickable from '../../molecules/Images/ImageClickable.jsx'
import config from '../../../config'
import './ReassortmentNotificationsList.scss'

const ReassortmentNotificationsList = ({
    culture,
    reassortmentNotification,
    deleteReassortmentNotificationHandler,
    openImageHandler,
}) => {
    if (reassortmentNotification === undefined || reassortmentNotification === null) return <div />
    return (
        <div className="cardContainer">
            <div>
                <div className="description notificationHeader">
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                        <label>First name</label>
                        <span>{reassortmentNotification.firstName}</span>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                        <label>Last name</label>
                        <span>{reassortmentNotification.lastName}</span>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                        <label>Email</label>
                        <span>{reassortmentNotification.email}</span>
                    </div>
                </div>
            </div>
            {reassortmentNotification.lines.length === 0 ? (
                <h3 style={{ textAlign: 'center', marginTop: '35px' }}>No reassortment notification line available</h3>
            ) : (
                <ul className="reassortmentNotificationList">
                    {reassortmentNotification.lines.map(l => {
                        return (
                            <li key={l.id} className="reassortmentNotificationLinesContainer">
                                <div className="image">
                                    <ImageClickable
                                        key={l.productImageSrc}
                                        filename={
                                            config.getSiteByCulture(culture) + l.productImageSrc.replace('~/', '')
                                        }
                                        onOpen={openImageHandler}
                                    />
                                </div>
                                <div className="info">
                                    <p>
                                        <strong>Type: </strong>
                                        {l.productType}
                                    </p>
                                    <p>
                                        <strong>Title: </strong>
                                        {l.productTitle}
                                    </p>
                                    <p>
                                        <strong>Slug: </strong>
                                        {l.productSlug}
                                    </p>
                                    <p>
                                        <strong>Quantity: </strong>
                                        {l.quantity}
                                    </p>
                                    <p>
                                        <strong>Active since: </strong>
                                        {l.activeSince}
                                    </p>
                                    <div>
                                        <ul>
                                            {map(l.productAttributes, (value, key) => {
                                                return (
                                                    <li key={key} style={{ marginBottom: '2px' }}>
                                                        {key} : {value}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    <div className="actions">
                                        <Button
                                            text="Delete"
                                            onClick={() =>
                                                deleteReassortmentNotificationHandler({
                                                    reassortmentNotificationId: reassortmentNotification.id,
                                                    lineId: l.id,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            )}
        </div>
    )
}

export default ReassortmentNotificationsList
