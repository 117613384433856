import { isEmpty, isNil } from 'lodash'
import zones from '../../model/zones'

const validateZone = zone => {
    let isZoneValid = true
    const errors = {}

    if (isNil(zone.name) || zone.name === '') {
        isZoneValid = false
        errors.name = 'Please enter a valid name.'
    }

    if (isNil(zone.courier) || isNil(zone.courier.name) || zone.courier.name === '') {
        isZoneValid = false
        errors.courier = 'Please select a courier.'
    }

    if (isNil(zone.countries) || isEmpty(zone.countries)) {
        isZoneValid = false
        errors.countries = 'Please select at least a country.'
    }

    return [isZoneValid, errors]
}

const submitZone = async (zone, endpoint, method) => {
    const [isValid, errors] = validateZone(zone)
    if (isValid) {
        return zones.submit(zone, endpoint, method)
    }

    return errors
}

export { submitZone }
