import { call, takeLatest, takeEvery, put } from 'redux-saga/effects'

import * as actions from './questionAndAnswers.actions'
import * as commonActions from '../common/common.actions'
import * as userActions from '../user/user.actions'
import * as modalActions from '../modal/modal.actions'
import { ACTION_TYPES } from './questionAndAnswers.actions'
import questions from '../../model/questions'
import ServerError from '../../errors/ServerError'
import modal from '../modal/modal.types'
import messages from '../../helpers/messages'

export function* fetchQuestions(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        yield put(actions.exitSearchMode(action.payload))
        const result = yield call(questions.get, action.payload)
        yield put(actions.questionsReceived(result))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export function* deleteQuestion(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        yield call(questions.deleteQuestion, action.payload)
        yield put(actions.questionDeleted(action.payload))
        yield put(commonActions.showMessage(messages.getDeletedSuccessMessage('Question')))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export function* deleteAnswer(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        yield call(questions.deleteAnswer, action.payload.questionId, action.payload.answerId)
        yield put(actions.answerDeleted(action.payload.questionId, action.payload.answerId))
        yield put(commonActions.showMessage(messages.getDeletedSuccessMessage('Answer')))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export function* answerQuestion(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const result = yield call(
            questions.answerQuestion,
            action.payload.questionId,
            action.payload.answer,
            action.payload.culture
        )
        yield put(actions.questionAnswered(action.payload.questionId, result))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export function* approveAnswer(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        yield call(questions.approveAnswer, action.payload.questionId, action.payload.answerId)
        yield put(actions.answerApproved(action.payload.questionId, action.payload.answerId))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export function* searchQuestions(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const result = yield call(questions.getBySlug, action.payload.slug, action.payload.culture)
        yield put(actions.questionsReceived(result))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export default function*() {
    yield takeLatest(ACTION_TYPES.SEARCH_QUESTIONS, fetchQuestions)
    yield takeEvery(ACTION_TYPES.DELETE_ANSWER_REQUEST, deleteAnswer)
    yield takeEvery(ACTION_TYPES.DELETE_QUESTION_REQUEST, deleteQuestion)
    yield takeEvery(ACTION_TYPES.ANSWER_QUESTION_REQUEST, answerQuestion)
    yield takeEvery(ACTION_TYPES.APPROVE_ANSWER_REQUEST, approveAnswer)
    yield takeEvery(ACTION_TYPES.SEARCH_QUESTIONS_REQUEST, searchQuestions)
}
