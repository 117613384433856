import React from 'react'

import Feedback from '../Feedback/Feedback.jsx'
import '../FeedbackList/FeedbackList.scss'

const FeedbackList = ({
    list,
    culture,
    deleteFeedbackHandler,
    answerFeedbackHandler,
    getUserInfoHandler,
    approveFeedbackHandler,
}) => {
    const Feedbacks = list.map(feed => {
        return (
            <li className="feedbackList" key={feed.id}>
                <Feedback
                    culture={culture}
                    feedback={feed}
                    deleteFeedbackHandler={deleteFeedbackHandler}
                    answerFeedbackHandler={answerFeedbackHandler}
                    getUserInfoHandler={getUserInfoHandler}
                    approveFeedbackHandler={approveFeedbackHandler}
                />
            </li>
        )
    })

    return (
        <div>
            <ul>{Feedbacks}</ul>
        </div>
    )
}

export default FeedbackList
