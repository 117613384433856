/* eslint-disable react/no-unescaped-entities */
import { isNumber, map, sortBy } from 'lodash'
import React from 'react'
import ShippingService from '../../molecules/Couriers/ShippingService.jsx'

import './ShippingServicesList.scss'

const ShippingServicesList = ({
    shippingServices,
    url,
    handleDelete,
    filters,
    couriersList,
    countriesList,
    updateCountry,
    updateCourier,
}) => {
    return (
        <div className="container shippingServices">
            <div>
                <h3>Filters:</h3>
                <div className="shippingServices-filters">
                    <div className="shippingServices-filter">
                        <label htmlFor="country">
                            Country:
                            <select
                                name="country"
                                value={filters.country}
                                onChange={e => {
                                    updateCountry(e.target.value)
                                }}
                            >
                                <option value="">-- select --</option>
                                {map(
                                    sortBy(countriesList, c => c.name),
                                    (c, idx) => (
                                        <option key={idx} value={c.code}>
                                            {c.name} ({c.code})
                                        </option>
                                    )
                                )}
                            </select>
                        </label>
                    </div>
                    <div className="shippingServices-filter">
                        <label htmlFor="courier">
                            Courier:
                            <select
                                name="courier"
                                value={filters.courier}
                                onChange={e => {
                                    const courierId = Number(e.target.value)
                                    if (isNumber(courierId) && !isNaN(courierId)) {
                                        updateCourier(courierId)
                                    }
                                }}
                            >
                                <option value={0}>-- select --</option>
                                {map(couriersList, (c, idx) => (
                                    <option key={idx} value={c.courierId}>
                                        {c.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
            </div>
            {shippingServices.length > 0 ? (
                <div className="shippingServices-table">
                    <div className="shippingServices-table-row header">
                        <div>Logo</div>
                        <div>Service id</div>
                        <div>Service name</div>
                        <div>Country</div>
                        <div>Free shipping threshold</div>
                        <div>Actions</div>
                    </div>
                    {map(
                        sortBy(shippingServices, s => s.destinationCountryCode),
                        (s, idx) => (
                            <div key={idx} className="shippingServices-table-row">
                                <ShippingService
                                    shippingService={s}
                                    url={url}
                                    handleDelete={handleDelete}
                                    countries={countriesList}
                                />
                            </div>
                        )
                    )}
                </div>
            ) : (
                <p>
                    There are no shipping services. Create one by clicking on the button "Create new shipping service".
                </p>
            )}
        </div>
    )
}

export default ShippingServicesList
