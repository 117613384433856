import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

const get = culture =>
    fetch(`${config.apiRoot}/v1/categories/${culture}`, {
        headers: authHeader(),
    })
        .then(r => {
            if (r.ok) {
                return r.json()
            }
            throw new ServerError(r)
        })
        .then(categories => {
            const mappedCategories = categories.map(cat => {
                const obj = { ...cat }
                obj.value = cat.id
                obj.name = `${cat.erpId} - ${cat.title} - ${cat.titleFacet}`
                return obj
            })
            return mappedCategories
        })

export default {
    get,
}
