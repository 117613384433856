import React from 'react'
import moment from 'moment'
import Button from '../../atoms/Button/Button.jsx'
import './UserList.scss'

const UserList = ({ users, lock, unlock, deleteUser }) => {
    const x = users === undefined ? [] : [...users]
    const usersFound = x.map(u => {
        return (
            <div className="cardContainer userList" key={u.id}>
                <div className="card">
                    <div className="info accessData ">
                        <div>
                            <label>Password attempt count: </label>
                            <span>{u.failedPasswordAttemptCount}</span>
                        </div>
                        <div>
                            <label>Last login date: </label>
                            <span>
                                {u.lastLoginDate == null ? 'Never logged in' : moment(u.lastLoginDate).format('LLLL')}
                            </span>
                        </div>
                    </div>
                    <div className="description">
                        <div>
                            <h3>
                                <span className={u.isLockedOut ? 'userLockedOut' : 'userActive'}>&#11044;</span>

                                {u.userName}
                            </h3>
                        </div>
                        <div className="userDetails">
                            <div>
                                <label>First name</label>
                                <span>{u.firstName}</span>
                            </div>

                            <div>
                                <label>Last name</label>
                                <span>{u.lastName}</span>
                            </div>

                            <div>
                                <label>Username</label>
                                <span>{u.userName}</span>
                            </div>
                            <div>
                                <label>Nickname</label>
                                <span>{u.nickname}</span>
                            </div>
                            <div>
                                <label>Role</label>
                                <span>{u.role}</span>
                            </div>
                        </div>
                    </div>

                    <div className="actions">
                        {u.isLockedOut ? (
                            <Button text="Unlock" onClick={() => unlock(u.id)} />
                        ) : (
                            <Button text="Lock" onClick={() => lock(u.id)} />
                        )}
                        <Button text="Delete" onClick={() => deleteUser(u.id)} />
                    </div>
                </div>
            </div>
        )
    }, [])
    return <div>{usersFound}</div>
}

export default UserList
