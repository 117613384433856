import React, { useState, useEffect } from 'react'
import sizeGuides from './sizeGuides'
import sizeGuides1 from '../../model/sizeGuides'
import './SizeGuidesPage.scss'

function SizeGuidesPage() {
    const [cdnImagesFallbackUrl, setCdnUrl] = useState('')

    useEffect(() => {
        async function fetchUrl() {
            try {
                const cdnUrl = await sizeGuides1.getCdnUrl()
                setCdnUrl(cdnUrl)
            } catch (error) {
                alert("Impossibile recuperare l'url della CDN. Fai logout e login prima e poi riprova.")
            }
        }
        fetchUrl()
    }, [])

    const sizeGuidesManImages = [
        {
            imgName: 'size_man_a.jpg',
            text: 'size_man_a.jpg',
        },
        {
            imgName: 'size_man_a-b.jpg',
            text: 'size_man_a-b.jpg',
        },
        {
            imgName: 'size_man_a-b-c.jpg',
            text: 'size_man_a-b-c.jpg',
        },
        {
            imgName: 'size_man_a-b-c-d.jpg',
            text: 'size_man_a-b-c-d.jpg',
        },
        {
            imgName: 'size_man_a-b-d.jpg',
            text: 'size_man_a-b-d.jpg',
        },
        {
            imgName: 'size_man_a-b-d-e.jpg',
            text: 'size_man_a-b-d-e.jpg',
        },
        {
            imgName: 'size_man_a-c.jpg',
            text: 'size_man_a-c.jpg',
        },
        {
            imgName: 'size_man_b.jpg',
            text: 'size_man_b.jpg',
        },
        {
            imgName: 'size_man_b1.jpg',
            text: 'size_man_b1.jpg',
        },
        {
            imgName: 'size_man_b1-e.jpg',
            text: 'size_man_b1-e.jpg',
        },
        {
            imgName: 'size_man_b1-f.jpg',
            text: 'size_man_b1-f.jpg',
        },
        {
            imgName: 'size_man_b-d.jpg',
            text: 'size_man_b-d.jpg',
        },
        {
            imgName: 'size_man_b-f.jpg',
            text: 'size_man_b-f.jpg',
        },
        {
            imgName: 'size_man_b-d-e.jpg',
            text: 'size_man_b-d-e.jpg',
        },
        {
            imgName: 'size_man_b-d-f.jpg',
            text: 'size_man_b-d-f.jpg',
        },
        {
            imgName: 'size_man_b-d-j.jpg',
            text: 'size_man_b-d-j.jpg',
        },
        {
            imgName: 'size_man_d.jpg',
            text: 'size_man_d.jpg',
        },
        {
            imgName: 'size_man_d-e.jpg',
            text: 'size_man_d-e.jpg',
        },
        {
            imgName: 'size_man_d-j.jpg',
            text: 'size_man_d-j.jpg',
        },
        {
            imgName: 'size_man_e.jpg',
            text: 'size_man_e.jpg',
        },
        {
            imgName: 'size_man_h.jpg',
            text: 'size_man_h.jpg',
        },
        {
            imgName: 'size_man_h-a.jpg',
            text: 'size_man_h-a.jpg',
        },
        {
            imgName: 'size_man_h-a-b-d.jpg',
            text: 'size_man_h-a-b-d.jpg',
        },
        {
            imgName: 'size_man_h-a-b-d-j.jpg',
            text: 'size_man_h-a-b-d-j.jpg',
        },
        {
            imgName: 'size_man_h-b.jpg',
            text: 'size_man_h-b.jpg',
        },
        {
            imgName: 'size_man_h-a-b.jpg',
            text: 'size_man_h-a-b.jpg',
        },
        {
            imgName: 'size_man_h-a-b-c.jpg',
            text: 'size_man_h-a-b-c.jpg',
        },
        {
            imgName: 'size_man_h-a-c.jpg',
            text: 'size_man_h-a-c.jpg',
        },
        {
            imgName: 'size_man_h-a-d.jpg',
            text: 'size_man_h-a-d.jpg',
        },
        {
            imgName: 'size_man_h-b-c.jpg',
            text: 'size_man_h-b-c.jpg',
        },
        {
            imgName: 'size_man_h-b-d.jpg',
            text: 'size_man_h-b-d.jpg',
        },
        {
            imgName: 'size_man_h-b-d-e.jpg',
            text: 'size_man_h-b-d-e.jpg',
        },
        {
            imgName: 'size_man_h-b-d-f.jpg',
            text: 'size_man_h-b-d-f.jpg',
        },
        {
            imgName: 'size_man_h-b-d-j.jpg',
            text: 'size_man_h-b-d-j.jpg',
        },
        {
            imgName: 'size_man_h-b-f.jpg',
            text: 'size_man_h-b-f.jpg',
        },
        {
            imgName: 'size_man_h-d-e.jpg',
            text: 'size_man_h-d-e.jpg',
        },
        {
            imgName: 'size_man_h-g.jpg',
            text: 'size_man_h-g.jpg',
        },
        {
            imgName: 'size_leg_circumference_man_d-g.jpg',
            text: 'size_leg_circumference_man_d-g.jpg',
        },
        {
            imgName: 'size_man_h-a-b-c-d-e-f-g.jpg',
            text: 'size_man_h-a-b-c-d-e-f-g.jpg',
        },
        {
            imgName: 'size_man-d.jpg',
            text: 'size_man-d.jpg',
        },
        {
            imgName: 'size_man_a-b-m.jpg',
            text: 'size_man_a-b-m.jpg',
        },
        {
            imgName: 'size_man_general.jpg',
            text: 'size_man_general.jpg',
        },
    ]
    const sizeGuidesWomanImages = [
        {
            imgName: 'size_woman_a.jpg',
            text: 'size_woman_a.jpg',
        },
        {
            imgName: 'size_woman_a-b.jpg',
            text: 'size_woman_a-b.jpg',
        },
        {
            imgName: 'size_woman_a-b-c.jpg',
            text: 'size_woman_a-b-c.jpg',
        },
        {
            imgName: 'size_woman_a-b-c-d.jpg',
            text: 'size_woman_a-b-c-d.jpg',
        },
        {
            imgName: 'size_woman_a-b-d.jpg',
            text: 'size_woman_a-b-d.jpg',
        },
        {
            imgName: ' size_woman_a-b-d-e.jpg',
            text: ' size_woman_a-b-d-e.jpg',
        },
        {
            imgName: 'size_woman_a-b-c-d-e.jpg',
            text: 'size_woman_a-b-c-d-e.jpg',
        },
        {
            imgName: 'size_woman_a-c.jpg',
            text: 'size_woman_a-c.jpg',
        },
        {
            imgName: 'size_woman_b.jpg',
            text: 'size_woman_b.jpg',
        },
        {
            imgName: 'size_woman_b1.jpg',
            text: 'size_woman_b1.jpg',
        },
        {
            imgName: 'size_woman_b1-e.jpg',
            text: 'size_woman_b1-e.jpg',
        },
        {
            imgName: 'size_woman_b1-f.jpg',
            text: 'size_woman_b1-f.jpg',
        },
        {
            imgName: 'size_woman_b-d.jpg',
            text: 'size_woman_b-d.jpg',
        },
        {
            imgName: 'size_woman_b-f.jpg',
            text: 'size_woman_b-f.jpg',
        },
        {
            imgName: 'size_woman_b-d-e.jpg',
            text: 'size_woman_b-d-e.jpg',
        },
        {
            imgName: 'size_woman_b-d-f.jpg',
            text: 'size_woman_b-d-f.jpg',
        },
        {
            imgName: 'size_woman_b-d-j.jpg',
            text: 'size_woman_b-d-j.jpg',
        },
        {
            imgName: 'size_woman_d.jpg',
            text: 'size_woman_d.jpg',
        },
        {
            imgName: 'size_woman_d-e.jpg',
            text: 'size_woman_d-e.jpg',
        },
        {
            imgName: 'size_woman_d-j.jpg',
            text: 'size_woman_d-j.jpg',
        },
        {
            imgName: 'size_woman_e.jpg',
            text: 'size_woman_e.jpg',
        },
        {
            imgName: 'size_woman_h.jpg',
            text: 'size_woman_h.jpg',
        },
        {
            imgName: 'size_woman_h-a.jpg',
            text: 'size_woman_h-a.jpg',
        },
        {
            imgName: 'size_woman_h-a-b-d.jpg',
            text: 'size_woman_h-a-b-d.jpg',
        },
        {
            imgName: 'size_woman_h-a-b-d-j.jpg',
            text: 'size_woman_h-a-b-d-j.jpg',
        },
        {
            imgName: 'size_woman_h-b.jpg',
            text: 'size_woman_h-b.jpg',
        },
        {
            imgName: 'size_woman_h-a-c.jpg',
            text: 'size_woman_h-a-c.jpg',
        },
        {
            imgName: 'size_woman_h-a-b.jpg',
            text: 'size_woman_h-a-b.jpg',
        },
        {
            imgName: 'size_woman_h-a-b-c.jpg',
            text: 'size_woman_h-a-b-c.jpg',
        },
        {
            imgName: 'size_woman_h-a-d.jpg',
            text: 'size_woman_h-a-d.jpg',
        },
        {
            imgName: 'size_woman_h-b-c.jpg',
            text: 'size_woman_h-b-c.jpg',
        },
        {
            imgName: 'size_woman_h-b-d.jpg',
            text: 'size_woman_h-b-d.jpg',
        },
        {
            imgName: 'size_woman_h-b-d-e.jpg',
            text: 'size_woman_h-b-d-e.jpg',
        },
        {
            imgName: 'size_woman_h-b-d-j.jpg',
            text: 'size_woman_h-b-d-j.jpg',
        },
        {
            imgName: 'size_woman_h-b-d-f.jpg',
            text: 'size_woman_h-b-d-f.jpg',
        },
        {
            imgName: 'size_woman_h-b-f.jpg',
            text: 'size_woman_h-b-f.jpg',
        },
        {
            imgName: 'size_woman_h-d-e.jpg',
            text: 'size_woman_h-d-e.jpg',
        },
        {
            imgName: 'size_woman_h-g.jpg',
            text: 'size_woman_h-g.jpg',
        },
        {
            imgName: 'size_leg_circumference_woman_d-g.jpg',
            text: 'size_leg_circumference_woman_d-g.jpg',
        },
        {
            imgName: 'size_woman_general.jpg',
            text: 'size_woman_general.jpg',
        },
    ]
    const sizeGuidesVariousImages = [
        {
            imgName: 'size_hand_circumference.jpg',
            text: 'size_hand_circumference.jpg',
        },
        {
            imgName: 'size_hand_width.jpg',
            text: 'size_hand_width.jpg',
        },
        {
            imgName: 'size_hand_a-b-c-d.jpg',
            text: 'size_hand_a-b-c-d.jpg',
        },
        {
            imgName: 'size_hand_a-d.jpg',
            text: 'size_hand_a-d.jpg',
        },
        {
            imgName: 'size_hand_l-d.jpg',
            text: 'size_hand_l-d.jpg',
        },
        {
            imgName: 'size_hand_l.jpg',
            text: 'size_hand_l.jpg',
        },
        {
            imgName: 'size_arm.jpg',
            text: 'size_arm.jpg',
        },
        {
            imgName: 'size_biceps.jpg',
            text: 'size_biceps.jpg',
        },
        {
            imgName: 'size_foot.jpg',
            text: 'size_foot.jpg',
        },
        {
            imgName: 'size_foot_calf.jpg',
            text: 'size_foot_calf.jpg',
        },
        {
            imgName: 'size_calf.jpg',
            text: 'size_calf.jpg',
        },
        {
            imgName: 'size_calf_knee_leg.jpg',
            text: 'size_calf_knee_leg.jpg',
        },
        {
            imgName: 'size_ankle-calf-knee-leg.jpg',
            text: 'size_ankle-calf-knee-leg.jpg',
        },
        {
            imgName: 'size_arm_length.jpg',
            text: 'size_arm_length.jpg',
        },
        {
            imgName: 'size_knee-pad-ixs.jpg',
            text: 'size_knee-pad-ixs.jpg',
        },
        {
            imgName: 'size_elbow-pad-a-c.jpg',
            text: 'size_elbow-pad-a-c.jpg',
        },
        {
            imgName: 'size_backpack.jpg',
            text: 'size_backpack.jpg',
        },
        {
            imgName: 'size_leg_circumference.jpg',
            text: 'size_leg_circumference.jpg',
        },
        {
            imgName: 'size_head.jpg',
            text: 'size_head.jpg',
        },
        {
            imgName: 'size_hand_l_w.jpg',
            text: 'size_hand_l_w.jpg',
        },
        {
            imgName: 'size_hand_l_w_o.jpg',
            text: 'size_hand_l_w_o.jpg',
        },
    ]
    return (
        <div className="container sizeguides">
            <a
                href="https://static-files-for-all-sites.s3.eu-west-1.amazonaws.com/sizeguide.docx"
                target="_blank"
                rel="noreferrer"
            >
                Download Guide
            </a>
            <div id="lrdg_tablesContainer">
                <div className="lrdg_newTable">
                    <h3>Insert title here!</h3>
                    <input
                        id="inputTitle_0"
                        placeholder="Here's the title of your table"
                        type="text"
                        style={{ width: '100%' }}
                    />
                    <br />
                    <h3>Insert excel here!</h3>
                    <textarea
                        id="csv_0"
                        placeholder="Paste XLSX content here"
                        rows="8"
                        style={{ width: '100%' }}
                    ></textarea>
                    <br />
                </div>
            </div>
            <button onClick={sizeGuides.addNewTable}>Add new Table</button>
            <h3>Insert notes here!</h3>
            <textarea
                id="notes"
                placeholder="If you need to add some notes, this is the place for you!"
                rows="4"
                style={{ width: '100%' }}
            ></textarea>
            <br />
            <h3>Select image here!</h3>
            <select id="lrdg_slct" name="select">
                <option disabled value="default" defaultValue={'selected'}>
                    --- seleziona una immagine ---
                </option>
                <option disabled value="man">
                    --- Man ---
                </option>
                {sizeGuidesManImages.map(el => (
                    <option key={el.imgName} value={`${cdnImagesFallbackUrl}/sizeguide/${el.imgName}`}>
                        {el.text}
                    </option>
                ))}
                <option disabled value="woman">
                    --- Woman ---
                </option>
                {sizeGuidesWomanImages.map(el => (
                    <option key={el.imgName} value={`${cdnImagesFallbackUrl}/sizeguide/${el.imgName}`}>
                        {el.text}
                    </option>
                ))}
                <option disabled value="various">
                    --- Various ---
                </option>
                {sizeGuidesVariousImages.map(el => (
                    <option key={el.imgName} value={`${cdnImagesFallbackUrl}/sizeguide/${el.imgName}`}>
                        {el.text}
                    </option>
                ))}
                <option disabled value="divider">
                    --------------------
                </option>
                <option value="noImage">No image</option>
            </select>
            <br />
            <br />
            <br />
            <button
                onClick={() => {
                    var imageSelect = document.getElementById('lrdg_slct')
                    sizeGuides.createShoesTable(imageSelect)
                }}
            >
                Create Shoes Table
            </button>
            <button
                onClick={() => {
                    var imageSelect = document.getElementById('lrdg_slct')
                    sizeGuides.createClothingTable(imageSelect)
                }}
            >
                Create Clothing Table
            </button>
            <br />
            <br />
            <button onClick={sizeGuides.resetTable}>Reset All</button> <br />
            <div id="lrdg_cntnr"></div>
            <br />
            <textarea
                id="tableText"
                type="text"
                placeholder="Here's your brand new table"
                rows="2"
                style={{ width: '100%' }}
            ></textarea>
            <br />
            <br />
            <button onClick={sizeGuides.copyTable}>Copy table for Erp</button>
        </div>
    )
}

export default SizeGuidesPage
