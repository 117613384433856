import { delay } from 'redux-saga'
import { call, put, takeEvery } from 'redux-saga/effects'

import * as actions from './user.actions'
import * as commonActions from '../common/common.actions'
import { ACTION_TYPES } from './user.actions'
import users from '../../model/users'
import * as modalActions from '../modal/modal.actions'
import modal from '../modal/modal.types'
import storage from '../../model/storage'
import { history } from '../../helpers/history'
import ServerError from '../../errors/ServerError'
import messages from '../../helpers/messages'

function* authorize(action) {
    try {
        yield call(storage.clear)
        yield put(modalActions.showModal(modal.types.LOADING))
        const user = yield call(users.authorize, action.payload.username, action.payload.password)
        yield put(actions.loginSuccess(user))
        yield call(storage.storeToken, user.access_token)
        yield put(modalActions.hideModal())
        history.push('/landingEmptyPage')
    } catch (e) {
        yield put(modalActions.hideModal())
        yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* logout() {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        yield put(actions.logout())
        yield call(storage.clear)
        yield put(modalActions.hideModal())
        history.push('/')
    } catch (e) {
        yield put(modalActions.hideModal())
        yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* getUserInfo(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const user = yield call(users.getUserInfo, action.payload)
        if (user) {
            yield put(
                modalActions.showModal(modal.types.MODAL_USER, {
                    user,
                })
            )
        } else {
            yield put(commonActions.showMessage(messages.MESSAGE_USER_NOT_FOUND))
        }
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(actions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export function* searchUsers(action) {
    try {
        const result = yield call(users.searchUsers, action.searchTerm)
        yield put(actions.usersReceived(result))
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(actions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* lockUser(action) {
    try {
        yield call(users.lock, action.payload)
        yield put(modalActions.showModal(modal.types.LOADING))
        yield delay(500)
        const updatedUser = yield call(users.getUserInfo, action.payload)
        yield put(actions.userLocked({ updatedUser }))
        yield put(modalActions.hideModal())
        yield put(commonActions.showMessage(messages.getSuccessMessage('User locked')))
    } catch (e) {
        yield put(modalActions.hideModal())
        commonActions.handleUnauthorized(e, actions.logoutRequest)
    }
}

function* unlockUser(action) {
    try {
        yield call(users.unlock, action.payload)
        yield put(modalActions.showModal(modal.types.LOADING))
        yield delay(500)
        const updatedUser = yield call(users.getUserInfo, action.payload)
        yield put(actions.userUnlocked({ updatedUser }))
        yield put(commonActions.showMessage(messages.getSuccessMessage('User unlocked')))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        commonActions.handleUnauthorized(e, actions.logoutRequest)
    }
}

function* deleteUser(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        yield call(users.deleteUser, action.payload)
        yield put(actions.userDeleted(action.payload))
        yield put(commonActions.showMessage(messages.getDeletedSuccessMessage('User')))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        commonActions.handleUnauthorized(e, actions.logoutRequest)
    }
}

export default function*() {
    yield takeEvery(ACTION_TYPES.LOGIN_REQUEST, authorize)
    yield takeEvery(ACTION_TYPES.LOGOUT_REQUEST, logout)
    yield takeEvery(ACTION_TYPES.USER_INFO_REQUEST, getUserInfo)
    yield takeEvery(ACTION_TYPES.SEARCH_USERS_REQUEST, searchUsers)
    yield takeEvery(ACTION_TYPES.USER_LOCKED_REQUEST, lockUser)
    yield takeEvery(ACTION_TYPES.USER_UNLOCKED_REQUEST, unlockUser)
    yield takeEvery(ACTION_TYPES.USER_DELETED_REQUEST, deleteUser)
}
