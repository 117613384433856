/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { isNaN, isNumber, map, sortBy } from 'lodash'
import { getServiceName } from '../../../services/couriers/shippingServices.js'
import shippingServices from '../../../model/shippingServices.js'
import Catalog from '../../molecules/Couriers/Catalog.jsx'
import Button from '../../atoms/Button/Button.jsx'

import './CatalogList.scss'

const CatalogList = ({
    catalogs,
    url,
    handleDelete,
    countries,
    updateList,
    filters,
    couriersList,
    updateCourier,
    updateName,
}) => {
    const [shippingServicesNotInCatalogs, setShippingServicesNotInCatalogs] = useState([])
    const [open, setOpen] = useState(false)

    const getServicesNotInCatalogs = async () => {
        try {
            const s = await shippingServices.getServicesNotInCatalogs()
            setShippingServicesNotInCatalogs(s)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getServicesNotInCatalogs()
    }, [])

    return (
        <div className="container catalogs">
            <div>
                <h3>Filters:</h3>
                <div className="catalogs-filters">
                    <div className="catalogs-filter">
                        <label htmlFor="courier">
                            Courier:
                            <select
                                name="courier"
                                value={filters.courier}
                                onChange={e => {
                                    const courierId = Number(e.target.value)
                                    if (isNumber(courierId) && !isNaN(courierId)) {
                                        updateCourier(courierId)
                                    }
                                }}
                            >
                                <option value={0}>-- select --</option>
                                {map(couriersList, (c, idx) => (
                                    <option key={idx} value={c.courierId}>
                                        {c.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className="catalogs-filter">
                        <label htmlFor="name">
                            Name contains (min 3 char):
                            <input
                                type="text"
                                value={filters.name}
                                name="name"
                                onChange={e => {
                                    const val = e.target.value
                                    updateName(val)
                                }}
                            />
                        </label>
                    </div>
                </div>
            </div>
            {catalogs.length > 0 ? (
                <>
                    <Button
                        text="See shipping services not in catalogs"
                        onClick={() => {
                            setOpen(true)
                        }}
                    />
                    <div className="catalogs-table">
                        <div className="catalogs-table-row header">
                            <div>Catalog name</div>
                            <div>Zone name</div>
                            <div>Courier</div>
                            <div>Courier service</div>
                            <div>Catalog items</div>
                            <div>Actions</div>
                        </div>
                        {map(catalogs, (c, idx) => (
                            <div key={idx} className="catalogs-table-row">
                                <Catalog
                                    catalog={c}
                                    url={url}
                                    handleDelete={handleDelete}
                                    countries={countries}
                                    updateList={updateList}
                                />
                            </div>
                        ))}
                    </div>
                    <Modal
                        isOpen={open}
                        onRequestClose={() => {
                            setOpen(false)
                        }}
                        closeTimeoutMS={500}
                        shouldFocusAfterRender={false}
                        className={{
                            base: 'catalogs-modal',
                            afterOpen: 'catalogs-modal-afterOpen',
                            beforeClose: 'catalogs-modal-beforeClose',
                        }}
                        overlayClassName={{
                            base: 'catalogs-modal-overlay',
                            afterOpen: 'catalogs-modal-overlay-afterOpen',
                            beforeClose: 'catalogs-modal-overlay-beforeClose',
                        }}
                    >
                        {shippingServicesNotInCatalogs.length > 0 && (
                            <div className="zones-countries">
                                {map(
                                    sortBy(shippingServicesNotInCatalogs, s => s.destinationCountryCode),
                                    (s, idx) => (
                                        <p key={idx}>
                                            {s.destinationCountryCode}: {s.courierServiceId} -{' '}
                                            {getServiceName(s.courierServiceNames)}
                                        </p>
                                    )
                                )}
                            </div>
                        )}
                    </Modal>
                </>
            ) : (
                <p>There are no catalogs. Create one by clicking on the button "Create new catalog".</p>
            )}
        </div>
    )
}

export default CatalogList
