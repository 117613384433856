import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import { find, isNil } from 'lodash'
import Button from '../../atoms/Button/Button.jsx'
import getCountriesNames from '../../../services/countries/countries.js'

const WarehousePickup = ({ warehousePickup, url, handleDelete, countries, updateList }) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const getName = names => {
        const name = find(names, n => n.culture === 'it-IT')
        if (!isNil(name)) {
            return name.name
        }
        return ''
    }

    return (
        <>
            <div className="warehousePickup-logo-container">
                <img src={warehousePickup.logoUrl} alt={getName(warehousePickup.names)} />
            </div>
            <div>{getName(warehousePickup.names)}</div>
            <div>{getCountriesNames(warehousePickup.countries, countries)}</div>
            <div className="warehousePickups-cta">
                <Link to={`${url}/duplicate/${warehousePickup.dtoGuid}`}>
                    <Button text="Duplicate" />
                </Link>
                <Link to={`${url}/edit/${warehousePickup.dtoGuid}`}>
                    <Button text="Edit" />
                </Link>
                <Button
                    onClick={() => {
                        setShowConfirmationModal(true)
                    }}
                    text="Delete"
                />
            </div>
            <Modal
                isOpen={showConfirmationModal}
                onRequestClose={() => {
                    setShowConfirmationModal(false)
                }}
                closeTimeoutMS={500}
                shouldFocusAfterRender={false}
                className={{
                    base: 'warehousePickup-modal',
                    afterOpen: 'warehousePickup-modal-afterOpen',
                    beforeClose: 'warehousePickup-modal-beforeClose',
                }}
                overlayClassName={{
                    base: 'warehousePickup-modal-overlay',
                    afterOpen: 'warehousePickup-modal-overlay-afterOpen',
                    beforeClose: 'warehousePickup-modal-overlay-beforeClose',
                }}
            >
                <h3>Are you sure?</h3>
                <p>You are deleting the warehouse pickup {getName(warehousePickup.names)}.</p>
                <p>This operation cannot be undone.</p>
                <Button
                    onClick={() => {
                        setShowConfirmationModal(false)
                    }}
                    text="Cancel"
                />
                <Button
                    onClick={() => {
                        handleDelete(warehousePickup.dtoGuid)
                        updateList()
                        setShowConfirmationModal(false)
                    }}
                    text="Confirm"
                />
            </Modal>
        </>
    )
}

export default WarehousePickup
