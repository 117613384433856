/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { filter, includes, map, sortBy } from 'lodash'
import zonesModel from '../../../model/zones.js'
import Zone from '../../molecules/Couriers/Zone.jsx'
import Button from '../../atoms/Button/Button.jsx'

import './ZoneList.scss'

const ZoneList = ({ zones, url, handleDelete, countries, updateList, couriers }) => {
    const [countriesToExclude, setCountriesToExclude] = useState([])
    const [courierId, setCourierId] = useState(0)
    const [open, setOpen] = useState(false)
    const [selectedCourierId, setSelectedCourierId] = useState(0)

    const getCountriesNotInZones = async courierId => {
        try {
            const c = await zonesModel.getCountriesToExclude(courierId)
            setCountriesToExclude(c)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (courierId === 0) {
            setCountriesToExclude([])
            return
        }
        getCountriesNotInZones(courierId)
    }, [courierId])

    return zones.length > 0 ? (
        <div className="container zones">
            <Button
                text="See countries not in any zone"
                onClick={() => {
                    setOpen(true)
                }}
            />
            <div>
                <h3>Filter by courier</h3>
                <div>
                    <label htmlFor="courier">
                        Courier:
                        <select
                            name="courier"
                            onChange={e => {
                                const val = e.target.value
                                console.log(val)
                                setSelectedCourierId(Number(val))
                            }}
                            value={selectedCourierId}
                        >
                            <option value={0}>-- select --</option>
                            {map(couriers, (c, idx) => (
                                <option value={c.courierId} key={idx}>
                                    {c.name}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
            </div>
            <div className="zones-table">
                <div className="zones-table-row header">
                    <div>Zone name</div>
                    <div>Courier</div>
                    <div>Services</div>
                    <div>Countries</div>
                    <div>Actions</div>
                </div>
                {map(
                    selectedCourierId === 0 ? zones : filter(zones, z => z.courier.courierId === selectedCourierId),
                    (z, idx) => (
                        <div key={idx} className="zones-table-row">
                            <Zone
                                zone={z}
                                url={url}
                                handleDelete={handleDelete}
                                countries={countries}
                                updateList={updateList}
                            />
                        </div>
                    )
                )}
            </div>
            <Modal
                isOpen={open}
                onRequestClose={() => {
                    setOpen(false)
                }}
                closeTimeoutMS={500}
                shouldFocusAfterRender={false}
                className={{
                    base: 'zones-modal',
                    afterOpen: 'zones-modal-afterOpen',
                    beforeClose: 'zones-modal-beforeClose',
                }}
                overlayClassName={{
                    base: 'zones-modal-overlay',
                    afterOpen: 'zones-modal-overlay-afterOpen',
                    beforeClose: 'zones-modal-overlay-beforeClose',
                }}
            >
                <label htmlFor="courier">
                    <strong>Courier: </strong>
                    <select
                        name="courier"
                        onChange={e => {
                            const val = e.target.value
                            setCourierId(Number(val))
                        }}
                        value={courierId}
                    >
                        <option value={0}>-- select --</option>
                        {map(couriers, (c, idx) => (
                            <option value={c.courierId} key={idx}>
                                {c.name}
                            </option>
                        ))}
                    </select>
                </label>
                {courierId !== 0 && (
                    <div className="zones-countries">
                        {map(
                            sortBy(
                                filter(countries, x => !includes(countriesToExclude, x.code)),
                                item => item.name
                            ),
                            (c, idx) => (
                                <p key={idx}>
                                    {c.code} - {c.name}
                                </p>
                            )
                        )}
                    </div>
                )}
            </Modal>
        </div>
    ) : (
        <div className="container zones">
            <p>There are no zones. Create one by clicking on the button "Create new zone".</p>
        </div>
    )
}

export default ZoneList
