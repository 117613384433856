import { ACTION_TYPES } from './categoryBanner.actions'

const INITIAL_STATE = {
    list: [],
    categories: [],
}

const reducers = {}

reducers[ACTION_TYPES.BANNERS_RECEIVED] = (state, action) => {
    return {
        ...state,
        list: action.payload,
        editMode: action.payload.reduce((r, b) => {
            r[b.id] = false
            return r
        }, {}),
    }
}

reducers[ACTION_TYPES.CATEGORIES_RECEIVED] = (state, action) => {
    return {
        ...state,
        categories: action.payload,
    }
}

reducers[ACTION_TYPES.BANNERS_RECEIVED] = (state, action) => {
    return {
        ...state,
        list: action.payload,
    }
}

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type] || (() => state)
    return reducer(state, action)
}
