import { ACTION_TYPES } from './user.actions'

const INITIAL_STATE = {
    user: {},
    loggedIn: false,
    list: [],
}

const reducers = {}

reducers[ACTION_TYPES.LOGIN_SUCCESS] = (state, action) => {
    return {
        ...state,
        loggedIn: true,
        user: {
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            role: action.payload.role,
            username: action.payload.userName,
        },
    }
}

reducers[ACTION_TYPES.LOGOUT] = state => {
    return {
        ...state,
        user: {},
        loggedIn: false,
    }
}

reducers[ACTION_TYPES.USERS_RECEIVED] = (state, action) => {
    let lista = action.payload === undefined ? [] : action.payload
    return {
        ...state,
        list: [...lista],
    }
}

reducers[ACTION_TYPES.USER_LOCKED] = (state, action) => ({
    ...state,
    list: state.list.map(user => (user.id === action.payload.updatedUser.id ? action.payload.updatedUser : user)),
})

reducers[ACTION_TYPES.USER_UNLOCKED] = (state, action) => ({
    ...state,
    list: state.list.map(user => {
        return user.id === action.payload.updatedUser.id ? action.payload.updatedUser : user
    }),
})

reducers[ACTION_TYPES.USER_DELETED] = (state, action) => ({
    ...state,
    list: state.list.filter(q => q.id !== action.payload),
})

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type] || (() => state)
    return reducer(state, action)
}
