const NAMESPACE = 'REASSORTMENTNOTIFICATIONS'

export const ACTION_TYPES = {
    SEARCH_REASSORTMENTNOTIFICATIONS_REQUEST: `${NAMESPACE}/SEARCH_REASSORTMENTNOTIFICATIONS_REQUEST`,
    REASSORTMENTNOTIFICATIONS_RECEIVED: `${NAMESPACE}/REASSORTMENTNOTIFICATIONS_RECEIVED`,
    DELETE_REASSORTMENTNOTIFICATIONS_REQUEST: `${NAMESPACE}/DELETE_REASSORTMENTNOTIFICATIONS_REQUEST`,
    REASSORTMENTNOTIFICATIONS_DELETED: `${NAMESPACE}/REASSORTMENTNOTIFICATIONS_DELETED`,
}
export const searchReassortmentNotificationsRequest = searchTerm => ({
    type: ACTION_TYPES.SEARCH_REASSORTMENTNOTIFICATIONS_REQUEST,
    searchTerm,
})

export const reassortmentNotificationsReceived = notification => ({
    type: ACTION_TYPES.REASSORTMENTNOTIFICATIONS_RECEIVED,
    payload: notification,
})

export const deleteReassortmentNotificationRequest = ({ reassortmentNotificationId, lineId }) => ({
    type: ACTION_TYPES.DELETE_REASSORTMENTNOTIFICATIONS_REQUEST,
    payload: { reassortmentNotificationId, lineId },
})

export const reassortmentNotificationsDeleted = ({ reassortmentNotificationId, lineId }) => ({
    type: ACTION_TYPES.REASSORTMENTNOTIFICATIONS_DELETED,
    payload: { reassortmentNotificationId, lineId },
})
