import { forEach, isEmpty, isNil } from 'lodash'
import warehousePickups from '../../model/warehousePickups'

const validateNames = names => {
    let areNamesValid = true
    let errors = {}
    forEach(names, n => {
        const isNameValid = !isNil(n.name) && n.name !== ''
        areNamesValid = areNamesValid && isNameValid
        errors[n.culture] = isNameValid ? '' : 'Please enter a valid name'
    })

    return [areNamesValid, errors]
}

const validateWarehousePickup = warehousePickup => {
    let isWarehousePickupValid = true
    const errors = {}

    if (isNil(warehousePickup.countries) || isEmpty(warehousePickup.countries)) {
        isWarehousePickupValid = false
        errors.names = 'Please select at least a country.'
    }
    if (
        isNil(warehousePickup.warehousePickupId) ||
        warehousePickup.warehousePickupId === '' ||
        isNaN(Number(warehousePickup.warehousePickupId))
    ) {
        isWarehousePickupValid = false
        errors.warehousePickupId = 'Please enter a valid number.'
    }

    const [namesValid, namesError] = validateNames(warehousePickup.names)

    isWarehousePickupValid = namesValid && isWarehousePickupValid
    errors.names = namesError
    // //TODO: logourl

    return [isWarehousePickupValid, errors]
}

const submitWarehousePickup = async (warehousePickup, endpoint, method) => {
    const [isValid, errors] = validateWarehousePickup(warehousePickup)
    if (isValid) {
        warehousePickup.warehousePickupId = Number(warehousePickup.warehousePickupId)
        return warehousePickups.submit(warehousePickup, endpoint, method)
    }

    return errors
}

export { submitWarehousePickup }
