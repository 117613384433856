import React from 'react'

import './button.scss'

const Button = ({ onClick, text, type, disabled, customClassName = '' }) => (
    <button className={`${customClassName}`} onClick={onClick} type={type} disabled={disabled}>
        {text}
    </button>
)

export default Button
