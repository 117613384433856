import React from 'react'

import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Button from '../../atoms/Button/Button.jsx'
import '../Answers/AnswerForm.scss'

export default class AnswerForm extends React.Component {
    state = { answer: '' }
    submitAnswer = e => {
        e.preventDefault()
        this.props.answerQuestionHandler(this.props.questionId, this.state.answer, this.props.culture)
        this.setState(prevState => ({
            ...prevState,
            answer: '',
        }))
    }
    render() {
        return (
            <form className="answerForm">
                <div className="answerFormTitle">
                    <h3>Write your answer</h3>
                </div>
                <div className="answerFormTextArea">
                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            toolbar: ['bold', 'italic', 'link', 'numberedList'],

                            link: {
                                decorators: {
                                    toggleDownloadable: {
                                        mode: 'manual',
                                        label: 'External link',
                                        attributes: {
                                            target: '_blank',
                                        },
                                    },
                                },
                            },
                        }}
                        data={this.state.answer}
                        onChange={(event, editor) => {
                            const value = editor.getData()
                            this.setState(prevState => ({
                                ...prevState,
                                answer: value,
                            }))
                        }}
                    />
                </div>
                <div className="answerFormSubmit">
                    <Button onClick={this.submitAnswer} type="submit" text="Submit" />
                </div>
            </form>
        )
    }
}
