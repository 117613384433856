import React from 'react'
import { isNil } from 'lodash'
import Button from '../../atoms/Button/Button.jsx'

const CatalogFormItem = ({ index, item, update, deleteItem, errors }) => {
    return (
        <div className="catalog-form-table-row">
            <div>
                <Button
                    onClick={() => {
                        deleteItem(index)
                    }}
                    type="button"
                    customClassName="button-round"
                    text={
                        <svg height="16" width="16">
                            <path d="M2 2 L15 15" />
                            <path d="M2 15 L15 2" />
                        </svg>
                    }
                />
                <div>
                    <label className="catalog-form-label" htmlFor="fromWeight">
                        From weight (included) [kg]
                        <input
                            type="text"
                            name="fromWeight"
                            value={isNil(item.fromWeight) ? '' : item.fromWeight}
                            onChange={e => {
                                const value = e.target.value
                                update(value, 'fromWeight', index)
                            }}
                        />
                        {!isNil(errors) && !isNil(errors.fromWeight) && <p className="error">{errors.fromWeight}</p>}
                    </label>
                    <label className="catalog-form-label" htmlFor="toWeight">
                        To weight (not included) [kg]
                        <input
                            type="text"
                            name="toWeight"
                            value={isNil(item.toWeight) ? '' : item.toWeight}
                            onChange={e => {
                                const value = e.target.value
                                update(value, 'toWeight', index)
                            }}
                        />
                        {!isNil(errors) && !isNil(errors.toWeight) && <p className="error">{errors.toWeight}</p>}
                    </label>
                    <label className="catalog-form-label" htmlFor="price">
                        Price [EUR without VAT]
                        <input
                            type="text"
                            name="price"
                            value={isNil(item.price) ? '' : item.price}
                            onChange={e => {
                                const value = e.target.value
                                update(value, 'price', index)
                            }}
                        />
                        {!isNil(errors) && !isNil(errors.price) && <p className="error">{errors.price}</p>}
                    </label>
                </div>
            </div>
            {!isNil(errors) && !isNil(errors.generic) && <p className="error">{errors.generic}</p>}
            {!isNil(errors) && !isNil(errors.gapTo) && <p className="error">{errors.gapTo}</p>}
            {!isNil(errors) && !isNil(errors.gapFrom) && <p className="error">{errors.gapFrom}</p>}
        </div>
    )
}

export default CatalogFormItem
