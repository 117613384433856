import React, { useState, useEffect } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { concat, filter, includes, map } from 'lodash'
import warehousePickup from '../../model/warehousePickups.js'
import countries from '../../model/countries.js'
import Loading from '../molecules/Loading/Loading.jsx'
import Header from '../organisms/Header/Header.jsx'
import Button from '../atoms/Button/Button.jsx'
import WarehousePickupsList from '../organisms/Couriers/WarehousePickupsList.jsx'
import WarehousePickupForm from '../molecules/Couriers/WarehousePickupForm.jsx'

import './errors.scss'

const WarehousePickupPage = props => {
    const [isLoading, setLoading] = useState(true)
    const [warehousePickups, setWarehousePickups] = useState([])
    const [countriesList, setCountriesList] = useState([])
    const [errors, setErrors] = useState([])

    const handleDelete = async id => {
        try {
            const isSuccess = await warehousePickup.deleteWarehousePickup(id)
            if (isSuccess) {
                const newList = filter(warehousePickups, w => w.dtoGuid !== id)
                setWarehousePickups(newList)
                setErrors([])
            }
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
    }

    const fetchWarehousePickups = async () => {
        try {
            setLoading(true)
            const result = await warehousePickup.getWarehousePickups()
            setWarehousePickups(result)
            setErrors([])
            setLoading(false)
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
            setLoading(false)
        }
    }

    const fetchCountries = async () => {
        try {
            const fetchedCountries = await countries.getCountries('en-US')
            setCountriesList(fetchedCountries)
            setErrors([])
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
    }

    useEffect(() => {
        fetchWarehousePickups()
        fetchCountries()
    }, [])

    return isLoading ? (
        <Loading />
    ) : (
        <div>
            <Header>
                <div className="title">
                    <h2>Warehouse pickup</h2>
                </div>
                <div className="cta">
                    <Link to={`${props.match.url}/create`}>
                        <Button text="Create new warehouse pickup" />
                    </Link>
                </div>
            </Header>
            {errors.length > 0 && (
                <div className="errors-container">
                    {map(errors, (e, idx) => (
                        <p key={idx} className="error">
                            {e}
                        </p>
                    ))}
                </div>
            )}
            <Switch>
                <Route
                    exact
                    path={props.match.url}
                    render={() => (
                        <WarehousePickupsList
                            warehousePickups={warehousePickups}
                            url={props.match.url}
                            handleDelete={handleDelete}
                            countries={countriesList}
                            updateList={fetchWarehousePickups}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${props.match.url}/create`}
                    render={() => (
                        <WarehousePickupForm
                            mode="create"
                            url={props.match.url}
                            updateList={fetchWarehousePickups}
                            countries={countriesList}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${props.match.url}/duplicate/:id`}
                    render={routeProps => (
                        <WarehousePickupForm
                            mode="create"
                            url={props.match.url}
                            id={routeProps.match.params.id}
                            updateList={fetchWarehousePickups}
                            countries={countriesList}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${props.match.url}/edit/:id`}
                    render={routeProps => (
                        <WarehousePickupForm
                            mode="edit"
                            url={props.match.url}
                            id={routeProps.match.params.id}
                            updateList={fetchWarehousePickups}
                            countries={countriesList}
                        />
                    )}
                />
            </Switch>
        </div>
    )
}

export default WarehousePickupPage
