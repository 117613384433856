import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

const searchReassortmentNotifications = searchTerm => {
    const url = searchTerm.includes('@')
        ? `${config.apiRoot}/v1/reassortmentNotifications?email=${encodeURIComponent(searchTerm.trim())}`
        : `${config.apiRoot}/v1/reassortmentNotifications/${searchTerm.trim()}`
    return fetch(url, { method: 'GET', headers: authHeader() }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw r
    })
}

const deleteReassortmentNotification = ({ reassortmentNotificationId, lineId }) =>
    fetch(`${config.apiRoot}/v1/ReassortmentNotifications/${reassortmentNotificationId}/line/${lineId}`, {
        method: 'DELETE',
        headers: authHeader(),
    }).then(r => {
        if (!r.ok) throw new ServerError(r)
    })

export default {
    searchReassortmentNotifications,
    deleteReassortmentNotification,
}
