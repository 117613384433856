import { ACTION_TYPES } from './countries.actions'

const INITIAL_STATE = {
    countries: [],
}

const reducers = {}

reducers[ACTION_TYPES.COUNTRIES_RECEIVED] = (state, action) => {
    let lista = action.payload === undefined ? [] : action.payload

    return {
        ...state,
        countries: [...lista],
    }
}

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type] || (() => state)
    return reducer(state, action)
}
