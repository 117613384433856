const KEY = 'STATE'

const get = () =>
    new Promise(resolve => {
        const savedState = window.localStorage.getItem(KEY)
        const state = savedState ? JSON.parse(savedState) : undefined
        resolve(state)
    })

const set = state =>
    new Promise(resolve => {
        window.localStorage.setItem(KEY, JSON.stringify(state))
        resolve(state)
    })

const storeToken = token =>
    new Promise(resolve => {
        window.localStorage.setItem('TOKEN', token)
        resolve(token)
    })

const clear = () =>
    new Promise(resolve => {
        window.localStorage.clear()
        resolve()
    })

const getToken = () => window.localStorage.getItem('TOKEN')

export default {
    get,
    set,
    clear,
    storeToken,
    getToken,
}
