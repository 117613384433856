import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import { isEmpty, isNil, join, map } from 'lodash'
import shippingServices from '../../../model/shippingServices.js'
import { getServiceName } from '../../../services/couriers/shippingServices.js'
import getCountriesNames from '../../../services/countries/countries.js'
import Button from '../../atoms/Button/Button.jsx'

const Zone = ({ zone, url, handleDelete, countries, updateList }) => {
    const [showCountries, setShowCountries] = useState(false)
    const [showServices, setShowServices] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [courierServices, setCourierServices] = useState([])

    const fetchCourierServices = async serviceIds => {
        try {
            const services = await shippingServices.getByServiceIds(serviceIds)
            setCourierServices(services)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div>{zone.name}</div>
            <div>{zone.courier.name}</div>
            <div
                className="clickable"
                onClick={() => {
                    if (!isNil(zone.courierServices) && !isEmpty(zone.courierServices)) {
                        fetchCourierServices(join(zone.courierServices, ','))
                    }
                    setShowServices(true)
                }}
            >
                See services
            </div>
            <div
                className="clickable"
                onClick={() => {
                    setShowCountries(true)
                }}
            >
                See countries
            </div>
            <div className="zones-cta">
                <Link to={`${url}/duplicate/${zone.dtoGuid}`}>
                    <Button text="Duplicate" />
                </Link>
                <Link to={`${url}/edit/${zone.dtoGuid}`}>
                    <Button text="Edit" />
                </Link>
                <Button
                    onClick={() => {
                        setShowConfirmationModal(true)
                    }}
                    text="Delete"
                />
            </div>
            <Modal
                isOpen={showServices}
                onRequestClose={() => {
                    setShowServices(false)
                }}
                closeTimeoutMS={500}
                shouldFocusAfterRender={false}
                className={{
                    base: 'zone-services',
                    afterOpen: 'zone-services-afterOpen',
                    beforeClose: 'zone-services-beforeClose',
                }}
                overlayClassName={{
                    base: 'zone-services-overlay',
                    afterOpen: 'zone-services-overlay-afterOpen',
                    beforeClose: 'zone-services-overlay-beforeClose',
                }}
            >
                <h3>Services in {zone.name}:</h3>
                {isEmpty(courierServices)
                    ? 'No shipping services available in this zone. Create one before you create a catalog.'
                    : map(courierServices, (s, idx) => (
                          <div key={idx}>
                              <p>{`${getServiceName(s.courierServiceNames)} (${getCountriesNames(
                                  [s.destinationCountryCode],
                                  countries
                              )})`}</p>
                          </div>
                      ))}
                <Button
                    onClick={() => {
                        setShowServices(false)
                    }}
                    text="Close"
                />
            </Modal>
            <Modal
                isOpen={showCountries}
                onRequestClose={() => {
                    setShowCountries(false)
                }}
                closeTimeoutMS={500}
                shouldFocusAfterRender={false}
                className={{
                    base: 'zone-countries',
                    afterOpen: 'zone-countries-afterOpen',
                    beforeClose: 'zone-countries-beforeClose',
                }}
                overlayClassName={{
                    base: 'zone-countries-overlay',
                    afterOpen: 'zone-countries-overlay-afterOpen',
                    beforeClose: 'zone-countries-overlay-beforeClose',
                }}
            >
                <h3>Countries in {zone.name}:</h3>
                <p>{getCountriesNames(zone.countries, countries)}</p>
                <Button
                    onClick={() => {
                        setShowCountries(false)
                    }}
                    text="Close"
                />
            </Modal>
            <Modal
                isOpen={showConfirmationModal}
                onRequestClose={() => {
                    setShowConfirmationModal(false)
                }}
                closeTimeoutMS={500}
                shouldFocusAfterRender={false}
                className={{
                    base: 'zone-modal',
                    afterOpen: 'zone-modal-afterOpen',
                    beforeClose: 'zone-modal-beforeClose',
                }}
                overlayClassName={{
                    base: 'zone-modal-overlay',
                    afterOpen: 'zone-modal-overlay-afterOpen',
                    beforeClose: 'zone-modal-overlay-beforeClose',
                }}
            >
                <h3>Are you sure?</h3>
                <p>You are deleting the zone {zone.name}.</p>
                <p>
                    This will also delete all the <strong>catalogs</strong> linked to this zone.
                </p>
                <p>This operation cannot be undone.</p>
                <Button
                    onClick={() => {
                        setShowConfirmationModal(false)
                    }}
                    text="Cancel"
                />
                <Button
                    onClick={() => {
                        handleDelete(zone.dtoGuid)
                        updateList()
                        setShowConfirmationModal(false)
                    }}
                    text="Confirm"
                />
            </Modal>
        </>
    )
}

export default Zone
