var isNil = require('lodash/isNil')
import { ACTION_TYPES } from './destinations.actions'

const INITIAL_STATE = {
    list: [],
}

const reducers = {}

reducers[ACTION_TYPES.DESTINATIONSNOTES_RECEIVED] = (state, action) => {
    let lista = action.payload.map(d => {
        d.selected = false
        return d
    })
    return {
        ...state,
        list: lista,
    }
}

reducers[ACTION_TYPES.DESTINATIONNOTE_CLEARED] = (state, action) => ({
    ...state,
    list: state.list.map(q => {
        if (q.code === action.payload.code) {
            q.note = ''
            return q
        }
        return q
    }),
})

reducers[ACTION_TYPES.DESTINATIONNOTE_SET] = (state, action) => ({
    ...state,
    list: state.list.map(q => {
        if (q.culture === action.payload.culture && q.code === action.payload.code) {
            q.note = action.payload.note
            return q
        }
        return q
    }),
})

reducers[ACTION_TYPES.DESTINATIONNOTE_CHANGE] = (state, action) => ({
    ...state,
    list: state.list.map(q => {
        if (q.culture === action.payload.culture && q.code === action.payload.code) {
            q.note = action.payload.note
            return q
        }
        return q
    }),
})

reducers[ACTION_TYPES.SELECT_DESTINATIONNOTE] = (state, action) => ({
    ...state,
    list: state.list.map(q => {
        if (q.culture === action.payload.culture && q.code === action.payload.code) {
            q.selected = isNil(action.payload.selected) ? false : action.payload.selected
            return q
        }
        return q
    }),
})

reducers[ACTION_TYPES.DESTINATIONNOTE_BULK_SELECTED] = (state, action) => ({
    ...state,
    list: state.list.map(q => {
        if (q.culture === action.payload.culture) {
            q.selected = isNil(action.payload.selected) ? false : action.payload.selected
            return q
        }
        return q
    }),
})

reducers[ACTION_TYPES.DESTINATIONNOTE_BULK_SET] = (state, action) => ({
    ...state,
    list: state.list.map(q => {
        if (q.culture === action.payload.culture && !isNil(q.selected) && q.selected === true) {
            q.note = isNil(action.payload.note) ? '' : action.payload.note
            return q
        }
        return q
    }),
})

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type] || (() => state)
    return reducer(state, action)
}
