import { call, put, takeLatest } from 'redux-saga/effects'

import * as actions from './countries.actions'
import * as commonActions from '../common/common.actions'
import * as userActions from '../user/user.actions'
import { ACTION_TYPES } from './countries.actions'
import countries from '../../model/countries'
import * as modalActions from '../modal/modal.actions'
import modal from '../modal/modal.types'
import ServerError from '../../errors/ServerError'
import messages from '../../helpers/messages'

export function* fetchCountries(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const list = yield call(countries.getCountries, action.payload)
        yield put(actions.countriesReceived(list))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export default function*() {
    yield takeLatest(ACTION_TYPES.READ_COUNTRIES, fetchCountries)
}
