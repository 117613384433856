import { filter, includes, join, map } from 'lodash'

const getCountriesNames = (includedCountries, countries) => {
    const countriesNames = map(
        filter(countries, c => includes(includedCountries, c.code)),
        c => `${c.code}: ${c.name}`
    )

    return join(countriesNames, ', ')
}

export default getCountriesNames
