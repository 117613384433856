import { isNil, map } from 'lodash'

const _validateGtag = () => {
    if (isNil(window.gtag)) {
        console.error('window.gtag is null or undefined')
        init()
    }
}

export const init = ({ debug }) => {
    // Analytics 4
    if (!window.dataLayer) {
        window.dataLayer = window.dataLayer || []
    }
    function gtag() {
        window.dataLayer.push(arguments)
    }
    window.gtag = gtag
    _validateGtag()
    gtag('js', new Date())
}

const getGoogleId = item => (item.productType === 'Product' ? `${item.slug}-${item.optionIds[0]}` : item.slug)

export const refundGA4 = ({
    transaction_id,
    currency,
    value,
    items,
    analytics4MeasurementId,
    analytics4MeasurementGlobalId,
}) => {
    _validateGtag()

    let obj = {
        transaction_id,
        send_to: [analytics4MeasurementId, analytics4MeasurementGlobalId],
        currency,
        value,
        items: map(items, li => ({
            item_id: getGoogleId(li),
            item_name: li.slug,
            price: li.pricePerUnitTransformed,
            quantity: li.quantity,
        })),
    }
    window.gtag('event', 'refund', obj)
}
