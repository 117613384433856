const NAMESPACE = 'COUNTRIES'

export const ACTION_TYPES = {
    READ_COUNTRIES: `${NAMESPACE}/READ_COUNTRIES`,
    COUNTRIES_RECEIVED: `${NAMESPACE}/COUNTRIES_RECEIVED`,
}

export const readCountries = culture => ({
    type: ACTION_TYPES.READ_COUNTRIES,
    payload: culture,
})

export const countriesReceived = list => ({
    type: ACTION_TYPES.COUNTRIES_RECEIVED,
    payload: list,
})
