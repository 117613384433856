const NAMESPACE = 'REVIEWS'

export const ACTION_TYPES = {
    SEARCH_REVIEWS: `${NAMESPACE}/SEARCH_REVIEWS`,
    REVIEWS_RECEIVED: `${NAMESPACE}/REVIEWS_RECEIVED`,
    DELETE_REVIEW_REQUEST: `${NAMESPACE}/DELETE_REVIEW_REQUEST`,
    REVIEW_DELETED: `${NAMESPACE}/REVIEW_DELETED`,
    ANSWER_REVIEW_REQUEST: `${NAMESPACE}/ANSWER_REVIEW_REQUEST`,
    REVIEW_ANSWERED: `${NAMESPACE}/REVIEW_ANSWERED`,
    APPROVE_REVIEW_REQUEST: `${NAMESPACE}/APPROVE_REVIEW_REQUEST`,
    REVIEW_APPROVED: `${NAMESPACE}/REVIEW_APPROVED`,
}

export const searchReviews = culture => ({
    type: ACTION_TYPES.SEARCH_REVIEWS,
    payload: culture,
})

export const reviewsReceived = list => ({
    type: ACTION_TYPES.REVIEWS_RECEIVED,
    payload: list,
})

export const deleteReview = reviewId => ({
    type: ACTION_TYPES.DELETE_REVIEW_REQUEST,
    payload: reviewId,
})

export const reviewDeleted = reviewId => ({
    type: ACTION_TYPES.REVIEW_DELETED,
    payload: reviewId,
})

export const answerReviewRequest = (reviewId, answer, culture) => ({
    type: ACTION_TYPES.ANSWER_REVIEW_REQUEST,
    payload: { reviewId, answer, culture },
})

export const reviewAnswered = (reviewId, answer) => ({
    type: ACTION_TYPES.REVIEW_ANSWERED,
    payload: { reviewId, answer },
})

export const approveReviewRequest = reviewId => ({
    type: ACTION_TYPES.APPROVE_REVIEW_REQUEST,
    payload: reviewId,
})

export const reviewApproved = reviewId => ({
    type: ACTION_TYPES.REVIEW_APPROVED,
    payload: reviewId,
})
