import React from 'react'
import { connect } from 'react-redux'

import * as actions from '../../../redux/user/user.actions'
import ButtonMenu from '../../atoms/ButtonMenu/ButtonMenu.jsx'

const Logout = ({ logout }) => <ButtonMenu onClick={logout} text="Logout" />

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(actions.logoutRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
