const createNewTable = () => {
    //Creo il div per newTable
    var divNewTableContainer = document.createElement('div')
    divNewTableContainer.id = 'lrdg_tblcnt'
    divNewTableContainer.className = 'wrapper wrapper--white pad-in clearfix'
    var divNewTableContainerChild = document.createElement('div')
    divNewTableContainerChild.className = 'gutter clearfix'
    var divNewTableTitle = document.createElement('h2')
    divNewTableTitle.id = 'lrdg_ttl'
    divNewTableTitle.className = 'section__title'
    var divNewTableClearfix = document.createElement('div')
    divNewTableClearfix.className = 'clearfix'
    var divNewTable = document.createElement('div')
    divNewTable.id = 'lrdg_tbl'
    divNewTable.className = 'gutter'
    divNewTableContainerChild.appendChild(divNewTableTitle)
    divNewTableClearfix.appendChild(divNewTable)
    divNewTableContainer.appendChild(divNewTableContainerChild)
    divNewTableContainer.appendChild(divNewTableClearfix)

    return { divNewTableContainer, divNewTable, divNewTableTitle }
}

const getResultingTableText = () => {
    var lrdgTableText = document.getElementById('lrdg_cntnr')
    lrdgTableText.innerHTML = ''
    return lrdgTableText
}

const removeUnderscore = () => {
    var removeUnderscore = document.getElementsByClassName('table__cell')
    for (let i = 0; i < removeUnderscore.length; i++) {
        if (removeUnderscore[i].innerHTML === '_') {
            removeUnderscore[i].innerHTML = '-'
        }
    }
}

const createNotes = lrdgTableText => {
    var tableNotes = document.getElementById('notes').value
    //Creo il div per le note
    if (tableNotes && tableNotes !== '') {
        var divNotes = document.createElement('div')
        divNotes.id = 'lrdg_ntscnt'
        divNotes.className = 'wrapper wrapper--white pad-in clearfix'
        var divNotesChild = document.createElement('div')
        divNotesChild.className = 'gutter clearfix'
        var divNotesText = document.createElement('em')
        divNotesText.innerText = tableNotes
        divNotesChild.appendChild(divNotesText)
        divNotes.appendChild(divNotesChild)
        lrdgTableText.appendChild(divNotes)
    }
}

const clearPreviewImageContainer = () => {
    var imgContainer = document.getElementById('lrdg_imgcnt')
    if (imgContainer) {
        document.getElementById('lrdg_imgcnt').outerHTML = ''
    }
}

const createImageDiv = (lrdgTableText, imageSelect) => {
    //Creo il div per l'immagine
    if (imageSelect.options[imageSelect.selectedIndex].value != 'noImage') {
        var divImg = document.createElement('div')
        divImg.id = 'lrdg_imgcnt'
        divImg.className = 'wrapper wrapper--white no-gutter clearfix'
        var divImgChild = document.createElement('div')
        divImgChild.className = 'no-gutter clearfix'
        var divImgImg = document.createElement('img')
        divImgImg.id = 'lrdg_img'
        divImgImg.className = 'img-responsive img-responsive--auto'
        divImgImg.src = ''
        divImgImg.alt = ''
        divImgChild.appendChild(divImgImg)
        divImg.appendChild(divImgChild)
        lrdgTableText.appendChild(divImg)

        var selected = imageSelect.options[imageSelect.selectedIndex].value
        var selectedAlt = imageSelect.options[imageSelect.selectedIndex].text
        document.getElementById('lrdg_img').src = selected
        document.getElementById('lrdg_img').alt = selectedAlt
    }
}

const setFinalVisibleResultToCopy = lrdgTableText => {
    document.getElementById('tableText').value = lrdgTableText.innerHTML
}

function createShoesTable(imageSelect) {
    const lrdgTableText = getResultingTableText()
    clearPreviewImageContainer()

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    var forMultipleTable = document.getElementsByClassName('lrdg_newTable')

    for (let t = 0; t < forMultipleTable.length; t++) {
        const { divNewTableContainer, divNewTable, divNewTableTitle } = createNewTable()
        lrdgTableText.appendChild(divNewTableContainer)

        var tableTitle = document.getElementById('inputTitle_' + t).value
        var excelData = document.getElementById('csv_' + t).value

        if (!excelData || excelData === '' || !tableTitle || tableTitle === '') continue
        if (imageSelect.value === 'default') return

        //Prendo i dati della tabella

        // split into rows
        let excelRow = excelData.split(String.fromCharCode(10))

        // split rows into columns
        for (let i = 0; i < excelRow.length; i++) {
            excelRow[i] = excelRow[i].split(String.fromCharCode(9))
        }

        // start to create the HTML table
        var myTable = document.createElement('table')
        myTable.className = 'table table--responsive table--size-guide'
        var myColGroup = document.createElement('colgroup')
        var myTbody = document.createElement('tbody')
        myTbody.className = 'table__body lrdg_tblbd'

        var widthCol = Math.floor(100 / excelRow[0].length)

        // Loop over the rows
        for (let i = 0; i < excelRow.length - 1; i++) {
            // create a row in the HTML table
            var myRow = document.createElement('tr')
            myRow.className = 'table__row'

            // Loop over the columns and add TD to the TR
            for (let j = 0; j < excelRow[i].length; j++) {
                // Loop over the row columns
                if (excelRow[i][j].length != 0) {
                    var myCell = document.createElement('td')
                    myCell.className = 'table__cell'
                    myCell.dataset.title = excelRow[0][j]
                    myCell.innerHTML = excelRow[i][j]
                }
                myRow.appendChild(myCell)
            }
            myTbody.appendChild(myRow)
        }
        myTable.appendChild(myColGroup)
        myTable.appendChild(myTbody)
        divNewTableTitle.innerHTML = tableTitle
        divNewTable.appendChild(myTable)

        //cambio la classe delle celle nella prima e seconda colonna

        for (let i = 0; i < myTable.rows[0].cells.length; i++) {
            let firstCellFirstRow = myTable.rows[0].cells[i]
            firstCellFirstRow.className = 'table__cell table__cell--head'
            firstCellFirstRow.dataset.title = ''
        }

        // for (let i = 1; i < myTable.rows.length; i++) {
        //     let firstCellFirstRow = myTable.rows[0].cells[0]
        //     var secondCellFirstRow = myTable.rows[0].cells[1]
        //     firstCellFirstRow.className = "table__cell table__cell--head lrdg_rmvl"
        //     // secondCellFirstRow.className = "table__cell table__cell--head lrdg_rmvl"

        //     var firstCellEveryRow = myTable.rows[i].cells[0]
        //     var firstCellEveryRowValue = firstCellEveryRow.innerHTML
        //     firstCellEveryRow.innerHTML = ""
        //     let myLabel = document.createElement("span")
        //     myLabel.className = "size-guide__label-point label_rmvl"
        //     myLabel.innerHTML = firstCellEveryRowValue
        //     firstCellEveryRow.appendChild(myLabel)

        //     // var secondCellEveryRow = myTable.rows[i].cells[1]
        //     // var secondCellEveryRowValue = secondCellEveryRow.innerHTML
        //     // secondCellEveryRow.innerHTML = ""
        //     // const myLabelText = document.createElement("strong")
        //     // myLabelText.className = "size-guide__label"
        //     // myLabelText.innerHTML = secondCellEveryRowValue
        //     // secondCellEveryRow.appendChild(myLabelText)

        //     var firstColumn = myTable.rows[i].cells[0]
        //     firstColumn.dataset.title = ""
        //     var secondColumn = myTable.rows[i].cells[1]
        //     secondColumn.dataset.title = ""
        // }

        // var removeValue = document.getElementsByClassName("lrdg_rmvl")

        // for (let i = 0; i < removeValue.length; i++) {
        //     removeValue[i].innerHTML = ""
        // }

        // var removeLabelPoint = document.getElementsByClassName("label_rmvl")
        // for (let i = 0; i < removeLabelPoint.length; i++) {
        //     if (removeLabelPoint[i].innerHTML === "_") {
        //         removeLabelPoint[i].innerHTML = ""
        //         removeLabelPoint[i].classList.remove("size-guide__label-point")
        //     }
        // }

        removeUnderscore()

        // if (document.getElementsByClassName("size-guide__label-point").length === 0) {
        //     for (let i = 0; i < myTable.rows.length; i++) {
        //         myTable.rows[i].cells[0].remove()
        //     }
        // }

        //Trovami le colonne
        for (let j = 0; j < excelRow[0].length; j++) {
            // Loop over the row columns
            var myCol = document.createElement('col')
            myCol.style.width = widthCol + '%'
            myColGroup.appendChild(myCol)
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    createNotes(lrdgTableText)
    createImageDiv(lrdgTableText, imageSelect)
    setFinalVisibleResultToCopy(lrdgTableText)
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///CREATE CLOTHING TABLE
function createClothingTable(imageSelect) {
    const lrdgTableText = getResultingTableText()
    clearPreviewImageContainer()

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    var forMultipleTable = document.getElementsByClassName('lrdg_newTable')

    for (let t = 0; t < forMultipleTable.length; t++) {
        const { divNewTableContainer, divNewTable, divNewTableTitle } = createNewTable()
        lrdgTableText.appendChild(divNewTableContainer)
        var tableTitle = document.getElementById('inputTitle_' + t).value
        var excelData = document.getElementById('csv_' + t).value

        if (!excelData || excelData === '' || !tableTitle || tableTitle === '') continue
        if (imageSelect.value === 'default') return

        //Prendo i dati della tabella

        // split into rows
        let excelRow = excelData.split(String.fromCharCode(10))

        // split rows into columns
        for (let i = 0; i < excelRow.length; i++) {
            excelRow[i] = excelRow[i].split(String.fromCharCode(9))
        }

        // start to create the HTML table
        var myTable = document.createElement('table')
        myTable.className = 'table table--responsive table--size-guide'
        var myColGroup = document.createElement('colgroup')
        var myTbody = document.createElement('tbody')
        myTbody.className = 'table__body lrdg_tblbd'

        var widthCol = Math.floor(100 / excelRow[0].length)

        // Loop over the rows
        for (let i = 0; i < excelRow.length - 1; i++) {
            // create a row in the HTML table
            var myRow = document.createElement('tr')
            myRow.className = 'table__row'

            // Loop over the columns and add TD to the TR
            for (let j = 0; j < excelRow[i].length; j++) {
                // Loop over the row columns
                if (excelRow[i][j].length != 0) {
                    var myCell = document.createElement('td')
                    myCell.className = 'table__cell'
                    myCell.dataset.title = excelRow[0][j]
                    myCell.innerHTML = excelRow[i][j]
                }
                myRow.appendChild(myCell)
            }
            myTbody.appendChild(myRow)
        }
        myTable.appendChild(myColGroup)
        myTable.appendChild(myTbody)
        divNewTableTitle.innerHTML = tableTitle
        divNewTable.appendChild(myTable)

        //cambio la classe delle celle nella prima e seconda colonna

        for (let i = 0; i < myTable.rows[0].cells.length; i++) {
            let firstCellFirstRow = myTable.rows[0].cells[i]
            firstCellFirstRow.className = 'table__cell table__cell--head'
            firstCellFirstRow.dataset.title = ''
        }

        for (let i = 1; i < myTable.rows.length; i++) {
            let firstCellFirstRow = myTable.rows[0].cells[0]
            // var secondCellFirstRow = myTable.rows[0].cells[1]
            firstCellFirstRow.className = 'table__cell table__cell--head lrdg_rmvl'
            // secondCellFirstRow.className = "table__cell table__cell--head lrdg_rmvl"

            var firstCellEveryRow = myTable.rows[i].cells[0]
            var firstCellEveryRowValue = firstCellEveryRow.innerHTML
            firstCellEveryRow.innerHTML = ''
            let myLabel = document.createElement('span')
            myLabel.className = 'size-guide__label-point label_rmvl'
            myLabel.innerHTML = firstCellEveryRowValue
            firstCellEveryRow.appendChild(myLabel)

            var secondCellEveryRow = myTable.rows[i].cells[1]
            var secondCellEveryRowValue = secondCellEveryRow.innerHTML
            secondCellEveryRow.innerHTML = ''
            const myLabelText = document.createElement('strong')
            myLabelText.className = 'size-guide__label'
            myLabelText.innerHTML = secondCellEveryRowValue
            secondCellEveryRow.appendChild(myLabelText)

            var firstColumn = myTable.rows[i].cells[0]
            firstColumn.dataset.title = ''
            var secondColumn = myTable.rows[i].cells[1]
            secondColumn.dataset.title = ''
        }

        var removeValue = document.getElementsByClassName('lrdg_rmvl')

        for (let i = 0; i < removeValue.length; i++) {
            removeValue[i].innerHTML = ''
        }

        var removeLabelPoint = document.getElementsByClassName('label_rmvl')
        for (let i = 0; i < removeLabelPoint.length; i++) {
            if (removeLabelPoint[i].innerHTML === '_') {
                removeLabelPoint[i].innerHTML = ''
                removeLabelPoint[i].classList.remove('size-guide__label-point')
            }
        }

        removeUnderscore()

        if (document.getElementsByClassName('size-guide__label-point').length === 0) {
            for (let i = 0; i < myTable.rows.length; i++) {
                myTable.rows[i].cells[0].remove()
            }
        }

        //Trovami le colonne
        for (let j = 0; j < excelRow[0].length; j++) {
            // Loop over the row columns
            var myCol = document.createElement('col')
            myCol.style.width = widthCol + '%'
            myColGroup.appendChild(myCol)
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    createNotes(lrdgTableText)
    createImageDiv(lrdgTableText, imageSelect)
    setFinalVisibleResultToCopy(lrdgTableText)
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///ADD NEW TABLE

function addNewTable() {
    var tableCounter = document.getElementsByClassName('lrdg_newTable').length

    //Creo i nuovi input per la nuova tabella
    var tablesContainer = document.getElementById('lrdg_tablesContainer')
    var divAddNewTableContainer = document.createElement('div')
    divAddNewTableContainer.className = 'lrdg_newTable'
    var divAddNewTableTitle = document.createElement('h3')
    divAddNewTableTitle.innerText = 'Insert title here!'
    var divAddNewTableTitleInput = document.createElement('input')
    divAddNewTableTitleInput.id = 'inputTitle_' + tableCounter
    divAddNewTableTitleInput.placeholder = "Here's the title of your table"
    divAddNewTableTitleInput.type = 'text'
    divAddNewTableTitleInput.style = 'width: 100%;'
    var br = document.createElement('br')
    var divAddNewTableCsv = document.createElement('h3')
    divAddNewTableCsv.innerText = 'Insert excel here!'
    var divAddNewTableCsvInput = document.createElement('textarea')
    divAddNewTableCsvInput.id = 'csv_' + tableCounter
    divAddNewTableCsvInput.placeholder = 'Paste XLSX content here'
    divAddNewTableCsvInput.rows = '8'
    divAddNewTableCsvInput.style = 'width: 100%;'
    divAddNewTableContainer.appendChild(divAddNewTableTitle)
    divAddNewTableContainer.appendChild(divAddNewTableTitleInput)
    divAddNewTableContainer.appendChild(br)
    divAddNewTableContainer.appendChild(divAddNewTableCsv)
    divAddNewTableContainer.appendChild(divAddNewTableCsvInput)
    divAddNewTableContainer.appendChild(br)
    tablesContainer.appendChild(divAddNewTableContainer)
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///COPY TABLE
function copyTable() {
    var copyText = document.getElementById('tableText')
    copyText.select()
    document.execCommand('Copy')
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///RESET TABLE
function resetTable() {
    var resetInput = document.getElementById('lrdg_tablesContainer')
    resetInput.innerHTML = ''
    addNewTable()

    document.getElementById('lrdg_cntnr').innerHTML = ''
    document.getElementById('lrdg_slct').value = 'default'

    var resetNotes = document.getElementById('notes')
    resetNotes.value = ''

    var resetTableText = document.getElementById('tableText')
    resetTableText.value = ''

    var lrdgCntnrReset = document.getElementById('lrdg_imgcnt')
    if (lrdgCntnrReset) {
        lrdgCntnrReset.outerHTML = ''
    }
}

export default { addNewTable, createShoesTable, createClothingTable, resetTable, copyTable }
