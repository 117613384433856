import React, { useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { concat, filter, includes, map } from 'lodash'
import couriers from '../../model/couriers'
import shippingServices from '../../model/shippingServices'
import countries from '../../model/countries'
import Loading from '../molecules/Loading/Loading.jsx'
import Header from '../organisms/Header/Header.jsx'
import Button from '../atoms/Button/Button.jsx'
import ShippingServicesList from '../organisms/Couriers/ShippingServicesList.jsx'
import ShippingServiceForm from '../molecules/Couriers/ShippingServiceForm.jsx'

import './errors.scss'

const ShippingServicesPage = props => {
    const defaultFilters = {
        courier: 0,
        country: '',
    }
    const [isLoading, setLoading] = useState(true)
    const [filters, setFilters] = useState(defaultFilters)
    const [shippingServicesList, setShippingServicesList] = useState([])
    const [shippingServicesFiltered, setShippingServicesFiltered] = useState([])
    const [countriesList, setCountriesList] = useState([])
    const [couriersList, setCouriersList] = useState([])
    const [errors, setErrors] = useState([])

    const handleDelete = async shippingServiceId => {
        try {
            setLoading(true)
            const isSuccess = await shippingServices.deleteShippingService(shippingServiceId)
            if (isSuccess) {
                const newList = filter(shippingServicesList, c => c.dtoGuid !== shippingServiceId)
                setShippingServicesList(newList)
                setErrors([])
            }
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
        setLoading(false)
    }

    const updateCountry = code => {
        setFilters(prevState => ({
            ...prevState,
            country: code,
        }))
    }

    const updateCourier = courierId => {
        setFilters(prevState => ({
            ...prevState,
            courier: courierId,
        }))
    }

    const fetchShippingServices = async () => {
        try {
            setLoading(true)
            const services = await shippingServices.getShippingServices()
            setShippingServicesList(services)
            setErrors([])
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
        setLoading(false)
    }

    const updateShippingServices = async (courierId, country) => {
        try {
            if (courierId !== 0 && country !== '') {
                const filtered = filter(
                    shippingServicesList,
                    s => s.courierId === courierId && s.destinationCountryCode === country
                )
                setShippingServicesFiltered(filtered)
            } else if (courierId !== 0) {
                const filtered = filter(shippingServicesList, s => s.courierId === courierId)
                setShippingServicesFiltered(filtered)
            } else if (country !== '') {
                const filtered = filter(shippingServicesList, s => s.destinationCountryCode === country)
                setShippingServicesFiltered(filtered)
            } else {
                setShippingServicesFiltered(shippingServicesList)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const fetchCountries = async () => {
        try {
            const c = await countries.getCountries('en-US')
            setCountriesList(c)
            setErrors([])
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
    }

    const fetchCouriers = async () => {
        try {
            const c = await couriers.getCouriers()
            setCouriersList(c)
            setErrors([])
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
    }

    const updateList = () => {
        fetchShippingServices()
        setFilters(defaultFilters)
    }

    useEffect(() => {
        fetchShippingServices()
        fetchCountries()
        fetchCouriers()
    }, [])

    useEffect(() => {
        updateShippingServices(filters.courier, filters.country)
    }, [filters.country, filters.courier, shippingServicesList.length])

    return isLoading ? (
        <Loading />
    ) : (
        <div>
            <Header>
                <div className="title">
                    <h2>Shipping services</h2>
                </div>
                <div className="cta">
                    <Button text="Refresh shipping services" onClick={updateList} />
                    <Link to={`${props.match.url}/create`}>
                        <Button text="Create new shipping service" />
                    </Link>
                </div>
            </Header>
            {errors.length > 0 && (
                <div className="errors-container">
                    {map(errors, (e, idx) => (
                        <p key={idx} className="error">
                            {e}
                        </p>
                    ))}
                </div>
            )}
            <Switch>
                <Route
                    exact
                    path={props.match.url}
                    render={() => (
                        <ShippingServicesList
                            shippingServices={shippingServicesFiltered}
                            url={props.match.url}
                            handleDelete={handleDelete}
                            filters={filters}
                            couriersList={couriersList}
                            countriesList={countriesList}
                            updateCountry={updateCountry}
                            updateCourier={updateCourier}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${props.match.url}/create`}
                    render={() => (
                        <ShippingServiceForm
                            mode="create"
                            url={props.match.url}
                            countriesList={countriesList}
                            updateList={updateList}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${props.match.url}/duplicate/:shippingServiceId`}
                    render={routeProps => (
                        <ShippingServiceForm
                            shippingServiceId={routeProps.match.params.shippingServiceId}
                            mode="create"
                            url={props.match.url}
                            countriesList={countriesList}
                            updateList={updateList}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${props.match.url}/edit/:shippingServiceId`}
                    render={routeProps => (
                        <ShippingServiceForm
                            shippingServiceId={routeProps.match.params.shippingServiceId}
                            mode="edit"
                            url={props.match.url}
                            countriesList={countriesList}
                            updateList={updateList}
                        />
                    )}
                />
            </Switch>
        </div>
    )
}

export default ShippingServicesPage
