import React from 'react'
import { connect } from 'react-redux'
import { isEmpty, isFinite, isNil } from 'lodash'
import * as actions from '../../redux/returns/returns.actions'
import * as currenciesActions from '../../redux/currencies/currencies.actions'
import * as commonActions from '../../redux/common/common.actions'
import ReturnFilters from '../organisms/ReturnFilters/ReturnFilters.jsx'
import ReturnsList from '../organisms/ReturnsList/ReturnsList.jsx'
import * as modalActions from '../../redux/modal/modal.actions'
import modal from '../../redux/modal/modal.types'
import messages from '../../helpers/messages'
import Header from '../organisms/Header/Header.jsx'
import { returnStatuses } from '../../model/returns'

class ReturnsPage extends React.Component {
    componentDidMount() {
        this.props.getReturns(this.props.culture, returnStatuses.toBeDealtWith)
        this.props.getCurrencies(this.props.culture)
    }

    componentDidUpdate(prevProps) {
        if (this.props.culture !== prevProps.culture) {
            this.props.getReturns(this.props.culture, returnStatuses.toBeDealtWith)
            this.props.getCurrencies(this.props.culture)
        }
    }

    render() {
        return (
            <div>
                <Header>
                    <div className="title">
                        <h2>Returns ({this.props.list.length})</h2>
                    </div>
                </Header>
                <ReturnFilters
                    filterByStatusHandler={status => this.props.getReturns(this.props.culture, status)}
                    search={searchTerm => this.props.searchReturns(searchTerm, this.props.culture)}
                    minLengthMessage="Min 4 chars. You can search by user email, order number or return number"
                    minLength={4}
                    onKeyUpHandler={status => this.props.getReturns(this.props.culture, status)}
                />
                <ReturnsList
                    list={this.props.list}
                    currencies={this.props.currencies}
                    culture={this.props.culture}
                    markAsApprovedAndWaitingToBeReceivedHandler={this.props.markAsApprovedAndWaitingToBeReceived}
                    markAsRejectedHandler={this.props.markAsRejected}
                    markAsReceivedAndInProcessHandler={this.props.markAsReceivedAndInProcess}
                    markAsRefundedHandler={this.props.markAsRefunded}
                    viewOrderDetails={this.props.viewOrderDetails}
                    hideOrderDetails={this.props.hideOrderDetails}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ...state.returns,
    ...state.currencies,
    culture: state.common.culture,
})

const mapDispatchToProps = dispatch => ({
    getReturns: (culture, status) => dispatch(actions.getReturns(culture, status)),
    getCurrencies: culture => dispatch(currenciesActions.getCurrencies(culture)),
    markAsApprovedAndWaitingToBeReceived: returnId =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.markAsApprovedAndWaitingToBeReceived(returnId)),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
    markAsRejected: (returnId, reasonForRejection, rejectWithAutomaticEmail) =>
        dispatch(
            !isNil(reasonForRejection) && !isEmpty(reasonForRejection)
                ? modalActions.showModal(modal.types.CONFIRM, {
                      confirmHandler: () =>
                          dispatch(actions.markAsRejected(returnId, reasonForRejection, rejectWithAutomaticEmail)),
                      cancelHandler: () => dispatch(modalActions.hideModal()),
                  })
                : commonActions.showMessage(messages.MESSAGE_INVALID_REASON_FOR_REJECTION)
        ),
    markAsReceivedAndInProcess: returnId =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.markAsReceivedAndInProcess(returnId)),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
    markAsRefunded: (returnId, amount, currency, items, culture) =>
        dispatch(
            !isNil(amount) && !isEmpty(amount) && isFinite(Number(amount)) && amount !== 0
                ? modalActions.showModal(modal.types.CONFIRM, {
                      confirmHandler: () =>
                          dispatch(actions.markAsRefunded(returnId, Number(amount), currency, items, culture)),
                      cancelHandler: () => dispatch(modalActions.hideModal()),
                  })
                : commonActions.showMessage(messages.MESSAGE_INVALID_AMOUNT)
        ),
    viewOrderDetails: (returnId, orderId) => dispatch(actions.getOrderDetails(returnId, orderId)),
    hideOrderDetails: returnId => dispatch(actions.hideOrderDetails(returnId)),
    searchReturns: (searchTerm, culture) => dispatch(actions.searchReturns(searchTerm, culture)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReturnsPage)
