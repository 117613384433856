const NAMESPACE = 'RETURNS'

export const ACTION_TYPES = {
    RETURNS_REQUEST: `${NAMESPACE}/RETURNS_REQUEST`,
    RETURNS_RECEIVED: `${NAMESPACE}/RETURNS_RECEIVED`,
    APPROVEDANDWAITINGTOBERECEIVED_REQUEST: `${NAMESPACE}/APPROVEDANDWAITINGTOBERECEIVED_REQUEST`,
    REJECTED_REQUEST: `${NAMESPACE}/REJECTED_REQUEST`,
    REJECTED: `${NAMESPACE}/REJECTED`,
    APPROVEDANDWAITINGTOBERECEIVED: `${NAMESPACE}/APPROVEDANDWAITINGTOBERECEIVED`,
    RECEIVEDANDINPROCESS_REQUEST: `${NAMESPACE}/RECEIVEDANDINPROCESS_REQUEST`,
    RECEIVEDANDINPROCESS: `${NAMESPACE}/RECEIVEDANDINPROCESS`,
    REFUNDED_REQUEST: `${NAMESPACE}/REFUNDED_REQUEST`,
    REFUNDED: `${NAMESPACE}/REFUNDED`,
    FILTERBYSTATUS_REQUEST: `${NAMESPACE}/FILTERBYSTATUS_REQUEST`,
    SEARCH_REQUEST: `${NAMESPACE}/SEARCH_REQUEST`,
    ONKEYUP: `${NAMESPACE}/ONKEYUP`,
    ORDERDETAILS_REQUEST: `${NAMESPACE}/ORDERDETAILS_REQUEST`,
    ORDERDETAILS_RECEIVED: `${NAMESPACE}/ORDERDETAILS_RECEIVED`,
    HIDEORDERDETAILS_REQUEST: `${NAMESPACE}/HIDEORDERDETAILS_REQUEST`,
}
export const getReturns = (culture, status) => ({
    type: ACTION_TYPES.RETURNS_REQUEST,
    payload: { culture, status },
})
export const returnsReceived = list => ({
    type: ACTION_TYPES.RETURNS_RECEIVED,
    payload: list,
})
export const markAsApprovedAndWaitingToBeReceived = returnId => ({
    type: ACTION_TYPES.APPROVEDANDWAITINGTOBERECEIVED_REQUEST,
    payload: returnId,
})
export const setApprovedAndWaitingToBeReceived = (returnId, result) => ({
    type: ACTION_TYPES.APPROVEDANDWAITINGTOBERECEIVED,
    payload: { returnId, result },
})
export const markAsRejected = (returnId, reasonForRejection, rejectWithAutomaticEmail) => ({
    type: ACTION_TYPES.REJECTED_REQUEST,
    payload: { returnId, reasonForRejection, rejectWithAutomaticEmail },
})
export const setRejected = (returnId, result) => ({
    type: ACTION_TYPES.REJECTED,
    payload: { returnId, result },
})
export const markAsReceivedAndInProcess = returnId => ({
    type: ACTION_TYPES.RECEIVEDANDINPROCESS_REQUEST,
    payload: returnId,
})
export const setReceivedAndInProcess = (returnId, result) => ({
    type: ACTION_TYPES.RECEIVEDANDINPROCESS,
    payload: { returnId, result },
})
export const markAsRefunded = (returnId, amount, currency, itemsReturned, culture) => ({
    type: ACTION_TYPES.REFUNDED_REQUEST,
    payload: { returnId, amount, currency, itemsReturned, culture },
})
export const setRefunded = (returnId, result) => ({
    type: ACTION_TYPES.REFUNDED,
    payload: { returnId, result },
})
export const searchReturns = (searchTerm, culture) => ({
    type: ACTION_TYPES.SEARCH_REQUEST,
    payload: { searchTerm, culture },
})
export const getOrderDetails = (returnId, orderId) => ({
    type: ACTION_TYPES.ORDERDETAILS_REQUEST,
    payload: { returnId, orderId },
})
export const orderDetailsReceived = (returnId, result) => ({
    type: ACTION_TYPES.ORDERDETAILS_RECEIVED,
    payload: { returnId, result },
})
export const hideOrderDetails = returnId => ({
    type: ACTION_TYPES.HIDEORDERDETAILS_REQUEST,
    payload: returnId,
})
