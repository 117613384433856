const NAMESPACE = 'CATEGORYBANNER'

export const ACTION_TYPES = {
    READ_CATEGORIES: `${NAMESPACE}/READ_CATEGORIES`,
    CATEGORIES_RECEIVED: `${NAMESPACE}/CATEGORIES_RECEIVED`,
    READ_BANNERS: `${NAMESPACE}/READ_BANNERS`,
    BANNERS_RECEIVED: `${NAMESPACE}/BANNERS_RECEIVED`,
    SAVE_EDIT_BANNER_REQUEST: `${NAMESPACE}/SAVE_EDIT_BANNER_REQUEST`,
    SAVE_NEW_BANNER: `${NAMESPACE}/SAVE_NEW_BANNER`,
    SAVE_NEW_BANNER_REQUEST: `${NAMESPACE}/SAVE_NEW_BANNER_REQUEST`,
    DELETE_BANNER_REQUEST: `${NAMESPACE}/DELETE_BANNER_REQUEST`,
}

export const readCategories = value => {
    return {
        type: ACTION_TYPES.READ_CATEGORIES,
        payload: value,
    }
}

export const categoriesReceived = list => {
    return {
        type: ACTION_TYPES.CATEGORIES_RECEIVED,
        payload: list,
    }
}
export const bannersReceived = list => ({
    type: ACTION_TYPES.BANNERS_RECEIVED,
    payload: list,
})

export const readBanners = value => ({
    type: ACTION_TYPES.READ_BANNERS,
    payload: value,
})
export const saveEditBannerRequest = (banner, culture) => ({
    type: ACTION_TYPES.SAVE_EDIT_BANNER_REQUEST,
    payload: { banner, culture },
})
export const saveNewBannerRequest = (banner, culture) => ({
    type: ACTION_TYPES.SAVE_NEW_BANNER_REQUEST,
    payload: { banner, culture },
})

export const deleteCategoryBanner = ({ categoryId, culture, id }) => ({
    type: ACTION_TYPES.DELETE_BANNER_REQUEST,
    payload: { categoryId, culture, id },
})
