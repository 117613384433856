import { ACTION_TYPES } from './questionAndAnswers.actions'

const INITIAL_STATE = {
    list: [],
    isSearch: false,
}

const reducers = {}

reducers[ACTION_TYPES.QUESTIONS_RECEIVED] = (state, action) => {
    const questions = action.payload === undefined ? [] : action.payload
    return {
        ...state,
        list: [...questions],
    }
}

reducers[ACTION_TYPES.QUESTION_DELETED] = (state, action) => ({
    ...state,
    list: state.list.filter(q => q.id !== action.payload),
})

reducers[ACTION_TYPES.ANSWER_DELETED] = (state, action) => ({
    ...state,
    list: state.list.map(q => {
        if (q.id === action.payload.questionId) {
            q.answers = q.answers.filter(a => a.id !== action.payload.answerId)
        }
        return q
    }),
})

reducers[ACTION_TYPES.QUESTION_ANSWERED] = (state, action) => ({
    ...state,
    list: state.list.filter(q => {
        if (q.id === action.payload.questionId) {
            q.answers = [...q.answers, action.payload.answer]
            if (!state.isSearch && q.answers.filter(a => !a.approved).length === 0) return false
        }
        return true
    }),
})

reducers[ACTION_TYPES.ANSWER_APPROVED] = (state, action) => ({
    ...state,
    list: state.list.filter(q => {
        if (q.id === action.payload.questionId) {
            q.answers = q.answers.map(a => {
                if (a.id === action.payload.answerId) a.approved = true
                return a
            })
            if (!state.isSearch && q.answers.filter(a => !a.approved).length === 0) return false
        }
        return true
    }),
})

reducers[ACTION_TYPES.SWITCH_TO_SEARCH] = state => ({
    ...state,
    isSearch: true,
    list: [],
})

reducers[ACTION_TYPES.EXIT_SEARCH] = state => ({
    ...state,
    isSearch: false,
})

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type] || (() => state)
    return reducer(state, action)
}
