import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import Button from '../../atoms/Button/Button.jsx'
import { join, map } from 'lodash'
import shippingServices from '../../../model/shippingServices.js'
import { getServiceName } from '../../../services/couriers/shippingServices.js'
import getCountriesNames from '../../../services/countries/countries.js'

const Catalog = ({ catalog, url, handleDelete, countries, updateList }) => {
    const [showItems, setShowItems] = useState(false)
    const [showServices, setShowServices] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [courierServices, setCourierServices] = useState([])

    const fetchCourierServices = async serviceIds => {
        try {
            const services = await shippingServices.getByServiceIds(serviceIds)
            setCourierServices(services)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div>{catalog.name}</div>
            <div>{catalog.zone.name}</div>
            <div>{catalog.zone.courier.name}</div>
            <div
                className="clickable"
                onClick={() => {
                    fetchCourierServices(
                        join(
                            map(catalog.courierServices, s => s.serviceId),
                            ','
                        )
                    )
                    setShowServices(true)
                }}
            >
                See services
            </div>
            <div
                className="clickable"
                onClick={() => {
                    setShowItems(true)
                }}
            >
                See items
            </div>
            <div className="catalogs-cta">
                <Link to={`${url}/duplicate/${catalog.dtoGuid}`}>
                    <Button text="Duplicate" />
                </Link>
                <Link to={`${url}/edit/${catalog.dtoGuid}`}>
                    <Button text="Edit" />
                </Link>
                <Button
                    onClick={() => {
                        setShowConfirmationModal(true)
                    }}
                    text="Delete"
                />
            </div>
            <Modal
                isOpen={showServices}
                onRequestClose={() => {
                    setShowServices(false)
                }}
                closeTimeoutMS={500}
                shouldFocusAfterRender={false}
                className={{
                    base: 'catalog-services',
                    afterOpen: 'catalog-services-afterOpen',
                    beforeClose: 'catalog-services-beforeClose',
                }}
                overlayClassName={{
                    base: 'catalog-services-overlay',
                    afterOpen: 'catalog-services-overlay-afterOpen',
                    beforeClose: 'catalog-services-overlay-beforeClose',
                }}
            >
                <h3>Services in {catalog.name}:</h3>
                {map(courierServices, (s, idx) => (
                    <div key={idx}>
                        <p>{`${getServiceName(s.courierServiceNames)} (${getCountriesNames(
                            [s.destinationCountryCode],
                            countries
                        )})`}</p>
                    </div>
                ))}
                <Button
                    onClick={() => {
                        setShowServices(false)
                    }}
                    text="Close"
                />
            </Modal>
            <Modal
                isOpen={showItems}
                onRequestClose={() => {
                    setShowItems(false)
                }}
                closeTimeoutMS={500}
                shouldFocusAfterRender={false}
                className={{
                    base: 'catalog-items',
                    afterOpen: 'catalog-items-afterOpen',
                    beforeClose: 'catalog-items-beforeClose',
                }}
                overlayClassName={{
                    base: 'catalog-items-overlay',
                    afterOpen: 'catalog-items-overlay-afterOpen',
                    beforeClose: 'catalog-items-overlay-beforeClose',
                }}
            >
                <h3>Items in catalog {catalog.name}:</h3>
                <div className="catalog-items-table">
                    <div className="catalog-items-table-row header">
                        <div>From [kg] (included)</div>
                        <div>To [kg] (excluded)</div>
                        <div>Price [EUR without VAT]</div>
                    </div>
                    {map(catalog.catalogItems, (i, idx) => (
                        <div className="catalog-items-table-row" key={idx}>
                            <div>{i.fromWeight}</div>
                            <div>{i.toWeight}</div>
                            <div>{i.price}</div>
                        </div>
                    ))}
                </div>
                <br />
                <div>Volumetric weight: {catalog.volumetricWeight ? 'Yes' : 'No'}</div>
                <Button
                    onClick={() => {
                        setShowItems(false)
                    }}
                    text="Close"
                />
            </Modal>
            <Modal
                isOpen={showConfirmationModal}
                onRequestClose={() => {
                    setShowConfirmationModal(false)
                }}
                closeTimeoutMS={500}
                shouldFocusAfterRender={false}
                className={{
                    base: 'catalog-modal',
                    afterOpen: 'catalog-modal-afterOpen',
                    beforeClose: 'catalog-modal-beforeClose',
                }}
                overlayClassName={{
                    base: 'catalog-modal-overlay',
                    afterOpen: 'catalog-modal-overlay-afterOpen',
                    beforeClose: 'catalog-modal-modal-overlay-beforeClose',
                }}
            >
                <h3>Are you sure?</h3>
                <p>You are deleting the catalog {catalog.name}.</p>
                <p>This operation cannot be undone</p>
                <Button
                    onClick={() => {
                        setShowConfirmationModal(false)
                    }}
                    text="Cancel"
                />
                <Button
                    onClick={() => {
                        handleDelete(catalog.dtoGuid)
                        updateList()
                        setShowConfirmationModal(false)
                    }}
                    text="Confirm"
                />
            </Modal>
        </>
    )
}

export default Catalog
