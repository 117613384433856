import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

const getCatalogs = () => {
    return fetch(`${config.apiRoot}/v1/couriersPriceCatalogs/`, {
        method: 'GET',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return r.json()
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

const getCatalog = catalogId => {
    return fetch(`${config.apiRoot}/v1/couriersPriceCatalogs/${catalogId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return r.json()
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

const deleteCatalog = catalogId => {
    return fetch(`${config.apiRoot}/v1/couriersPriceCatalogs/${catalogId}`, {
        method: 'DELETE',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return true
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

const submit = (catalog, endpoint, method) => {
    return fetch(`${config.apiRoot}/v1/couriersPriceCatalogs/${endpoint}`, {
        method: method,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(catalog),
    }).then(async r => {
        if (r.ok) {
            return {}
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            return resp
        }
        throw new ServerError(r)
    })
}

export default { getCatalogs, getCatalog, deleteCatalog, submit }
