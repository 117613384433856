const availableTemplates = {
    none: {
        id: 0,
        description: 'No option selected',
    },
    freestyle: {
        id: 1,
        description: 'Free HTML',
    },
    base: {
        id: 2,
        description: 'Base template',
    },
    single: {
        id: 3,
        description: 'Single banner template',
    },
}
export { availableTemplates }
