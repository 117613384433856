import React from 'react'

import Question from '../Question/Question.jsx'
import '../QuestionList/QuestionList.scss'

const QuestionList = ({
    list,
    culture,
    deleteQuestionHandler,
    deleteAnswerHandler,
    answerQuestionHandler,
    approveAnswerHandler,
    getUserInfoHandler,
    openImageHandler,
}) => {
    const questions = list.map(question => {
        return (
            <li className="questionList" key={question.id}>
                <Question
                    culture={culture}
                    question={question}
                    deleteQuestionHandler={deleteQuestionHandler}
                    deleteAnswerHandler={deleteAnswerHandler}
                    answerQuestionHandler={answerQuestionHandler}
                    approveAnswerHandler={approveAnswerHandler}
                    getUserInfoHandler={getUserInfoHandler}
                    openImageHandler={openImageHandler}
                />
            </li>
        )
    })

    return (
        <div>
            <ul>{questions}</ul>
        </div>
    )
}

export default QuestionList
