import React from 'react'
import { connect } from 'react-redux'
import { toUpper } from 'lodash'

import Button from '../../atoms/Button/Button.jsx'
import Message from '../../molecules/Message/Message.jsx'

import config from '../../../config'

require('../Login/login.scss')

class Login extends React.Component {
    state = {
        username: '',
        password: '',
    }

    handleUsernameChange = e => {
        const value = e.target.value
        this.setState(prevState => ({
            ...prevState,
            username: value,
        }))
    }

    handlePasswordChange = e => {
        const value = e.target.value
        this.setState(prevState => ({
            ...prevState,
            password: value,
        }))
    }

    handleSubmit = e => {
        e.preventDefault()
        this.props.login(this.state.username, this.state.password)
    }

    render() {
        let currentSite = toUpper(config.currentSite)
        const showMessage = () => (this.props.messageVisible ? <Message /> : undefined)
        return (
            <div>
                <div className="">
                    <div className="app-content">{showMessage()}</div>
                </div>
                <div className="loginContainer">
                    <h2>Welcome to {currentSite}</h2>
                    <form onSubmit={this.handleSubmit}>
                        <label htmlFor="username"> Username: </label>
                        <input
                            id="username"
                            name="username"
                            type="email"
                            placeholder="guybrush@bigwhoop.com"
                            value={this.state.username}
                            onChange={this.handleUsernameChange}
                        />
                        <label htmlFor="password"> Password: </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            placeholder="LeChuckIsMyBrother"
                            value={this.state.password}
                            onChange={this.handlePasswordChange}
                        />
                        <Button customClassName="loginButton" text="Login" onClick={this.handleSubmit} type="submit" />
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    messageVisible: state.common.messageVisible,
})

export default connect(mapStateToProps)(Login)
