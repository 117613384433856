import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Link } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { hasIn, filter, head } from 'lodash'
import moment from 'moment'

import * as modalActions from '../../redux/modal/modal.actions'
import modal from '../../redux/modal/modal.types'

import * as actions from '../../redux/categoryBanner/categoryBanner.actions'
import Button from '../atoms/Button/Button.jsx'
import Header from '../organisms/Header/Header.jsx'
import CategoryBannerForm from '../molecules/CategoryBanners/CategoryBannerForm.jsx'
import Autocomplete from '../molecules/CategoryBanners/Autocomplete.jsx'
import { availableTemplates } from '../../model/availableTemplates'
import './categoryBannerPage.scss'

class CategoryBannerPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { selectedCategory: { value: undefined }, showToolbar: false }
    }
    componentDidMount() {
        this.props.getCategories(this.props.culture)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.culture !== prevProps.culture) {
            this.props.getCategories(this.props.culture)
            this.setState({ selectedCategory: { value: undefined, title: '' } })
            this.props.clearBanners()
        }
        if (
            hasIn(this.state, 'selectedCategory.value') &&
            hasIn(prevState, 'selectedCategory.value') &&
            prevState.selectedCategory.value !== this.state.selectedCategory.value &&
            this.state.selectedCategory.value !== undefined
        ) {
            this.props.getBanners(this.state.selectedCategory.value, this.props.culture)
        }
    }
    getTemplateById(templateId) {
        return head(filter(availableTemplates, template => template.id === templateId)).description
    }
    renderToolbar(currentLocation) {
        return this.isCategoryBannerList(currentLocation) ? (
            <div className="toolbar">
                <Autocomplete
                    value={this.state.selectedCategory.title}
                    placeholder="Search categories"
                    items={this.props.categories}
                    onSelect={selectedItem => {
                        if (selectedItem !== undefined) {
                            this.setState({ selectedCategory: selectedItem })
                            this.setState({ showToolbar: true })
                            this.props.getBanners(selectedItem.value, this.props.culture)
                        }
                    }}
                />
                {this.isCategorySelected() ? (
                    <div>
                        <Link
                            to={`${this.props.match.url}/${this.state.selectedCategory.value}/create`}
                            selectedcategory={this.state.selectedCategory}
                        >
                            <Button text="Create new banner" />
                        </Link>
                    </div>
                ) : (
                    <div />
                )}
            </div>
        ) : (
            <div />
        )
    }
    renderBanners(items) {
        return items.map(item => {
            const status = () => {
                let activeStatus
                const now = new Date()

                const start = new Date(item.startDate)
                const end = new Date(item.endDate)

                if (end >= now && start > now) {
                    activeStatus = 'not-yet-active'
                } else if (end >= now && start <= now) {
                    activeStatus = 'active'
                } else {
                    activeStatus = 'inactive'
                }

                return <div className={`status ${activeStatus}`} />
            }
            return (
                <div className="cardContainer" key={item.id}>
                    <div className="card">
                        <div className="info">
                            <div>
                                {status()}
                                <div>
                                    <label>Start date: </label>
                                    <p>{moment(item.startDate).format('LLLL')}</p>
                                </div>
                                <div>
                                    <label>End date: </label>
                                    <span>
                                        <p>{moment(item.endDate).format('LLLL')}</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="description">
                            <div>
                                <h3>{item.title}</h3>
                            </div>
                            <p>
                                <span>
                                    <em>Template type:</em> {this.getTemplateById(item.templateId)}
                                </span>
                            </p>
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: item.html }} />
                            </div>
                        </div>

                        <div className="actions">
                            <Link to={`${this.props.match.url}/edit/${item.id}`}>
                                <Button text="Edit" />
                            </Link>
                            <Button
                                text="Delete"
                                onClick={() =>
                                    this.props.deleteCategoryBanner({
                                        categoryId: this.state.selectedCategory.value,
                                        culture: this.props.culture,
                                        id: item.id,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            )
        })
    }
    isCategoryBannerList(currentLocation) {
        return currentLocation === `/categorybanners`
    }
    isCategorySelected() {
        return this.state.selectedCategory.value !== undefined
    }

    render() {
        const items = this.props.list || []
        const currentLocation = this.props.location.pathname
        return (
            <div className="categoryBannerPage">
                <Header>
                    <div className="title">
                        <h2>Category banners</h2>
                    </div>
                </Header>

                {this.renderToolbar(currentLocation)}
                {this.isCategorySelected() ? (
                    <div>
                        {this.isCategoryBannerList(currentLocation) ? this.renderBanners(items) : <div />}
                        <Switch>
                            {items.map(item => (
                                <Route
                                    key={item.id}
                                    path={`${this.props.match.url}/edit/${item.id}`}
                                    render={() => (
                                        <CategoryBannerForm
                                            selectedcategory={this.state.selectedCategory}
                                            culture={this.props.culture}
                                            selectedbannercategory={item}
                                        />
                                    )}
                                />
                            ))}
                            <Route
                                path={`${this.props.match.url}/${this.state.selectedCategory.value}/create`}
                                render={() => (
                                    <CategoryBannerForm
                                        selectedcategory={this.state.selectedCategory}
                                        culture={this.props.culture}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                ) : (
                    <div />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const obj = {
        categories: state.categoryBanners.categories,
        ...state.categoryBanners,
        culture: state.common.culture,
    }
    return obj
}

const mapDispatchToProps = dispatch => ({
    getCategories: culture => dispatch(actions.readCategories({ culture })),
    getBanners: (categoryId, culture) => dispatch(actions.readBanners({ categoryId, culture })),
    setBanners: (banners = []) => dispatch(actions.bannersReceived(banners)),
    deleteCategoryBanner: ({ categoryId, culture, id }) =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.deleteCategoryBanner({ categoryId, culture, id })),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
    clearBanners: () => dispatch(actions.bannersReceived([])),
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryBannerPage)
