import React, { useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { concat, filter, includes, map } from 'lodash'
import zones from '../../model/zones.js'
import countries from '../../model/countries.js'
import couriers from '../../model/couriers.js'
import Loading from '../molecules/Loading/Loading.jsx'
import Header from '../organisms/Header/Header.jsx'
import Button from '../atoms/Button/Button.jsx'
import ZoneList from '../organisms/Couriers/ZoneList.jsx'
import ZoneForm from '../molecules/Couriers/ZoneForm.jsx'

import './errors.scss'

const ZonesPage = props => {
    const [isLoading, setLoading] = useState(true)
    const [zonesList, setZones] = useState([])
    const [countriesList, setCountriesList] = useState([])
    const [couriersList, setCouriersList] = useState([])
    const [errors, setErrors] = useState([])

    const handleDelete = async zoneId => {
        try {
            const isSuccess = await zones.deleteZone(zoneId)
            if (isSuccess) {
                const newList = filter(zonesList, z => z.dtoGuid !== zoneId)
                setZones(newList)
                setErrors([])
            }
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
    }

    const fetchZones = async () => {
        try {
            setLoading(true)
            const fetchedZones = await zones.getZones()
            setZones(fetchedZones)
            setErrors([])
            setLoading(false)
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
            setLoading(false)
        }
    }

    const fetchCountries = async () => {
        try {
            const fetchedCountries = await countries.getCountries('en-US')
            setCountriesList(fetchedCountries)
            setErrors([])
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
    }

    const fetchCouriers = async () => {
        try {
            const fetchedCouriers = await couriers.getCouriers()
            setCouriersList(fetchedCouriers)
            setErrors([])
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
    }

    useEffect(() => {
        fetchZones()
        fetchCountries()
        fetchCouriers()
    }, [])

    return isLoading ? (
        <Loading />
    ) : (
        <div>
            <Header>
                <div className="title">
                    <h2>Zones</h2>
                </div>
                <div className="cta">
                    <Button text="Refresh zones" onClick={fetchZones} />
                    <Link to={`${props.match.url}/create`}>
                        <Button text="Create new zone" />
                    </Link>
                </div>
            </Header>
            {errors.length > 0 && (
                <div className="errors-container">
                    {map(errors, (e, idx) => (
                        <p key={idx} className="error">
                            {e}
                        </p>
                    ))}
                </div>
            )}
            <Switch>
                <Route
                    exact
                    path={props.match.url}
                    render={() => (
                        <ZoneList
                            zones={zonesList}
                            url={props.match.url}
                            handleDelete={handleDelete}
                            countries={countriesList}
                            updateList={fetchZones}
                            couriers={couriersList}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${props.match.url}/create`}
                    render={() => (
                        <ZoneForm
                            mode="create"
                            url={props.match.url}
                            updateList={fetchZones}
                            countries={countriesList}
                            couriers={couriersList}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${props.match.url}/duplicate/:zoneId`}
                    render={routeProps => (
                        <ZoneForm
                            zoneId={routeProps.match.params.zoneId}
                            mode="create"
                            url={props.match.url}
                            updateList={fetchZones}
                            countries={countriesList}
                            couriers={couriersList}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${props.match.url}/edit/:zoneId`}
                    render={routeProps => (
                        <ZoneForm
                            zoneId={routeProps.match.params.zoneId}
                            mode="edit"
                            url={props.match.url}
                            updateList={fetchZones}
                            countries={countriesList}
                            couriers={couriersList}
                        />
                    )}
                />
            </Switch>
        </div>
    )
}

export default ZonesPage
