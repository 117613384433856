import React from 'react'

import Button from '../../atoms/Button/Button.jsx'
import '../BulkUpdate/bulkUpdate.scss'

export default class BulkUpdate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            bulkUpdateChecked: false,
        }
    }
    onBulkUpdateChanged({ currentState }) {
        this.setState({ bulkUpdateChecked: !currentState })
        this.props.selectItemsBulk(this.props.culture, !currentState)
    }

    componentDidUpdate(prevProps) {
        if (this.props.culture !== prevProps.culture) {
            this.setState({ bulkUpdateChecked: false })
        }
    }

    render() {
        let culture = this.props.culture
        return (
            <div>
                <div className="bulkUpdate">
                    <div className="checkboxContainer">
                        <input
                            id="bulkUpdate"
                            type="checkbox"
                            checked={this.state.bulkUpdateChecked}
                            onChange={() => this.onBulkUpdateChanged({ currentState: this.state.bulkUpdateChecked })}
                        />
                        <label htmlFor="bulkUpdate" />
                    </div>
                    <input
                        className="bulkUpdate__note"
                        type="text"
                        ref={node => {
                            this.inputElement = node
                        }}
                    />
                    <Button
                        text="Set for selected"
                        onClick={() => this.props.setDestinationNoteBulk(culture, this.inputElement.value)}
                    />
                </div>
                <hr />
            </div>
        )
    }
}
