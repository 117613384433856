import React from 'react'
import moment from 'moment'
import ReactStars from 'react-stars'
import Button from '../../atoms/Button/Button.jsx'
import AnswerForm from '../../molecules/Answers/AnswerForm.jsx'
import ImageClickable from '../../molecules/Images/ImageClickable.jsx'

import '../Review/Review.scss'
import config from '../../../config'

const Review = ({
    review,
    culture,
    deleteReviewHandler,
    answerReviewHandler,
    getUserInfoHandler,
    approveReviewHandler,
    openImageHandler,
}) => {
    const momentDate = moment(review.creationDate).format('lll')
    const images = review.images.map(image => <ImageClickable key={image} filename={image} onOpen={openImageHandler} />)
    return (
        <div className="reviewContainer" key={review.id}>
            <div className="review">
                <div className="info">
                    <Button text={review.userNickName} onClick={() => getUserInfoHandler(review.userId)} />
                    <h4>{momentDate}</h4>
                </div>
                <div className="description">
                    <div>
                        <h3>{review.title}</h3>
                        <h4>
                            <a target="blank" href={config.getSiteByCulture(culture) + review.productSlug}>
                                {review.productSlug}
                            </a>
                        </h4>
                    </div>
                    <div className="ratingContainer">
                        <em className="ratingDescription">Overall ranking</em>
                        <ReactStars count={5} size={24} edit={false} color2={'#ffd700'} value={review.rating} />
                        <em className="ratingDescription">Quality/price ratio</em>
                        <ReactStars
                            count={5}
                            size={24}
                            edit={false}
                            color2={'#ffd700'}
                            value={review.qualityPriceRatio}
                        />
                    </div>
                    <p>
                        <strong>{review.description}</strong>
                    </p>
                    <div className="images">{images}</div>
                </div>

                <div className="actions">
                    <Button text="Read" onClick={() => approveReviewHandler(review.id)} />
                    <Button text="Delete" onClick={() => deleteReviewHandler(review.id)} />
                </div>
            </div>
            <AnswerForm culture={culture} answerQuestionHandler={answerReviewHandler} questionId={review.id} />
        </div>
    )
}

export default Review
