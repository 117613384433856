import React from 'react'
import { connect } from 'react-redux'

import * as actions from '../../redux/questionAndAnswers/questionAndAnswers.actions'
import QuestionList from '../organisms/QuestionList/QuestionList.jsx'
import * as modalActions from '../../redux/modal/modal.actions'
import modal from '../../redux/modal/modal.types'
import * as userActions from '../../redux/user/user.actions'
import Button from '../atoms/Button/Button.jsx'
import Header from '../organisms/Header/Header.jsx'
import SearchField from '../organisms/SearchField/SearchField.jsx'

class QuestionAndAnswersPage extends React.Component {
    componentDidMount() {
        this.props.getQuestionsAndAnswers(this.props.culture)
    }

    componentDidUpdate(prevProps) {
        if (this.props.culture !== prevProps.culture) {
            this.props.getQuestionsAndAnswers(this.props.culture)
        }
    }

    render() {
        return (
            <div>
                <Header>
                    <div className="title">
                        <h2>Questions and Answers ({this.props.list.length})</h2>
                    </div>
                    <div className="cta">
                        <Button
                            text="Search by product"
                            onClick={() => this.props.switchToSearch(this.props.culture)}
                        />
                        <Button
                            text="Get questions with no answer"
                            onClick={() => this.props.getQuestionsAndAnswers(this.props.culture)}
                        />
                    </div>
                </Header>
                {this.props.isSearch && (
                    <SearchField
                        search={slug => this.props.searchQuestions(slug, this.props.culture)}
                        minLengthMessage="Paste the product slug to search all related questions"
                        minLength={4}
                    />
                )}
                <QuestionList
                    list={this.props.list}
                    culture={this.props.culture}
                    deleteQuestionHandler={this.props.deleteQuestion}
                    deleteAnswerHandler={this.props.deleteAnswer}
                    answerQuestionHandler={this.props.answerQuestion}
                    approveAnswerHandler={this.props.approveAnswer}
                    getUserInfoHandler={this.props.getUserInfo}
                    openImageHandler={this.props.openImage}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ...state.questionsAndAnswers,
    culture: state.common.culture,
})

const mapDispatchToProps = dispatch => ({
    getQuestionsAndAnswers: culture => dispatch(actions.searchQuestionsAndAnswers(culture)),
    deleteQuestion: questionId =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.deleteQuestionRequest(questionId)),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
    deleteAnswer: (questionId, answerId) =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.deleteAnswerRequest(questionId, answerId)),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
    answerQuestion: (questionId, answer, culture) =>
        dispatch(actions.answerQuestionRequest(questionId, answer, culture)),
    approveAnswer: (questionId, answerId) => dispatch(actions.approveAnswerRequest(questionId, answerId)),
    getUserInfo: nickname => dispatch(userActions.getUserInfo(nickname)),
    openImage: filename =>
        dispatch(
            modalActions.showModal(modal.types.OPEN_IMAGE, {
                filename,
                isOpen: true,
                closeHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
    switchToSearch: culture => dispatch(actions.switchToSearch(culture)),
    searchQuestions: (slug, culture) => dispatch(actions.searchQuestions(slug, culture)),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuestionAndAnswersPage)
