import React from 'react'
import Menu from '../molecules/Menu/Menu.jsx'
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'
import Message from '../molecules/Message/Message.jsx'

import CategoryBannerPage from './CategoryBannerPage.jsx'
import QuestionAndAnswersPage from './QuestionAndAnswersPage.jsx'
import FeedbacksPage from './FeedbacksPage.jsx'
import DestinationsPage from './DestinationsPage.jsx'
import LandingEmptyPage from './LandingEmptyPage.jsx'
import PrivateRoute from '../PrivateRoute.jsx'
import ReviewsPage from './ReviewsPage.jsx'
import UsersPage from './UsersPage.jsx'
import InfoboxPage from './InfoboxPage.jsx'
import ReassortmentNotificationsPage from './ReassortmentNotificationsPage.jsx'
import ReturnsPage from './ReturnsPage.jsx'
import SizeGuidesPage from './SizeGuidesPage.jsx'
import ZonesPage from './ZonesPage.jsx'
import CouriersCatalogPage from './CouriersCatalogPage.jsx'
import CouriersPriceFinderPage from './CouriersPriceFinderPage.jsx'
import CouriersPage from './CouriersPage.jsx'
import ShippingServicesPage from './ShippingServicesPage.jsx'
import WarehousePickupPage from './WarehousePickupPage.jsx'
import userRoles from '../../model/userRoles.js'

// =============== ADD ROUTE!!! ===============
function MainPage({ messageVisible, role }) {
    const showMessage = () => (messageVisible ? <Message /> : undefined)
    const showMenu = () => (role ? <Menu roleSet={role} /> : undefined)
    return (
        <div className="app-container">
            {showMenu()}
            <div className="app-content">
                {showMessage()}
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/categorybanners"
                    component={CategoryBannerPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/questions"
                    component={QuestionAndAnswersPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/feedbacks"
                    component={FeedbacksPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/destinations"
                    component={DestinationsPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/reviews"
                    component={ReviewsPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/users"
                    component={UsersPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/infobox"
                    component={InfoboxPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/reassortmentNotifications"
                    component={ReassortmentNotificationsPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/returns"
                    component={ReturnsPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreLocale}
                    path="/sizeguides"
                    component={SizeGuidesPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/zones"
                    component={ZonesPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/couriers"
                    component={CouriersPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/shippingServices"
                    component={ShippingServicesPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/couriersCatalog"
                    component={CouriersCatalogPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/priceFinder"
                    component={CouriersPriceFinderPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.gestoreGlobale}
                    path="/warehousePickup"
                    component={WarehousePickupPage}
                    role={role}
                />
                <PrivateRoute
                    minimumValidRole={userRoles.userRoles.utente}
                    path="/landingEmptyPage"
                    component={LandingEmptyPage}
                    role={role}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        ...state,
        messageVisible: state.common.messageVisible,
        role: state.users.user.role,
    }
}

export default withRouter(connect(mapStateToProps)(MainPage))
