import React from 'react'
import { connect } from 'react-redux'

import * as actions from '../../redux/feedbacks/feedbacks.actions'
import FeedbackList from '../organisms/FeedbackList/FeedbackList.jsx'
import * as modalActions from '../../redux/modal/modal.actions'
import modal from '../../redux/modal/modal.types'
import * as userActions from '../../redux/user/user.actions'
import Header from '../organisms/Header/Header.jsx'

class FeedbacksPage extends React.Component {
    componentDidMount() {
        this.props.getFeedbacks(this.props.culture)
    }

    componentDidUpdate(prevProps) {
        if (this.props.culture !== prevProps.culture) {
            this.props.getFeedbacks(this.props.culture)
        }
    }

    render() {
        return (
            <div>
                <Header>
                    <div className="title">
                        <h2>Feedbacks ({this.props.list.length})</h2>
                    </div>
                </Header>
                <FeedbackList
                    list={this.props.list}
                    culture={this.props.culture}
                    deleteFeedbackHandler={this.props.deleteFeedback}
                    answerFeedbackHandler={this.props.answerFeedback}
                    getUserInfoHandler={this.props.getUserInfo}
                    approveFeedbackHandler={this.props.approveFeedback}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ...state.feedbacks,
    culture: state.common.culture,
})

const mapDispatchToProps = dispatch => ({
    deleteFeedback: feedbackId =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.deleteFeedback(feedbackId)),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
    answerFeedback: (feedbackId, answer, culture) =>
        dispatch(actions.answerFeedbackRequest(feedbackId, answer, culture)),
    getFeedbacks: culture => dispatch(actions.searchFeedbacks(culture)),
    getUserInfo: nickname => dispatch(userActions.getUserInfo(nickname)),
    approveFeedback: feedbackId =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.approveFeedbackRequest(feedbackId)),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
})

export default connect(mapStateToProps, mapDispatchToProps)(FeedbacksPage)
