import { call, takeLatest, takeEvery, put } from 'redux-saga/effects'

import * as actions from './feedbacks.actions'
import * as commonActions from '../common/common.actions'
import * as userActions from '../user/user.actions'
import * as modalActions from '../modal/modal.actions'
import { ACTION_TYPES } from './feedbacks.actions'
import feedbacks from '../../model/feedbacks'
import ServerError from '../../errors/ServerError'
import modal from '../modal/modal.types'
import messages from '../../helpers/messages'

function* fetchfeedbacks(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const result = yield call(feedbacks.get, action.payload)
        yield put(actions.feedbacksReceived(result))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* deleteFeedback(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        yield call(feedbacks.deleteFeedback, action.payload)
        yield put(actions.feedbackDeleted(action.payload))
        yield put(modalActions.hideModal())
        yield put(commonActions.showMessage(messages.getDeletedSuccessMessage('Feedback')))
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* answerFeedback(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const result = yield call(
            feedbacks.answerFeedback,
            action.payload.feedbackId,
            action.payload.answer,
            action.payload.culture
        )
        yield put(actions.feedbackAnswered(action.payload.feedbackId, result))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* approveFeedback(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        yield call(feedbacks.approveFeedback, action.payload)
        yield put(actions.feedbackApproved(action.payload))
        yield put(commonActions.showMessage(messages.getApprovedSuccessMessage('Feedback')))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export default function*() {
    yield takeLatest(ACTION_TYPES.SEARCH_FEEDBACKS, fetchfeedbacks)
    yield takeEvery(ACTION_TYPES.DELETE_FEEDBACK_REQUEST, deleteFeedback)
    yield takeEvery(ACTION_TYPES.ANSWER_FEEDBACK_REQUEST, answerFeedback)
    yield takeEvery(ACTION_TYPES.APPROVE_FEEDBACK_REQUEST, approveFeedback)
}
