const NAMESPACE = 'INFOBOX'

export const ACTION_TYPES = {
    READ_INFOBOXES: `${NAMESPACE}/READ_INFOBOXES`,
    INFOBOXES_RECEIVED: `${NAMESPACE}/INFOBOXES_RECEIVED`,
    EDIT_INFOBOX: `${NAMESPACE}/EDIT_INFOBOX`,
    INCORRECT_DATE: `${NAMESPACE}/INCORRECT_DATE`,
    CANCEL_EDIT_INFOBOX: `${NAMESPACE}/CANCEL_EDIT_INFOBOX`,
    SAVE_EDIT_INFOBOX_REQUEST: `${NAMESPACE}/SAVE_EDIT_INFOBOX_REQUEST`,
    SAVE_EDIT_INFOBOX: `${NAMESPACE}/SAVE_EDIT_INFOBOX`,
    SAVE_NEW_INFOBOX_REQUEST: `${NAMESPACE}/SAVE_NEW_INFOBOX_REQUEST`,
    SAVE_NEW_INFOBOX: `${NAMESPACE}/SAVE_NEW_INFOBOX`,
    DELETE_INFOBOX_REQUEST: `${NAMESPACE}/DELETE_INFOBOX_REQUEST`,
}

export const readInfoboxes = culture => ({
    type: ACTION_TYPES.READ_INFOBOXES,
    payload: culture,
})

export const infoboxesReceived = list => ({
    type: ACTION_TYPES.INFOBOXES_RECEIVED,
    payload: list,
})

export const editInfobox = infoboxId => ({
    type: ACTION_TYPES.EDIT_INFOBOX,
    payload: infoboxId,
})

export const incorrectDate = () => ({
    type: ACTION_TYPES.INCORRECT_DATE,
    payload: null,
})

export const cancelEditInfobox = infoboxId => ({
    type: ACTION_TYPES.CANCEL_EDIT_INFOBOX,
    payload: infoboxId,
})

export const saveEditInfoboxRequest = (infoboxId, infobox, culture) => ({
    type: ACTION_TYPES.SAVE_EDIT_INFOBOX_REQUEST,
    payload: { infoboxId, infobox, culture },
})

export const saveEditInfobox = infobox => ({
    type: ACTION_TYPES.SAVE_EDIT_INFOBOX,
    payload: { infobox },
})

export const saveNewInfoboxRequest = (infobox, culture) => ({
    type: ACTION_TYPES.SAVE_NEW_INFOBOX_REQUEST,
    payload: { infobox, culture },
})

export const deleteInfoboxRequest = (infoboxId, culture) => ({
    type: ACTION_TYPES.DELETE_INFOBOX_REQUEST,
    payload: { infoboxId, culture },
})
