import config from '../config'
import { isNil } from 'lodash'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'
import { refundGA4 } from '../helpers/ga4ServiceHelper'

export const getReturns = (culture, status) =>
    fetch(`${config.apiRoot}/v1/returns/${culture}/status/${status}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError('Server error :(')
    })

export const searchReturns = (culture, searchTerm) =>
    fetch(`${config.apiRoot}/v1/returns/${culture}?search=${searchTerm}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError('Server error :(')
    })

const _put = (url, body) => {
    return fetch(url, {
        method: 'PUT',
        headers: new Headers({
            ...authHeader(),
            'Content-Type': 'application/json',
        }),
        body: isNil(body) ? null : JSON.stringify(body),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })
}

export const getOrderDetails = (returnId, orderId) =>
    fetch(`${config.apiRoot}/v1/returns/${returnId}/getOrderDetails?orderId=${orderId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError('Server error :(')
    })

export const markAsApprovedAndWaitingToBeReceived = returnId =>
    _put(`${config.apiRoot}/v1/returns/${returnId}/markAsApprovedAndWaitingToBeReceived`)
export const markAsRejected = (returnId, reasonForRejection, rejectWithAutomaticEmail) => {
    const body = { reasonForRejection, rejectWithAutomaticEmail }
    return _put(`${config.apiRoot}/v1/returns/${returnId}/markAsRejected`, body)
}
export const markAsReceivedAndInProcess = returnId =>
    _put(`${config.apiRoot}/v1/returns/${returnId}/markAsReceivedAndInProcess`)
export const markAsRefunded = payload => {
    return fetch(`${config.apiRoot}/v1/returns/${payload.returnId}/markAsRefunded`, {
        method: 'PUT',
        headers: new Headers({
            ...authHeader(),
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            amount: payload.amount,
            currency: payload.currency,
        }),
    }).then(r => {
        if (r.ok) {
            const googleData = config.getGoogleTagManagerData(payload.culture)
            refundGA4({
                transaction_id: payload.returnId,
                currency: payload.currency,
                value: payload.amount,
                items: payload.itemsReturned,
                analytics4MeasurementId: googleData.analytics4MeasurementId,
                analytics4MeasurementGlobalId: googleData.analytics4MeasurementGlobalId,
            })
            return r.json()
        }
        throw new ServerError('Server error :(')
    })
}
export const returnStatuses = {
    toBeDealtWith: 'ToBeDealtWith',
    approvedAndWaitingToBeReceived: 'ApprovedAndWaitingToBeReceived',
    rejected: 'Rejected',
    receivedAndInProcess: 'ReceivedAndInProcess',
    refunded: 'Refunded',
}
export const returnStatusesTranslations = {
    toBeDealtWith: 'To be dealt with',
    approvedAndWaitingToBeReceived: 'Approved and waiting to be received',
    rejected: 'Rejected',
    receivedAndInProcess: 'Received and in process',
    refunded: 'Refunded',
}
// export { returnStatuses, getReturns, markAsApprovedAndWaitingToBeReceived, markAsReceivedAndInProcess, markAsRefunded }
