import React, { useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { concat, filter, includes, isEmpty, isNil, map } from 'lodash'
import couriersCatalog from '../../model/couriersCatalog.js'
import countries from '../../model/countries.js'
import Loading from '../molecules/Loading/Loading.jsx'
import Button from '../atoms/Button/Button.jsx'
import Header from '../organisms/Header/Header.jsx'
import CatalogList from '../organisms/Couriers/CatalogList.jsx'
import CatalogForm from '../molecules/Couriers/CatalogForm.jsx'

import './errors.scss'
import couriers from '../../model/couriers.js'

const CouriersCatalogPage = props => {
    const defaultFilters = {
        courier: 0,
        name: '',
    }
    const [isLoading, setLoading] = useState(true)
    const [filters, setFilters] = useState(defaultFilters)
    const [catalogs, setCatalogs] = useState([])
    const [catalogsFiltered, setCatalogsFiltered] = useState([])
    const [countriesList, setCountriesList] = useState([])
    const [couriersList, setCouriersList] = useState([])
    const [errors, setErrors] = useState([])

    const handleDelete = async catalogId => {
        try {
            const isSuccess = await couriersCatalog.deleteCatalog(catalogId)
            if (isSuccess) {
                const newList = filter(catalogs, c => c.dtoGuid !== catalogId)
                setCatalogs(newList)
                setErrors([])
            }
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
    }

    const updateCourier = courierId => {
        setFilters(prevState => ({
            ...prevState,
            courier: courierId,
        }))
    }

    const updateName = name => {
        setFilters(prevState => ({
            ...prevState,
            name: name,
        }))
    }

    const fetchCatalogs = async () => {
        try {
            setLoading(true)
            const fetchedCatalogs = await couriersCatalog.getCatalogs()
            setCatalogs(fetchedCatalogs)
            setErrors([])
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
        setLoading(false)
    }

    const updateCatalogs = async (courierId, name) => {
        try {
            if (courierId != 0 && !isNil(name) && !isEmpty(name) && name.length >= 3) {
                const filtered = filter(
                    catalogs,
                    c => c.zone.courier.courierId === courierId && includes(c.name.toLowerCase(), name.toLowerCase())
                )
                setCatalogsFiltered(filtered)
            } else if (courierId != 0) {
                const filtered = filter(catalogs, c => c.zone.courier.courierId === courierId)
                setCatalogsFiltered(filtered)
            } else if (!isNil(name) && !isEmpty(name) && name.length >= 3) {
                const filtered = filter(catalogs, c => includes(c.name.toLowerCase(), name.toLowerCase()))
                setCatalogsFiltered(filtered)
            } else {
                setCatalogsFiltered(catalogs)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const fetchCountries = async () => {
        try {
            const fetchedCountries = await countries.getCountries('en-US')
            setCountriesList(fetchedCountries)
            setErrors([])
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
    }

    const fetchCouriers = async () => {
        try {
            const fetchedCouriers = await couriers.getCouriers()
            setCouriersList(fetchedCouriers)
            setErrors([])
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
    }

    useEffect(() => {
        fetchCatalogs()
        fetchCountries()
        fetchCouriers()
    }, [])

    useEffect(() => {
        updateCatalogs(filters.courier, filters.name)
    }, [filters.courier, filters.name, catalogs.length])

    return isLoading ? (
        <Loading />
    ) : (
        <div>
            <Header>
                <div className="title">
                    <h2>Couriers price catalogs</h2>
                </div>
                <div className="cta">
                    <Link to={`${props.match.url}/create`}>
                        <Button text="Create new catalog" />
                    </Link>
                </div>
            </Header>
            {errors.length > 0 && (
                <div className="errors-container">
                    {map(errors, (e, idx) => (
                        <p key={idx} className="error">
                            {e}
                        </p>
                    ))}
                </div>
            )}
            <Switch>
                <Route
                    exact
                    path={props.match.url}
                    render={() => (
                        <CatalogList
                            catalogs={catalogsFiltered}
                            url={props.match.url}
                            handleDelete={handleDelete}
                            countries={countriesList}
                            updateList={fetchCatalogs}
                            filters={filters}
                            updateCourier={updateCourier}
                            updateName={updateName}
                            couriersList={couriersList}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${props.match.url}/create`}
                    render={() => <CatalogForm mode="create" url={props.match.url} updateList={fetchCatalogs} />}
                />
                <Route
                    exact
                    path={`${props.match.url}/duplicate/:catalogId`}
                    render={routeProps => (
                        <CatalogForm
                            mode="create"
                            url={props.match.url}
                            catalogId={routeProps.match.params.catalogId}
                            updateList={fetchCatalogs}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${props.match.url}/edit/:catalogId`}
                    render={routeProps => (
                        <CatalogForm
                            mode="edit"
                            url={props.match.url}
                            catalogId={routeProps.match.params.catalogId}
                            updateList={fetchCatalogs}
                        />
                    )}
                />
            </Switch>
        </div>
    )
}

export default CouriersCatalogPage
