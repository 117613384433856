import React, { useEffect, useState } from 'react'
import { isEmpty, isNil } from 'lodash'
import config from '../config'
import { init } from '../helpers/ga4ServiceHelper'

const GoogleTagManager = ({ culture }) => {
    const [googleConfig, setGoogleConfig] = useState({
        analytics4MeasurementId: '',
        analytics4MeasurementGlobalId: '',
        tagManagerCode: '',
    })

    useEffect(() => {
        // console.log(culture)
        const newConfig = config.getGoogleTagManagerData(culture)
        setGoogleConfig(newConfig)
    }, [culture])

    useEffect(() => {
        if (!isEmpty(googleConfig.tagManagerCode)) {
            createScriptTag(window, document, 'script', 'dataLayer', googleConfig.tagManagerCode)
        }

        init({ debug: true })

        if (!isNil(window.gtag)) {
            window.gtag('config', googleConfig.analytics4MeasurementId)
            window.gtag('config', googleConfig.analytics4MeasurementGlobalId)
        }
    }, [googleConfig.tagManagerCode])

    const createScriptTag = (w, d, s, l, i) => {
        w[l] = w[l] || []
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        })
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
    }

    return (
        <>
            <noscript>
                <iframe
                    src={`//www.googletagmanager.com/ns.html?id=${googleConfig.tagManagerCode}`}
                    height="0"
                    width="0"
                    style="display:none;visibility:hidden"
                />
            </noscript>
            <div id="google-tag-manager" data-tag-manager-code={googleConfig.tagManagerCode}></div>
            <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${googleConfig.analytics4MeasurementId}`}
            ></script>
        </>
    )
}
export default GoogleTagManager
