const NAMESPACE = 'FEEDBACKS'

export const ACTION_TYPES = {
    SEARCH_FEEDBACKS: `${NAMESPACE}/SEARCH_FEEDBACKS`,
    FEEDBACKS_RECEIVED: `${NAMESPACE}/FEEDBACKS_RECEIVED`,
    DELETE_FEEDBACK_REQUEST: `${NAMESPACE}/DELETE_FEEDBACK_REQUEST`,
    FEEDBACK_DELETED: `${NAMESPACE}/FEEDBACK_DELETED`,
    ANSWER_FEEDBACK_REQUEST: `${NAMESPACE}/ANSWER_FEEDBACK_REQUEST`,
    FEEDBACK_ANSWERED: `${NAMESPACE}/FEEDBACK_ANSWERED`,
    APPROVE_FEEDBACK_REQUEST: `${NAMESPACE}/APPROVE_FEEDBACK_REQUEST`,
    FEEDBACK_APPROVED: `${NAMESPACE}/FEEDBACK_APPROVED`,
}

export const searchFeedbacks = culture => ({
    type: ACTION_TYPES.SEARCH_FEEDBACKS,
    payload: culture,
})

export const feedbacksReceived = list => ({
    type: ACTION_TYPES.FEEDBACKS_RECEIVED,
    payload: list,
})

export const deleteFeedback = feedbackId => ({
    type: ACTION_TYPES.DELETE_FEEDBACK_REQUEST,
    payload: feedbackId,
})

export const feedbackDeleted = feedbackId => ({
    type: ACTION_TYPES.FEEDBACK_DELETED,
    payload: feedbackId,
})

export const answerFeedbackRequest = (feedbackId, answer, culture) => ({
    type: ACTION_TYPES.ANSWER_FEEDBACK_REQUEST,
    payload: { feedbackId, answer, culture },
})

export const feedbackAnswered = (feedbackId, answer) => ({
    type: ACTION_TYPES.FEEDBACK_ANSWERED,
    payload: { feedbackId, answer },
})

export const approveFeedbackRequest = feedbackId => ({
    type: ACTION_TYPES.APPROVE_FEEDBACK_REQUEST,
    payload: feedbackId,
})

export const feedbackApproved = feedbackId => ({
    type: ACTION_TYPES.FEEDBACK_APPROVED,
    payload: feedbackId,
})
