import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import { find, isNil } from 'lodash'
import { getServiceName } from '../../../services/couriers/shippingServices.js'
import Button from '../../atoms/Button/Button.jsx'

const ShippingService = ({ shippingService, url, handleDelete, countries }) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const getCountry = (countriesList, countryCode) => {
        const country = find(countriesList, c => c.code === countryCode)
        return isNil(country) ? '' : `${country.name} (${country.code})`
    }

    return (
        <>
            <div>{shippingService.logoUrl !== '' && <img src={shippingService.logoUrl} />}</div>
            <div>{shippingService.courierServiceId}</div>
            <div>{getServiceName(shippingService.courierServiceNames)}</div>
            <div>{getCountry(countries, shippingService.destinationCountryCode)}</div>
            <div>{shippingService.courierServiceFreeShippingThreshold}</div>
            <div className="shippingServices-cta">
                <Link to={`${url}/duplicate/${shippingService.dtoGuid}`}>
                    <Button text="Duplicate" />
                </Link>
                <Link to={`${url}/edit/${shippingService.dtoGuid}`}>
                    <Button text="Edit" />
                </Link>
                <Button
                    onClick={() => {
                        setShowConfirmationModal(true)
                    }}
                    text="Delete"
                />
            </div>
            <Modal
                isOpen={showConfirmationModal}
                onRequestClose={() => {
                    setShowConfirmationModal(false)
                }}
                closeTimeoutMS={500}
                shouldFocusAfterRender={false}
                className={{
                    base: 'shippingService-modal',
                    afterOpen: 'shippingService-modal-afterOpen',
                    beforeClose: 'shippingService-modal-beforeClose',
                }}
                overlayClassName={{
                    base: 'shippingService-modal-overlay',
                    afterOpen: 'shippingService-modal-overlay-afterOpen',
                    beforeClose: 'shippingService-modal-overlay-beforeClose',
                }}
            >
                <h3>Are you sure?</h3>
                <p>You are deleting the shipping service {getServiceName(shippingService.courierServiceNames)}.</p>
                <p>This operation cannot be undone.</p>
                <Button
                    onClick={() => {
                        setShowConfirmationModal(false)
                    }}
                    text="Cancel"
                />
                <Button
                    onClick={() => {
                        handleDelete(shippingService.dtoGuid)
                        setShowConfirmationModal(false)
                    }}
                    text="Confirm"
                />
            </Modal>
        </>
    )
}

export default ShippingService
