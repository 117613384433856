import React from 'react'
import Infobox from '../../molecules/Infoboxes/Infobox.jsx'
import InfoboxForm from '../../molecules/Infoboxes/InfoboxForm.jsx'
import './InfoboxList.scss'

const InfoboxList = ({ infoboxes, editMode, countries }) => {
    const renderInfoboxes = item => {
        if (editMode[item.id]) return <InfoboxForm {...item} countries={countries} />
        return <Infobox {...item} countries={countries} />
    }
    const x = infoboxes === undefined ? [] : [...infoboxes]

    const infoboxesFound = x.map((i, idx) => {
        if (i.infoSecondary) {
            return (
                <li key={idx} className="infobox-item">
                    {renderInfoboxes(i)}
                </li>
            )
        }
    })

    return <ul className="infobox">{infoboxesFound}</ul>
}

export default InfoboxList
