/* eslint-disable no-console */
import { keys, filter } from 'lodash'

var mapConfig = {
    backofficeSite: {
        lordgun: {
            production: 'https://backoffice.lordgunbicycles.com/api',
            staging: 'https://staging.lordgunbicycles.com/backoffice/api',
            development: 'http://localhost/jsport.backoffice.api',
        },
        runkd: {
            production: 'https://backoffice.runkd.com/api',
            staging: 'https://staging.lordgunbicycles.com/RUNKD/backoffice/api',
            development: 'http://localhost/jsport.backoffice.api',
        },
        inwild: {
            production: 'https://backoffice.inwild.com/api',
            staging: 'https://staging.lordgunbicycles.com/Inwild/backoffice/api',
            development: 'http://localhost/jsport.backoffice.api',
        },
    },
    site: {
        lordgun: {
            production: {
                'it-IT': 'https://www.lordgunbicycles.com/',
                'fr-FR': 'https://www.lordgunbicycles.fr/',
                'es-ES': 'https://www.lordgunbicycles.es/',
                'de-DE': 'https://www.lordgun.de/',
                'en-GB': 'https://www.lordgunbicycles.co.uk/',
                'en-US': 'https://www.lordgun.com/',
                'en-AU': 'https://www.lordgun.com.au/',
                default: 'https://www.lordgun.com/',
            },
            staging: {
                'it-IT': 'https://www.lordgunbicycles.com/',
                'fr-FR': 'https://www.lordgunbicycles.fr/',
                'es-ES': 'https://www.lordgunbicycles.es/',
                'de-DE': 'https://www.lordgun.de/',
                'en-GB': 'https://www.lordgunbicycles.co.uk/',
                'en-US': 'https://www.lordgun.com/',
                'en-AU': 'https://www.lordgun.com.au/',
                default: 'https://www.lordgun.com/',
            },
            development: {
                'it-IT': 'https://www.lordgunbicycles.com/',
                'fr-FR': 'https://www.lordgunbicycles.fr/',
                'es-ES': 'https://www.lordgunbicycles.es/',
                'de-DE': 'https://www.lordgun.de/',
                'en-GB': 'https://www.lordgunbicycles.co.uk/',
                'en-US': 'https://www.lordgun.com/',
                'en-AU': 'https://www.lordgun.com.au/',
                default: 'https://www.lordgun.com/',
            },
        },
        runkd: {
            production: {
                'it-IT': 'https://www.runkd.it/',
                'fr-FR': 'https://www.runkd.fr/',
                'es-ES': 'https://www.runkd.es/',
                'de-DE': 'https://www.runkd.de/',
                'en-GB': 'https://www.runkd.co.uk/',
                'en-US': 'https://www.runkd.com/',
                default: 'https://www.runkd.com/',
            },
            staging: {
                'it-IT': 'https://www.runkd.it/',
                'fr-FR': 'https://www.runkd.fr/',
                'es-ES': 'https://www.runkd.es/',
                'de-DE': 'https://www.runkd.de/',
                'en-GB': 'https://www.runkd.co.uk/',
                'en-US': 'https://www.runkd.com/',
                default: 'https://www.runkd.com/',
            },
            development: {
                'it-IT': 'https://www.runkd.it/',
                'fr-FR': 'https://www.runkd.fr/',
                'es-ES': 'https://www.runkd.es/',
                'de-DE': 'https://www.runkd.de/',
                'en-GB': 'https://www.runkd.co.uk/',
                'en-US': 'https://www.runkd.com/',
                default: 'https://www.runkd.com/',
            },
        },
        inwild: {
            production: {
                'it-IT': 'https://www.inwild.it/',
                'fr-FR': 'https://www.inwild.fr/',
                'es-ES': 'https://www.inwild.es/',
                'de-DE': 'https://www.inwild.de/',
                'en-GB': 'https://www.inwild.co.uk/',
                'en-US': 'https://www.inwild.com/',
                default: 'https://www.inwild.com/',
            },
            staging: {
                'it-IT': 'https://www.inwild.it/',
                'fr-FR': 'https://www.inwild.fr/',
                'es-ES': 'https://www.inwild.es/',
                'de-DE': 'https://www.inwild.de/',
                'en-GB': 'https://www.inwild.co.uk/',
                'en-US': 'https://www.inwild.com/',
                default: 'https://www.inwild.com/',
            },
            development: {
                'it-IT': 'https://www.inwild.it/',
                'fr-FR': 'https://www.inwild.fr/',
                'es-ES': 'https://www.inwild.es/',
                'de-DE': 'https://www.inwild.de/',
                'en-GB': 'https://www.inwild.co.uk/',
                'en-US': 'https://www.inwild.com/',
                default: 'https://www.inwild.com/',
            },
        },
    },
    teamname: {
        lordgun: 'LordGunTeam',
        runkd: 'JSportTeam',
        inwild: 'JSportTeam',
    },
    cdn: {
        lordgun: {
            production: {
                useCdn: true,
                cdnImagesUrl: 'https://d3n8mmvj9ns1ml.cloudfront.net',
                cdnImagesFallbackUrl: 'https://sync.lordgunbicycles.com:4433',
            },
            staging: {
                useCdn: false,
                cdnImagesUrl: 'https://d3n8mmvj9ns1ml.cloudfront.net',
                cdnImagesFallbackUrl: 'https://sync.lordgunbicycles.com:4433',
            },
            development: {
                useCdn: false,
                cdnImagesUrl: 'https://d3n8mmvj9ns1ml.cloudfront.net',
                cdnImagesFallbackUrl: 'https://sync.lordgunbicycles.com:4433',
            },
        },
        runkd: {
            production: {
                useCdn: true,
                cdnImagesUrl: 'https://dufrl78eaxiu3.cloudfront.net',
                cdnImagesFallbackUrl: 'https://sync.runkd.com:4433',
            },
            staging: {
                useCdn: false,
                cdnImagesUrl: 'https://dufrl78eaxiu3.cloudfront.net',
                cdnImagesFallbackUrl: 'https://sync.runkd.com:4433',
            },
            development: {
                useCdn: false,
                cdnImagesUrl: 'https://dufrl78eaxiu3.cloudfront.net',
                cdnImagesFallbackUrl: 'https://sync.runkd.com:4433',
            },
        },
        inwild: {
            production: {
                useCdn: true,
                cdnImagesUrl: 'https://d26ztvr3v93474.cloudfront.net',
                cdnImagesFallbackUrl: 'https://sync.inwild.com:4433',
            },
            staging: {
                useCdn: false,
                cdnImagesUrl: 'https://d26ztvr3v93474.cloudfront.net',
                cdnImagesFallbackUrl: 'https://sync.inwild.com:4433',
            },
            development: {
                useCdn: false,
                cdnImagesUrl: 'https://d26ztvr3v93474.cloudfront.net',
                cdnImagesFallbackUrl: 'https://sync.inwild.com:4433',
            },
        },
    },
    sites: {
        lordgun: [
            'https://www.lordgunbicycles.com/',
            'https://www.lordgunbicycles.fr/',
            'https://www.lordgunbicycles.es/',
            'https://www.lordgun.de/',
            'https://www.lordgunbicycles.co.uk/',
            'https://www.lordgun.com/',
            'https://www.lordgun.com.au/',
        ],
        runkd: [
            'https://www.runkd.it/',
            'https://www.runkd.fr/',
            'https://www.runkd.es/',
            'https://www.runkd.de/',
            'https://www.runkd.co.uk/',
            'https://www.runkd.com/',
        ],
        inwild: [
            'https://www.inwild.it/',
            'https://www.inwild.fr/',
            'https://www.inwild.es/',
            'https://www.inwild.de/',
            'https://www.inwild.co.uk/',
            'https://www.inwild.com/',
        ],
    },
    google: {
        lordgun: {
            production: {
                'it-IT': {
                    analytics4MeasurementId: 'G-98106XCF0J',
                    analytics4MeasurementGlobalId: 'G-BES8PX72X9',
                    tagManagerCode: 'GTM-K77Z4L',
                },
                'fr-FR': {
                    analytics4MeasurementId: 'G-S5Q3MQS52N',
                    analytics4MeasurementGlobalId: 'G-BES8PX72X9',
                    tagManagerCode: 'GTM-TDTPGN',
                },
                'es-ES': {
                    analytics4MeasurementId: 'G-41H92B3Y3R',
                    analytics4MeasurementGlobalId: 'G-BES8PX72X9',
                    tagManagerCode: 'GTM-5P632W',
                },
                'de-DE': {
                    analytics4MeasurementId: 'G-LTXN3GPWEQ',
                    analytics4MeasurementGlobalId: 'G-BES8PX72X9',
                    tagManagerCode: 'GTM-NN7SWDD',
                },
                'en-GB': {
                    analytics4MeasurementId: 'G-34JKVSYFCX',
                    analytics4MeasurementGlobalId: 'G-BES8PX72X9',
                    tagManagerCode: 'GTM-TBSRN4',
                },
                'en-US': {
                    analytics4MeasurementId: 'G-SSKCGCKHWK',
                    analytics4MeasurementGlobalId: 'G-BES8PX72X9',
                    tagManagerCode: 'GTM-NBS4N68',
                },
                'en-AU': {
                    analytics4MeasurementId: 'G-M0TFEVX8RP',
                    analytics4MeasurementGlobalId: 'G-BES8PX72X9',
                    tagManagerCode: 'GTM-TRVDBLT',
                },
                default: {
                    analytics4MeasurementId: '',
                    analytics4MeasurementGlobalId: '',
                    tagManagerCode: '',
                },
            },
            staging: {
                'it-IT': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'fr-FR': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'es-ES': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'de-DE': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'en-GB': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'en-US': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'en-AU': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                default: {
                    analytics4MeasurementId: '',
                    analytics4MeasurementGlobalId: '',
                    tagManagerCode: 'GTM-KQDH76',
                },
            },
            development: {
                'it-IT': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'fr-FR': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'es-ES': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'de-DE': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'en-GB': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'en-US': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'en-AU': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                default: {
                    analytics4MeasurementId: '',
                    analytics4MeasurementGlobalId: '',
                    tagManagerCode: 'GTM-KQDH76',
                },
            },
        },
        runkd: {
            production: {
                'it-IT': {
                    analytics4MeasurementId: 'G-7EXPJ9R2YD',
                    analytics4MeasurementGlobalId: 'G-M5N1L2KRT4',
                    tagManagerCode: 'GTM-NKSCVW2',
                },
                'fr-FR': {
                    analytics4MeasurementId: 'G-VKME5NSF61',
                    analytics4MeasurementGlobalId: 'G-M5N1L2KRT4',
                    tagManagerCode: 'GTM-W8LF59Z',
                },
                'es-ES': {
                    analytics4MeasurementId: 'G-82LTQ125VV',
                    analytics4MeasurementGlobalId: 'G-M5N1L2KRT4',
                    tagManagerCode: 'GTM-5T4ZTTJ',
                },
                'de-DE': {
                    analytics4MeasurementId: 'G-ENS5LF902B',
                    analytics4MeasurementGlobalId: 'G-M5N1L2KRT4',
                    tagManagerCode: 'GTM-KKMX7TR',
                },
                'en-GB': {
                    analytics4MeasurementId: 'G-3V7PXF6BY6',
                    analytics4MeasurementGlobalId: 'G-M5N1L2KRT4',
                    tagManagerCode: 'GTM-KKMX7TR',
                },
                'en-US': {
                    analytics4MeasurementId: 'GTM-PLFPTCN',
                    analytics4MeasurementGlobalId: 'G-M5N1L2KRT4',
                    tagManagerCode: 'G-7SEGWTYEGS',
                },
                default: { analytics4MeasurementId: '', analytics4MeasurementGlobalId: '', tagManagerCode: '' },
            },
            staging: {
                'it-IT': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'fr-FR': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'es-ES': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'de-DE': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'en-GB': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'en-US': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                default: {
                    analytics4MeasurementId: '',
                    analytics4MeasurementGlobalId: '',
                    tagManagerCode: 'GTM-KQDH76',
                },
            },
            development: {
                'it-IT': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-NKSCVW2',
                },
                'fr-FR': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-NKSCVW2',
                },
                'es-ES': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-NKSCVW2',
                },
                'de-DE': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-NKSCVW2',
                },
                'en-GB': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-NKSCVW2',
                },
                'en-US': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-NKSCVW2',
                },
                default: {
                    analytics4MeasurementId: '',
                    analytics4MeasurementGlobalId: '',
                    tagManagerCode: 'GTM-NKSCVW2',
                },
            },
        },
        inwild: {
            production: {
                'it-IT': {
                    analytics4MeasurementId: 'G-BWCSGMVH92',
                    analytics4MeasurementGlobalId: 'G-HK3XESCTNM',
                    tagManagerCode: 'GTM-M86K9PN',
                },
                'fr-FR': {
                    analytics4MeasurementId: 'G-3KVFHKBQNH',
                    analytics4MeasurementGlobalId: 'G-HK3XESCTNM',
                    tagManagerCode: 'GTM-PCQ6CJW',
                },
                'es-ES': {
                    analytics4MeasurementId: 'G-W6X3EGENLV',
                    analytics4MeasurementGlobalId: 'G-HK3XESCTNM',
                    tagManagerCode: 'GTM-5R2XS95',
                },
                'de-DE': {
                    analytics4MeasurementId: 'G-Y155NRJTNL',
                    analytics4MeasurementGlobalId: 'G-HK3XESCTNM',
                    tagManagerCode: 'GTM-WWTQTMC',
                },
                'en-GB': {
                    analytics4MeasurementId: 'G-P05DFSL7HW',
                    analytics4MeasurementGlobalId: 'G-HK3XESCTNM',
                    tagManagerCode: 'GTM-WSVQWQ2',
                },
                'en-US': {
                    analytics4MeasurementId: 'G-5EQB68K1CF',
                    analytics4MeasurementGlobalId: 'G-HK3XESCTNM',
                    tagManagerCode: 'GTM-K2DWLXH',
                },
                default: { analytics4MeasurementId: '', analytics4MeasurementGlobalId: '', tagManagerCode: '' },
            },
            staging: {
                'it-IT': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'fr-FR': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'es-ES': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'de-DE': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'en-GB': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'en-US': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                default: {
                    analytics4MeasurementId: '',
                    analytics4MeasurementGlobalId: '',
                    tagManagerCode: 'GTM-KQDH76',
                },
            },
            development: {
                'it-IT': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'fr-FR': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'es-ES': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'de-DE': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'en-GB': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                'en-US': {
                    analytics4MeasurementId: 'G-XXXXXXXXXX',
                    analytics4MeasurementGlobalId: 'G-YYYYYYYYYY',
                    tagManagerCode: 'GTM-KQDH76',
                },
                default: {
                    analytics4MeasurementId: '',
                    analytics4MeasurementGlobalId: '',
                    tagManagerCode: 'GTM-KQDH76',
                },
            },
        },
    },
    cultures: ['it-IT', 'fr-FR', 'es-ES', 'de-DE', 'en-GB', 'en-US', 'en-AU'],
}

var currentSite = process.env.SITE === undefined ? 'lordgun' : process.env.SITE.toLowerCase().trim() || 'lordgun'
var stage = process.env.NODE_ENV.toLowerCase().trim()
console.log('Configuration: ', currentSite, stage, mapConfig['site'][currentSite][stage])

export default {
    currentSite,
    apiRoot: mapConfig['backofficeSite'][currentSite][stage],
    getSiteByCulture: (culture = 'default') =>
        mapConfig['site'][currentSite][stage][culture] || mapConfig['site'][currentSite][stage]['default'],
    getTeamName: () => mapConfig['teamname'][currentSite],
    getLocalizedWebsitesCultures: () => filter(keys(mapConfig['site'][currentSite][stage]), item => item !== 'default'),
    getCdnConfig: () => mapConfig['cdn'][currentSite][stage],
    getSites: () => mapConfig['sites'][currentSite],
    getCultures: () => mapConfig['cultures'],
    getGoogleTagManagerData: (culture = 'default') =>
        mapConfig['google'][currentSite][stage][culture] || mapConfig['google'][currentSite][stage]['default'],
}
