import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import Dropzone from 'react-dropzone'
import { useDropzone } from 'react-dropzone'
import { filter, forEach, includes, isEmpty, isNil, join, map, sortBy } from 'lodash'
import config from '../../../config.js'
import { submitWarehousePickup } from '../../../services/couriers/warehousePickups.js'
import Loading from '../Loading/Loading.jsx'
import Button from '../../atoms/Button/Button.jsx'

import './WarehousePickupForm.scss'
import warehousePickups from '../../../model/warehousePickups.js'

const WarehousePickupForm = ({ id, mode, url, updateList, countries }) => {
    const [isLoading, setLoading] = useState(true)
    const [isSubmitSuccess, setSubmitSuccess] = useState(false)
    const [warehousePickup, setWarehousePickup] = useState({
        names: map(config.getCultures(), c => ({
            culture: c,
            name: '',
            deliveryInfo: '',
        })),
        warehousePickupId: 0,
        countries: [],
        logoUrl: '',
        logoFile: null,
    })
    const [errors, setErrors] = useState({})
    const buttonText = {
        create: 'Create',
        edit: 'Save',
    }
    const baseStyle = {
        width: 180,
        height: 180,
        borderWidth: 2,
        borderColor: '#666',
        borderStyle: 'dashed',
        borderRadius: 5,
        padding: 20,
    }
    const activeStyle = {
        borderStyle: 'solid',
        borderColor: '#6c6',
        backgroundColor: '#eee',
    }
    const rejectStyle = {
        borderStyle: 'solid',
        borderColor: '#c66',
        backgroundColor: '#eee',
    }

    const submit = async () => {
        setLoading(true)
        let errors
        try {
            switch (mode) {
                case 'create':
                    errors = await submitWarehousePickup(warehousePickup, 'create', 'POST')
                    break
                case 'edit':
                    errors = await submitWarehousePickup(warehousePickup, `${id}`, 'PUT')
                    break
                default:
                    break
            }

            setLoading(false)
            if (isEmpty(errors)) {
                setSubmitSuccess(true)
            }

            setErrors(errors)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const fetchCourier = async id => {
        try {
            const courier = await warehousePickups.getWarehousePickup(id)
            setWarehousePickup(courier)
        } catch (error) {
            console.error(error)
        }
    }

    const updateName = (culture, value) => {
        const item = find(warehousePickup.names, n => n.culture === culture)

        if (!isNil(item)) {
            const newNames = map(warehousePickup.names, n => ({
                culture: n.culture,
                deliveryInfo: n.deliveryInfo,
                name: n.culture === culture ? value : n.name,
            }))

            setWarehousePickup(prevState => ({
                ...prevState,
                names: newNames,
            }))
        }
    }

    const updateDeliveryInfo = (culture, value) => {
        const item = find(warehousePickup.names, n => n.culture === culture)

        if (!isNil(item)) {
            const newNames = map(warehousePickup.names, n => ({
                culture: n.culture,
                name: n.name,
                deliveryInfo: n.culture === culture ? value : n.deliveryInfo,
            }))

            setWarehousePickup(prevState => ({
                ...prevState,
                names: newNames,
            }))
        }
    }

    const updateCountries = value => {
        let newCountriesList = []
        if (includes(warehousePickup.countries, value)) {
            newCountriesList = filter(warehousePickup.countries, c => c !== value)
        } else {
            newCountriesList = warehousePickup.countries
            newCountriesList.push(value)
        }
        setWarehousePickup(prevState => ({
            ...prevState,
            countries: newCountriesList,
        }))
    }

    const readFile = file => {
        return new Promise((resolve, reject) => {
            // Create file reader
            let reader = new FileReader()
            // Register event listeners
            reader.addEventListener('loadend', e => resolve(e.target.result))
            reader.addEventListener('error', reject)
            // Read file
            reader.readAsArrayBuffer(file)
        })
    }

    const getAsByteArray = async file => {
        try {
            let bytes = await readFile(file)
            let uint8Array = new Uint8Array(bytes)
            let array = []
            forEach(uint8Array, b => {
                array.push(b)
            })
            return array
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const updateLogo = async acceptedFiles => {
        const imgFile = acceptedFiles[0]
        Object.assign(imgFile, {
            preview: URL.createObjectURL(imgFile),
        })

        const img = {
            name: imgFile.name,
            bytes: [],
        }
        img.bytes = await getAsByteArray(imgFile)

        setWarehousePickup(prevState => ({
            ...prevState,
            logoFile: img,
        }))
    }

    useEffect(() => {
        if (!isNil(id)) {
            fetchCourier(id)
        }
        setLoading(false)
    }, [])

    const showImageErrors = fileRejections => {
        return map(fileRejections, ({ file, errors }, idx) => (
            <p className="error" key={idx}>
                {file.name}:{' '}
                {join(
                    map(errors, e => e.message),
                    '. '
                )}
            </p>
        ))
    }

    const preview = file => (
        <div className="warehousePickup-form-preview">
            <div
                className="warehousePickup-form-preview-icon"
                onClick={() => {
                    setWarehousePickup(prevState => ({
                        ...prevState,
                        logoFile: null,
                        logoUrl: '',
                    }))
                }}
            >
                <svg height="20" width="20">
                    <path d="M5 5 L13 13" />
                    <path d="M5 13 L13 5" />
                </svg>
            </div>
            <img src={file.preview} alt={file.name} />
            <p className="warehousePickup-form-preview-text">{file.name}</p>
        </div>
    )

    const { getRootProps } = useDropzone({
        // Note how this callback is never invoked if drop occurs on the inner dropzone
        onDrop: updateLogo,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
        },
        maxFiles: 1,
    })

    return isLoading ? (
        <Loading />
    ) : (
        <form className="warehousePickup-form">
            {!isNil(errors.generic) && !isEmpty(errors.generic) && <p className="error">{errors.generic}</p>}
            <div className="warehousePickup-form-row">
                <label className="warehousePickup-form-label" htmlFor="warehousePickupId">
                    Warehouse Pickup id:
                    <input
                        type="text"
                        value={warehousePickup.warehousePickupId}
                        name="warehousePickupId"
                        onChange={e => {
                            const value = e.target.value
                            setWarehousePickup(prevState => ({
                                ...prevState,
                                warehousePickupId: value,
                            }))
                        }}
                    />
                </label>
                {!isNil(errors.warehousePickupId) && !isEmpty(errors.warehousePickupId) && (
                    <p className="error">{errors.warehousePickupId}</p>
                )}
            </div>
            <div className="warehousePickup-form-row">
                <b>Warehouse pickup names:</b>
                {map(warehousePickup.names, (n, idx) => (
                    <fieldset key={idx}>
                        <legend>{n.culture}</legend>
                        <label className="warehousePickup-form-label" htmlFor={`${n.culture}-name`}>
                            Name:
                            <input
                                type="text"
                                name={`${n.culture}-name`}
                                value={n.name}
                                onChange={e => {
                                    const value = e.target.value
                                    updateName(n.culture, value)
                                }}
                            />
                        </label>
                        <label className="warehousePickup-form-label" htmlFor={`${n.culture}-deliveryInfo`}>
                            Delivery info:
                            <input
                                type="text"
                                name={`${n.culture}-deliveryInfo`}
                                value={isNil(n.deliveryInfo) ? '' : n.deliveryInfo}
                                onChange={e => {
                                    const value = e.target.value
                                    updateDeliveryInfo(n.culture, value)
                                }}
                            />
                        </label>
                        {!isNil(errors.names) &&
                            !isEmpty(errors.names) &&
                            !isNil(errors.names[n.culture]) &&
                            !isEmpty(errors.names[n.culture]) && <p className="error">{errors.names[n.culture]}</p>}
                    </fieldset>
                ))}
            </div>
            <div className="warehousePickup-form-row">
                <p>
                    <strong>Countries:</strong>
                </p>
                <div className="warehousePickup-form-row-checkboxes">
                    {map(
                        sortBy(countries, item => item.name),
                        (c, idx) => (
                            <label
                                key={idx}
                                className="warehousePickup-form-label"
                                htmlFor={c.code}
                                onClick={() => updateCountries(c.code)}
                                style={{ cursor: 'pointer' }}
                            >
                                <input
                                    name={c.code}
                                    type="checkbox"
                                    checked={includes(warehousePickup.countries, c.code)}
                                    readOnly
                                />
                                {c.name}
                            </label>
                        )
                    )}
                </div>
                {!isNil(errors.countries) && !isEmpty(errors.countries) && <p className="error">{errors.countries}</p>}
            </div>
            <div className="warehousePickup-form-row">
                {!isNil(warehousePickup.logoFile) ||
                !(isNil(warehousePickup.logoUrl) || isEmpty(warehousePickup.logoUrl)) ? (
                    <>
                        <p>
                            <strong>Warehouse pickup logo:</strong>
                        </p>
                        {preview(
                            isNil(warehousePickup.logoFile)
                                ? { name: warehousePickup.logoUrl, preview: warehousePickup.logoUrl }
                                : warehousePickup.logoFile
                        )}
                    </>
                ) : (
                    <Dropzone>
                        {({ getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections }) => {
                            let styles = { ...baseStyle }
                            styles = isDragActive ? { ...styles, ...activeStyle } : styles
                            styles = isDragReject ? { ...styles, ...rejectStyle } : styles

                            return (
                                <>
                                    <div {...getRootProps()} style={styles}>
                                        <input {...getInputProps()} />
                                        <h3>Upload warehouse pickup logo!</h3>
                                        <p>JPG or GIF only</p>
                                        <div>{isDragAccept ? 'Drop' : 'Drag'} files here...</div>
                                        {isDragReject && <div>Unsupported file type...</div>}
                                    </div>
                                    {!isEmpty(fileRejections) && showImageErrors(fileRejections)}
                                </>
                            )
                        }}
                    </Dropzone>
                )}
            </div>
            <Button
                text={buttonText[mode]}
                type="button"
                onClick={async () => {
                    submit()
                }}
            />
            <Link to={url}>
                <Button text="Cancel" type="button" />
            </Link>
            <Modal isOpen={isSubmitSuccess} contentLabel="Success modal" className="Modal" overlayClassName="Overlay">
                <p>Operation successful.</p>
                <Link to={url}>
                    <Button text="Back to warehouse pickup list" type="button" onClick={updateList} />
                </Link>
            </Modal>
        </form>
    )
}

export default WarehousePickupForm
