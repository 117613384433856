import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

const getCdnUrl = async () =>
    fetch(`${config.apiRoot}/v1/SizeGuides/CdnUrl`, {
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.text()
        }
        throw new ServerError(r)
    })
export default { getCdnUrl }
