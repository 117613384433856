import { http } from '../helpers/httpHelper'

const upload = (name, image) => {
    const formData = {}
    formData[name] = image
    return http.upload({ url: 'categoryBanners/upload', formData: formData })
}
const get = (categoryId, culture) =>
    http.get({ url: 'categoryBanners', qs: { culture: culture, categoryId: categoryId } })
const put = banner => http.put({ url: `categoryBanners/${banner.id}`, body: banner })
const post = banner => http.post({ url: 'categoryBanners', body: banner })
const deleteBanner = bannerId => http.remove({ url: `CategoryBanners/${bannerId}` })

export default {
    upload,
    get,
    put,
    post,
    deleteBanner,
}
