import React from 'react'
import { connect } from 'react-redux'
import * as modalActions from '../../../redux/modal/modal.actions'
import modalWithCloseOnOutside from '../../../components/organisms/ModalWithCloseOnOutside/ModalWithCloseOnOutside.jsx'

import './modalImage.scss'

class ModalImageWrappedComponent extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="imageContainer">
                <img className="fullImage" src={this.props.filename} onClick={this.props.closeHandler} />
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    closeHandler: () => dispatch(modalActions.hideModal()),
})

const ModalImage = modalWithCloseOnOutside(ModalImageWrappedComponent, props => props.isOpen)

export default connect(null, mapDispatchToProps)(ModalImage)
