import { put } from 'redux-saga/effects'
import messages from '../../helpers/messages'
import ServerError from '../../errors/ServerError'
const NAMESPACE = 'COMMON'

export const ACTION_TYPES = {
    SHOW_MESSAGE: `${NAMESPACE}/SHOW_MESSAGE`,
    HIDE_MESSAGE: `${NAMESPACE}/HIDE_MESSAGE`,
    CHANGE_CULTURE: `${NAMESPACE}/CHANGE_CULTURE`,
    SHOW_LOADING: `${NAMESPACE}/SHOW_LOADING`,
    HIDE_LOADING: `${NAMESPACE}/HIDE_LOADING`,
}

export const showMessage = message => ({
    type: ACTION_TYPES.SHOW_MESSAGE,
    payload: message,
})

export const hideMessage = () => ({ type: ACTION_TYPES.HIDE_MESSAGE })

export const changeCulture = culture => ({
    type: ACTION_TYPES.CHANGE_CULTURE,
    payload: culture,
})

export const handleUnauthorized = function*(e, fn) {
    if (e instanceof ServerError && e.response.status == 401) {
        yield put(fn())
        yield put(showMessage(messages.MESSAGE_UNAUTHORIZED))
    } else yield put(showMessage(messages.MESSAGE_ERROR_GENERIC))
}
