import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import Dropzone from 'react-dropzone'
import { useDropzone } from 'react-dropzone'
import { isEmpty, isNil, join, map } from 'lodash'
import couriers from '../../../model/couriers'
import { submitCourier } from '../../../services/couriers/couriers'
import Loading from '../Loading/Loading.jsx'
import Button from '../../atoms/Button/Button.jsx'

import './CourierForm.scss'

const CourierForm = ({ courierId, mode, url, updateList }) => {
    const [isLoading, setLoading] = useState(true)
    const [isSubmitSuccess, setSubmitSuccess] = useState(false)
    const [courier, setCourier] = useState({
        name: '',
        courierId: 0,
        logo: null,
        logoUrl: '',
        validFrom: 0,
        validTo: 0,
    })
    const [errors, setErrors] = useState({})
    const buttonText = {
        create: 'Create',
        edit: 'Save',
    }
    const baseStyle = {
        width: 180,
        height: 180,
        borderWidth: 2,
        borderColor: '#666',
        borderStyle: 'dashed',
        borderRadius: 5,
        padding: 20,
    }
    const activeStyle = {
        borderStyle: 'solid',
        borderColor: '#6c6',
        backgroundColor: '#eee',
    }
    const rejectStyle = {
        borderStyle: 'solid',
        borderColor: '#c66',
        backgroundColor: '#eee',
    }

    const submit = async () => {
        setLoading(true)
        let errors
        try {
            switch (mode) {
                case 'create':
                    errors = await submitCourier(courier, 'create', 'POST')
                    break
                case 'edit':
                    errors = await submitCourier(courier, `${courierId}`, 'PUT')
                    break
                default:
                    break
            }

            setLoading(false)
            if (isEmpty(errors)) {
                setSubmitSuccess(true)
            }

            setErrors(errors)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const fetchCourier = async id => {
        try {
            const courier = await couriers.getCourier(id)
            setCourier(courier)
        } catch (error) {
            console.error(error)
        }
    }

    const updateLogo = acceptedFiles => {
        const file = acceptedFiles[0]
        Object.assign(file, {
            preview: URL.createObjectURL(file),
        })
        setCourier(prevState => ({
            ...prevState,
            logo: file,
        }))
    }

    useEffect(() => {
        console.log(courierId)
        if (!isNil(courierId)) {
            fetchCourier(courierId)
        }
        setLoading(false)
    }, [])

    const showImageErrors = fileRejections => {
        return map(fileRejections, ({ file, errors }, idx) => (
            <p className="error" key={idx}>
                {file.name}:{' '}
                {join(
                    map(errors, e => e.message),
                    '. '
                )}
            </p>
        ))
    }

    const preview = file => (
        <div className="courier-form-preview">
            <div
                className="courier-form-preview-icon"
                onClick={() => {
                    setCourier(prevState => ({
                        ...prevState,
                        logo: null,
                        logoUrl: '',
                    }))
                }}
            >
                <svg height="20" width="20">
                    <path d="M5 5 L13 13" />
                    <path d="M5 13 L13 5" />
                </svg>
            </div>
            <img src={file.preview} alt={file.name} />
            <p className="courier-form-preview-text">{file.name}</p>
        </div>
    )

    const { getRootProps } = useDropzone({
        // Note how this callback is never invoked if drop occurs on the inner dropzone
        onDrop: updateLogo,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
        },
        maxFiles: 1,
    })

    return isLoading ? (
        <Loading />
    ) : (
        <form className="courier-form">
            {!isNil(errors.generic) && !isEmpty(errors.generic) && <p className="error">{errors.generic}</p>}
            <div className="courier-form-row">
                <label className="courier-form-label" htmlFor="name">
                    Courier name:
                    <input
                        type="text"
                        value={courier.name}
                        name="name"
                        onChange={e => {
                            const value = e.target.value
                            setCourier(prevState => ({
                                ...prevState,
                                name: value,
                            }))
                        }}
                    />
                </label>
                {!isNil(errors.name) && !isEmpty(errors.name) && <p className="error">{errors.name}</p>}
            </div>
            <div className="courier-form-row">
                <label className="courier-form-label" htmlFor="courierId">
                    Courier id:
                    <input
                        type="text"
                        value={courier.courierId}
                        name="courierId"
                        onChange={e => {
                            const value = e.target.value
                            setCourier(prevState => ({
                                ...prevState,
                                courierId: value,
                            }))
                        }}
                    />
                </label>
                {!isNil(errors.courierId) && !isEmpty(errors.courierId) && <p className="error">{errors.courierId}</p>}
            </div>
            <div className="courier-form-row">
                {!isNil(courier.logo) || !(isNil(courier.logoUrl) || isEmpty(courier.logoUrl)) ? (
                    <>
                        <p>
                            <strong>Courier logo:</strong>
                        </p>
                        {preview(
                            isNil(courier.logo) ? { name: courier.logoUrl, preview: courier.logoUrl } : courier.logo
                        )}
                    </>
                ) : (
                    <Dropzone>
                        {({ getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections }) => {
                            let styles = { ...baseStyle }
                            styles = isDragActive ? { ...styles, ...activeStyle } : styles
                            styles = isDragReject ? { ...styles, ...rejectStyle } : styles

                            return (
                                <>
                                    <div {...getRootProps()} style={styles}>
                                        <input {...getInputProps()} />
                                        <h3>Upload courier logo!</h3>
                                        <p>JPG or GIF only</p>
                                        <div>{isDragAccept ? 'Drop' : 'Drag'} files here...</div>
                                        {isDragReject && <div>Unsupported file type...</div>}
                                    </div>
                                    {!isEmpty(fileRejections) && showImageErrors(fileRejections)}
                                </>
                            )
                        }}
                    </Dropzone>
                )}
            </div>
            <Button
                text={buttonText[mode]}
                type="button"
                onClick={async () => {
                    submit()
                }}
            />
            <Link to={url}>
                <Button text="Cancel" type="button" />
            </Link>
            <Modal isOpen={isSubmitSuccess} contentLabel="Success modal" className="Modal" overlayClassName="Overlay">
                <p>Operation successful.</p>
                <Link to={url}>
                    <Button text="Back to couriers list" type="button" onClick={updateList} />
                </Link>
            </Modal>
        </form>
    )
}

export default CourierForm
