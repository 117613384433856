import { call, takeLatest, takeEvery, put } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import modal from '../modal/modal.types'

import * as modalActions from '../modal/modal.actions'
import * as actions from './categoryBanner.actions'
import * as commonActions from '../common/common.actions'
import * as userActions from '../user/user.actions'
import { ACTION_TYPES } from './categoryBanner.actions'
import { history } from '../../helpers/history'
import categories from '../../model/categories'
import ServerError from '../../errors/ServerError'
import messages from '../../helpers/messages'
import categoryBanners from '../../model/categoryBanners'

function* fetchCategories(action) {
    try {
        const list = yield call(categories.get, action.payload.culture)
        yield put(actions.categoriesReceived(list))
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* fetchCategoryBanners(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const list = yield call(categoryBanners.get, action.payload.categoryId, action.payload.culture)
        yield put(actions.bannersReceived(list))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* saveEditBanner(action) {
    try {
        const newBanner = action.payload.banner
        const categoryId = action.payload.banner.categoryId
        const culture = action.payload.culture
        yield put(modalActions.showModal(modal.types.LOADING))
        yield call(categoryBanners.put, newBanner)
        yield delay(2000)
        const list = yield call(categoryBanners.get, categoryId, culture)
        yield put(actions.bannersReceived(list))
        yield put(modalActions.hideModal())
        yield put(commonActions.showMessage(messages.getCreatedSuccessMessage('Banner')))
        history.push('/categorybanners')
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else {
            yield put(commonActions.showMessage(e.response))
        }
    }
}

function* saveNewBanner(action) {
    try {
        const newBanner = action.payload.banner
        const categoryId = action.payload.banner.categoryId
        const culture = action.payload.culture
        yield put(modalActions.showModal(modal.types.LOADING))
        yield call(categoryBanners.post, newBanner)
        yield delay(4000)
        const list = yield call(categoryBanners.get, categoryId, culture)
        yield put(actions.bannersReceived(list))
        yield put(modalActions.hideModal())
        yield put(commonActions.showMessage(messages.getCreatedSuccessMessage('Banner')))
        history.push('/categorybanners')
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else {
            yield put(commonActions.showMessage(e.response))
        }
    }
}

function* deleteBanner(action) {
    try {
        const id = action.payload.id
        const categoryId = action.payload.categoryId
        const culture = action.payload.culture
        yield put(modalActions.showModal(modal.types.LOADING))
        yield call(categoryBanners.deleteBanner, id)
        yield delay(2000)
        const list = yield call(categoryBanners.get, categoryId, culture)
        yield put(actions.bannersReceived(list))
        yield put(modalActions.hideModal())
        yield put(commonActions.showMessage(messages.getDeletedSuccessMessage('Banner')))
        history.push('/categorybanners')
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else if (e instanceof TypeError) {
            yield put(commonActions.showMessage(messages.MESSAGE_TYPE_ERROR_FAILEDTOFETCH))
        } else {
            const errorMsg = e.response || commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC)
            yield put(commonActions.showMessage(errorMsg))
        }
    }
}

export default function*() {
    yield takeLatest(ACTION_TYPES.READ_CATEGORIES, fetchCategories)
    yield takeLatest(ACTION_TYPES.READ_BANNERS, fetchCategoryBanners)
    yield takeEvery(ACTION_TYPES.SAVE_EDIT_BANNER_REQUEST, saveEditBanner)
    yield takeEvery(ACTION_TYPES.SAVE_NEW_BANNER_REQUEST, saveNewBanner)
    yield takeEvery(ACTION_TYPES.DELETE_BANNER_REQUEST, deleteBanner)
}
