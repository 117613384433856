const NAMESPACE = 'QUESTIONANDANSWERS'

export const ACTION_TYPES = {
    SEARCH_QUESTIONS: `${NAMESPACE}/SEARCH_QUESTIONS`,
    QUESTIONS_RECEIVED: `${NAMESPACE}/QUESTIONS_RECEIVED`,
    DELETE_QUESTION_REQUEST: `${NAMESPACE}/DELETE_QUESTION_REQUEST`,
    QUESTION_DELETED: `${NAMESPACE}/QUESTION_DELETED`,
    DELETE_ANSWER_REQUEST: `${NAMESPACE}/DELETE_ANSWER_REQUEST`,
    ANSWER_DELETED: `${NAMESPACE}/ANSWER_DELETED`,
    ANSWER_QUESTION_REQUEST: `${NAMESPACE}/ANSWER_QUESTION_REQUEST`,
    QUESTION_ANSWERED: `${NAMESPACE}/QUESTION_ANSWERED`,
    APPROVE_ANSWER_REQUEST: `${NAMESPACE}/APPROVE_ANSWER_REQUEST`,
    ANSWER_APPROVED: `${NAMESPACE}/ANSWER_APPROVED`,
    SWITCH_TO_SEARCH: `${NAMESPACE}/SWITCH_TO_SEARCH`,
    EXIT_SEARCH: `${NAMESPACE}/EXIT_SEARCH`,
    SEARCH_QUESTIONS_REQUEST: `${NAMESPACE}/SEARCH_QUESTIONS_REQUEST`,
}

export const questionsReceived = list => ({
    type: ACTION_TYPES.QUESTIONS_RECEIVED,
    payload: list,
})
export const searchQuestionsAndAnswers = culture => ({
    type: ACTION_TYPES.SEARCH_QUESTIONS,
    payload: culture,
})
export const deleteQuestionRequest = questionId => ({
    type: ACTION_TYPES.DELETE_QUESTION_REQUEST,
    payload: questionId,
})
export const questionDeleted = questionId => ({
    type: ACTION_TYPES.QUESTION_DELETED,
    payload: questionId,
})
export const deleteAnswerRequest = (questionId, answerId) => ({
    type: ACTION_TYPES.DELETE_ANSWER_REQUEST,
    payload: { questionId, answerId },
})
export const answerDeleted = (questionId, answerId) => ({
    type: ACTION_TYPES.ANSWER_DELETED,
    payload: { questionId, answerId },
})
export const answerQuestionRequest = (questionId, answer, culture) => ({
    type: ACTION_TYPES.ANSWER_QUESTION_REQUEST,
    payload: { questionId, answer, culture },
})
export const questionAnswered = (questionId, answer) => ({
    type: ACTION_TYPES.QUESTION_ANSWERED,
    payload: { questionId, answer },
})
export const approveAnswerRequest = (questionId, answerId) => ({
    type: ACTION_TYPES.APPROVE_ANSWER_REQUEST,
    payload: { questionId, answerId },
})
export const answerApproved = (questionId, answerId) => ({
    type: ACTION_TYPES.ANSWER_APPROVED,
    payload: { questionId, answerId },
})
export const switchToSearch = culture => ({
    type: ACTION_TYPES.SWITCH_TO_SEARCH,
    payload: culture,
})
export const exitSearchMode = culture => ({
    type: ACTION_TYPES.EXIT_SEARCH,
    payload: culture,
})
export const searchQuestions = (slug, culture) => ({
    type: ACTION_TYPES.SEARCH_QUESTIONS_REQUEST,
    payload: { slug, culture },
})
