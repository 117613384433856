import { isNil } from 'lodash'
import { ACTION_TYPES } from './currencies.actions'
const INITIAL_STATE = {
    currencies: [],
}

const reducers = {}

reducers[ACTION_TYPES.CURRENCIES_RECEIVED] = (state, action) => {
    const currencies = isNil(action.payload) ? [] : action.payload
    return {
        ...state,
        currencies: currencies,
    }
}

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type] || (() => state)
    return reducer(state, action)
}
