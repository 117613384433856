import React, { useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { concat, filter, includes, map } from 'lodash'
import couriers from '../../model/couriers'
import Loading from '../molecules/Loading/Loading.jsx'
import Header from '../organisms/Header/Header.jsx'
import Button from '../atoms/Button/Button.jsx'
import CouriersList from '../organisms/Couriers/CouriersList.jsx'
import CourierForm from '../molecules/Couriers/CourierForm.jsx'

import './errors.scss'

const CouriersPage = props => {
    const [isLoading, setLoading] = useState(true)
    const [couriersList, setCouriersList] = useState([])
    const [errors, setErrors] = useState([])

    const handleDelete = async courierId => {
        try {
            const isSuccess = await couriers.deleteCourier(courierId)
            if (isSuccess) {
                const newList = filter(couriersList, c => c.dtoGuid !== courierId)
                setCouriersList(newList)
                setErrors([])
            }
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
    }

    const fetchCouriers = async () => {
        try {
            setLoading(true)
            const fetchedCouriers = await couriers.getCouriers()
            setCouriersList(fetchedCouriers)
            setErrors([])
            setLoading(false)
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchCouriers()
    }, [])

    return isLoading ? (
        <Loading />
    ) : (
        <div>
            <Header>
                <div className="title">
                    <h2>Couriers</h2>
                </div>
                <div className="cta">
                    <Button text="Refresh couriers" onClick={fetchCouriers} />
                    <Link to={`${props.match.url}/create`}>
                        <Button text="Create new courier" />
                    </Link>
                </div>
            </Header>
            {errors.length > 0 && (
                <div className="errors-container">
                    {map(errors, (e, idx) => (
                        <p key={idx} className="error">
                            {e}
                        </p>
                    ))}
                </div>
            )}
            <Switch>
                <Route
                    exact
                    path={props.match.url}
                    render={() => (
                        <CouriersList
                            couriers={couriersList}
                            url={props.match.url}
                            handleDelete={handleDelete}
                            updateList={fetchCouriers}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${props.match.url}/create`}
                    render={() => <CourierForm mode="create" url={props.match.url} updateList={fetchCouriers} />}
                />
                <Route
                    exact
                    path={`${props.match.url}/edit/:courierId`}
                    render={routeProps => (
                        <CourierForm
                            courierId={routeProps.match.params.courierId}
                            mode="edit"
                            url={props.match.url}
                            updateList={fetchCouriers}
                        />
                    )}
                />
            </Switch>
        </div>
    )
}

export default CouriersPage
