import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

const authorize = (username, password) => {
    const data = {
        username: username,
        password: password,
    }
    return fetch(`${config.apiRoot}/v1/users/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(r => {
        if (r.ok) {
            return r.json().then(user => {
                return fetch(`${config.apiRoot}/v1/users/role/authorized`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + user.access_token,
                    },
                }).then(r1 => {
                    if (r1.ok) {
                        return r1.text().then(role => {
                            var res = { ...user, role }
                            return res
                        })
                    }
                    throw new ServerError(`User ${username} has an unauthorized role`)
                })
            })
        }
        throw new ServerError('Server error :(')
    })
}

const getUserInfo = userId => {
    return fetch(`${config.apiRoot}/v1/users/${userId}`, { method: 'GET', headers: authHeader() }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError('Server error :(')
    })
}

const searchUsers = searchTerm => {
    return fetch(`${config.apiRoot}/v1/users?searchTerm=${encodeURIComponent(searchTerm)}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError('Server error :(')
    })
}

const lock = userId => {
    return fetch(`${config.apiRoot}/v1/users/${userId}/lock`, {
        method: 'PUT',
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })
}

const unlock = userId => {
    return fetch(`${config.apiRoot}/v1/users/${userId}/unlock`, {
        method: 'PUT',
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })
}

const deleteUser = userId => {
    return fetch(`${config.apiRoot}/v1/users/${userId}`, {
        method: 'DELETE',
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return
        }
        throw new ServerError('Server error :(')
    })
}

export default {
    authorize,
    getUserInfo,
    searchUsers,
    lock,
    unlock,
    deleteUser,
}
