import { call, put, takeLatest } from 'redux-saga/effects'
import { delay } from 'redux-saga'

import * as actions from './returns.actions'
import * as commonActions from '../common/common.actions'
import * as userActions from '../user/user.actions'
import { ACTION_TYPES } from './returns.actions'
import {
    getReturns,
    searchReturns as searchReturnsCall,
    markAsApprovedAndWaitingToBeReceived as markAsApprovedAndWaitingToBeReceivedCall,
    markAsRejected as markAsRejectedCall,
    markAsReceivedAndInProcess as markAsReceivedAndInProcessCall,
    markAsRefunded as markAsRefundedCall,
    getOrderDetails,
} from '../../model/returns'
import * as modalActions from '../modal/modal.actions'
import modal from '../modal/modal.types'
import ServerError from '../../errors/ServerError'
import messages from '../../helpers/messages'

function* _handleError(e) {
    yield put(modalActions.hideModal())
    if (e instanceof ServerError && e.response.status == 401) {
        yield put(userActions.logoutRequest())
        yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
    } else {
        console.error(e)
        yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export function* fetchReturns(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const list = yield call(getReturns, action.payload.culture, action.payload.status)
        yield put(actions.returnsReceived(list))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield _handleError(e)
    }
}
export function* markAsApprovedAndWaitingToBeReceived(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const result = yield call(markAsApprovedAndWaitingToBeReceivedCall, action.payload)
        yield put(actions.setApprovedAndWaitingToBeReceived(action.payload, result))
        yield delay(1000)
        yield put(modalActions.hideModal())
        yield put(commonActions.showMessage(messages.getModifiedSuccessMessage(`Return ${result.number}`)))
    } catch (e) {
        yield _handleError(e)
    }
}
export function* markAsRejected(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const result = yield call(
            markAsRejectedCall,
            action.payload.returnId,
            action.payload.reasonForRejection,
            action.payload.rejectWithAutomaticEmail
        )
        yield put(actions.setRejected(action.payload.returnId, result))
        yield delay(1000)
        yield put(modalActions.hideModal())
        yield put(commonActions.showMessage(messages.getModifiedSuccessMessage(`Return ${result.number}`)))
    } catch (e) {
        yield _handleError(e)
    }
}
export function* markAsReceivedAndInProcess(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const result = yield call(markAsReceivedAndInProcessCall, action.payload)
        yield put(actions.setReceivedAndInProcess(action.payload, result))
        yield delay(1000)
        yield put(modalActions.hideModal())
        yield put(commonActions.showMessage(messages.getModifiedSuccessMessage(`Return ${result.number}`)))
    } catch (e) {
        yield _handleError(e)
    }
}
export function* refund(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const result = yield call(markAsRefundedCall, action.payload)
        yield put(actions.setRefunded(action.payload.returnId, result))
        yield delay(1000)
        yield put(modalActions.hideModal())
        yield put(commonActions.showMessage(messages.getModifiedSuccessMessage(`Return ${result.number}`)))
    } catch (e) {
        yield _handleError(e)
    }
}
export function* fetchOrderDetails(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const result = yield call(getOrderDetails, action.payload.returnId, action.payload.orderId)
        yield put(actions.orderDetailsReceived(action.payload.returnId, result))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield _handleError(e)
    }
}
export function* searchReturns(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const list = yield call(searchReturnsCall, action.payload.culture, action.payload.searchTerm)
        yield put(actions.returnsReceived(list))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield _handleError(e)
    }
}

export default function*() {
    yield takeLatest(ACTION_TYPES.RETURNS_REQUEST, fetchReturns)
    yield takeLatest(ACTION_TYPES.REJECTED_REQUEST, markAsRejected)
    yield takeLatest(ACTION_TYPES.APPROVEDANDWAITINGTOBERECEIVED_REQUEST, markAsApprovedAndWaitingToBeReceived)
    yield takeLatest(ACTION_TYPES.RECEIVEDANDINPROCESS_REQUEST, markAsReceivedAndInProcess)
    yield takeLatest(ACTION_TYPES.REFUNDED_REQUEST, refund)
    yield takeLatest(ACTION_TYPES.ORDERDETAILS_REQUEST, fetchOrderDetails)
    yield takeLatest(ACTION_TYPES.SEARCH_REQUEST, searchReturns)
}
