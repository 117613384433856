import { isNil, map } from 'lodash'
import React from 'react'

import ReturnDossier from '../ReturnDossier/ReturnDossier.jsx'
import '../ReturnsList/ReturnsList.scss'

const ReturnsList = ({
    list,
    currencies,
    culture,
    markAsApprovedAndWaitingToBeReceivedHandler,
    markAsRejectedHandler,
    markAsReceivedAndInProcessHandler,
    markAsRefundedHandler,
    viewOrderDetails,
    hideOrderDetails,
}) => {
    const items = map(list, (returnDossier, idx) =>
        isNil(returnDossier) ? (
            <React.Fragment key={idx}></React.Fragment>
        ) : (
            <li className="returnsList" key={idx}>
                <ReturnDossier
                    culture={culture}
                    currencies={currencies}
                    returnDossier={returnDossier}
                    markAsApprovedAndWaitingToBeReceivedHandler={markAsApprovedAndWaitingToBeReceivedHandler}
                    markAsRejectedHandler={markAsRejectedHandler}
                    markAsReceivedAndInProcessHandler={markAsReceivedAndInProcessHandler}
                    markAsRefundedHandler={markAsRefundedHandler}
                    viewOrderDetails={viewOrderDetails}
                    hideOrderDetails={hideOrderDetails}
                />
            </li>
        )
    )

    return (
        <div>
            <ul>{items}</ul>
        </div>
    )
}

export default ReturnsList
