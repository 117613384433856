import React, { useState } from 'react'
import Button from '../../atoms/Button/Button.jsx'

import './searchfield.scss'

const SearchField = ({ search, minLengthMessage, minLength, onKeyUpHandler, resetFiltersAfterSearch }) => {
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')

    const submitSearch = e => {
        e.preventDefault()
        search(searchTerm)

        if (resetFiltersAfterSearch) {
            resetFiltersAfterSearch()
        }
    }

    const onChangeTextHandler = inputValue => {
        setButtonDisabled(inputValue.length < minLength)
        setSearchTerm(inputValue)

        if (inputValue.length < minLength && onKeyUpHandler) {
            onKeyUpHandler()
        }
    }

    return (
        <div className="searchFieldContainer">
            <small className="searchFieldMinLengthMessage">{minLengthMessage || ''}</small>
            <form
                onSubmit={e => {
                    submitSearch(e)
                }}
            >
                <div className="search">
                    <div className="searchInput">
                        <input
                            className="description"
                            type="text"
                            autoFocus
                            value={searchTerm}
                            onChange={e => {
                                onChangeTextHandler(e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="searchButton">
                    <Button text="Search" disabled={buttonDisabled} />
                </div>
            </form>
        </div>
    )
}

export default SearchField
