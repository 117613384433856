import { call, takeLatest, takeEvery, put } from 'redux-saga/effects'

import * as actions from './reviews.actions'
import { ACTION_TYPES } from './reviews.actions'
import * as commonActions from '../common/common.actions'
import * as userActions from '../user/user.actions'
import * as modalActions from '../modal/modal.actions'
import reviews from '../../model/reviews'
import ServerError from '../../errors/ServerError'
import modal from '../modal/modal.types'
import messages from '../../helpers/messages'

function* fetchReviews(action) {
    try {
        const result = yield call(reviews.get, action.payload)
        yield put(actions.reviewsReceived(result))
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* deleteReview(action) {
    try {
        yield call(reviews.deleteReview, action.payload)
        yield put(actions.reviewDeleted(action.payload))
        yield put(commonActions.showMessage(messages.getDeletedSuccessMessage('Review')))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* answerReview(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const result = yield call(
            reviews.answerReview,
            action.payload.reviewId,
            action.payload.answer,
            action.payload.culture
        )
        yield put(actions.reviewAnswered(action.payload.reviewId, result))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* approveReview(action) {
    try {
        yield call(reviews.approveReview, action.payload)
        yield put(actions.reviewApproved(action.payload))
        yield put(commonActions.showMessage(messages.getApprovedSuccessMessage('Review')))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export default function*() {
    yield takeLatest(ACTION_TYPES.SEARCH_REVIEWS, fetchReviews)
    yield takeEvery(ACTION_TYPES.DELETE_REVIEW_REQUEST, deleteReview)
    yield takeEvery(ACTION_TYPES.ANSWER_REVIEW_REQUEST, answerReview)
    yield takeEvery(ACTION_TYPES.APPROVE_REVIEW_REQUEST, approveReview)
}
