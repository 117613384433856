import { filter, map } from 'lodash'
import { call, takeEvery, takeLatest, put, select } from 'redux-saga/effects'

import * as actions from './destinations.actions'
import * as commonActions from '../common/common.actions'
import * as userActions from '../user/user.actions'
import * as modalActions from '../modal/modal.actions'
import { ACTION_TYPES } from './destinations.actions'
import destinationsNotes from '../../model/destinationsNotes'
import ServerError from '../../errors/ServerError'
import modal from '../modal/modal.types'
import messages from '../../helpers/messages'

function* fetchDestinationsNotes(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const result = yield call(destinationsNotes.get, action.payload)
        yield put(actions.destinationsNotesReceived(result))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* setDestinationNote(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        yield call(destinationsNotes.setDestinationNote, action.payload)
        yield put(actions.destinationsNotesSet(action.payload))
        yield put(commonActions.showMessage(messages.getSetSuccessMessage('Destination note')))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* setDestinationNoteBulk(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const state = yield select()
        var codes = map(
            filter(
                state.destinationsNotes.list,
                destinationNote =>
                    destinationNote.selected === true && action.payload.culture === destinationNote.culture
            ),
            'code'
        )
        yield call(destinationsNotes.setDestinationNoteBulk, {
            codes,
            culture: action.payload.culture,
            note: action.payload.note,
        })
        yield put(
            actions.destinationNoteBulkSet({
                codes,
                culture: action.payload.culture,
                note: action.payload.note,
            })
        )
        yield put(modalActions.hideModal())
        yield put(commonActions.showMessage(messages.getSetSuccessMessage('Bulk destination note')))
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else {
            yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
        }
    }
}

export default function*() {
    yield takeLatest(ACTION_TYPES.SEARCH_DESTINATIONSNOTES, fetchDestinationsNotes)
    yield takeEvery(ACTION_TYPES.CLEAR_DESTINATIONNOTE_REQUEST, setDestinationNote)
    yield takeEvery(ACTION_TYPES.SET_DESTINATIONNOTE_REQUEST, setDestinationNote)
    yield takeEvery(ACTION_TYPES.SET_DESTINATIONNOTE_BULK_REQUEST, setDestinationNoteBulk)
}
