import { call, put, takeLatest } from 'redux-saga/effects'

import * as actions from './currencies.actions'
import * as commonActions from '../common/common.actions'
import * as userActions from '../user/user.actions'
import { ACTION_TYPES } from './currencies.actions'
import { getCurrencies } from '../../model/currencies'
import * as modalActions from '../modal/modal.actions'
import modal from '../modal/modal.types'
import ServerError from '../../errors/ServerError'
import messages from '../../helpers/messages'

function* _handleError(e) {
    yield put(modalActions.hideModal())
    if (e instanceof ServerError && e.response.status == 401) {
        yield put(userActions.logoutRequest())
        yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
    } else {
        console.error(e)
        yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export function* fetchCurrencies(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const currencies = yield call(getCurrencies, action.payload)
        yield put(actions.currenciesReceived(currencies))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield _handleError(e)
    }
}

export default function*() {
    yield takeLatest(ACTION_TYPES.CURRENCIES_REQUEST, fetchCurrencies)
}
