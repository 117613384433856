import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

const getCountries = culture => {
    return fetch(`${config.apiRoot}/v1/Countries/${culture}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })
}

export default {
    getCountries,
}
