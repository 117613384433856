import { ACTION_TYPES } from './reviews.actions'

const INITIAL_STATE = {
    list: [],
}

const reducers = {}

reducers[ACTION_TYPES.REVIEWS_RECEIVED] = (state, action) => ({
    ...state,
    list: [...action.payload],
})

reducers[ACTION_TYPES.REVIEW_DELETED] = (state, action) => ({
    ...state,
    list: state.list.filter(q => q.id !== action.payload),
})

reducers[ACTION_TYPES.REVIEW_ANSWERED] = (state, action) => ({
    ...state,
    list: state.list.filter(q => q.id !== action.payload.reviewId),
})

reducers[ACTION_TYPES.REVIEW_APPROVED] = (state, action) => ({
    ...state,
    list: state.list.filter(q => q.id !== action.payload),
})

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type] || (() => state)
    return reducer(state, action)
}
