import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

const get = culture =>
    fetch(`${config.apiRoot}/v1/QuestionAndAnswers/ToApprove/${culture}`, {
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })

const deleteQuestion = questionId =>
    fetch(`${config.apiRoot}/v1/QuestionAndAnswers/${questionId}`, {
        method: 'DELETE',
        headers: authHeader(),
    }).then(r => {
        if (!r.ok) throw new ServerError(r)
    })

const deleteAnswer = (questionId, answerId) =>
    fetch(`${config.apiRoot}/v1/QuestionAndAnswers/${questionId}/answer/${answerId}`, {
        method: 'DELETE',
        headers: authHeader(),
    }).then(r => {
        if (!r.ok) throw new ServerError(r)
    })

const approveAnswer = (questionId, answerId) =>
    fetch(`${config.apiRoot}/v1/QuestionAndAnswers/${questionId}/answer/${answerId}/approve`, {
        method: 'PUT',
        headers: authHeader(),
    }).then(r => {
        if (!r.ok) throw new ServerError(r)
    })

const answerQuestion = (questionId, answer, culture) =>
    fetch(`${config.apiRoot}/v1/QuestionAndAnswers/${questionId}/answer`, {
        method: 'POST',
        headers: new Headers({
            ...authHeader(),
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            answer: answer,
            culture: culture,
        }),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })

const getBySlug = (slug, culture) => {
    const cleanSlug = slug.split('?')[0]
    return fetch(`${config.apiRoot}/v1/QuestionAndAnswers/${culture}/Product/${encodeURIComponent(cleanSlug)}`, {
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })
}

export default {
    get,
    deleteQuestion,
    deleteAnswer,
    approveAnswer,
    answerQuestion,
    getBySlug,
}
