import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

const getZones = () => {
    return fetch(`${config.apiRoot}/v1/zones`, {
        method: 'GET',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return r.json()
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

const getZone = zoneId => {
    return fetch(`${config.apiRoot}/v1/zones/${zoneId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return r.json()
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

const submit = (zone, endpoint, method) => {
    return fetch(`${config.apiRoot}/v1/zones/${endpoint}`, {
        method: method,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(zone),
    }).then(async r => {
        if (r.ok) {
            return {}
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            return resp
        }
        throw new ServerError(r)
    })
}

const deleteZone = zoneId => {
    return fetch(`${config.apiRoot}/v1/zones/${zoneId}`, {
        method: 'DELETE',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return true
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

const getCountriesToExclude = params => {
    return fetch(`${config.apiRoot}/v1/zones/countries/${params}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return r.json()
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

export default { getZones, getZone, submit, deleteZone, getCountriesToExclude }
