import React from 'react'

import './buttonMenu.scss'

const ButtonMenu = ({ onClick, text, type, disabled }) => (
    <button onClick={onClick} type={type} disabled={disabled}>
        {text}
    </button>
)

export default ButtonMenu
