import { ACTION_TYPES } from './reassortmentNotifications.actions'
import _ from 'lodash'

const INITIAL_STATE = {}

const reducers = {}

reducers[ACTION_TYPES.REASSORTMENTNOTIFICATIONS_RECEIVED] = (state, action) => {
    return {
        ...state,
        reassortmentNotification: action.payload,
    }
}

reducers[ACTION_TYPES.REASSORTMENTNOTIFICATIONS_DELETED] = (state, action) => {
    const newReassortmentNotificationLines = _.reject(
        state.reassortmentNotification.lines,
        l => l.id === action.payload.lineId
    )
    state.reassortmentNotification.lines = newReassortmentNotificationLines
    return {
        ...state,
        reassortmentNotification: state.reassortmentNotification,
    }
}

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type] || (() => state)
    return reducer(state, action)
}
