import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import * as actions from '../../redux/user/user.actions'
import Login from '../organisms/Login/Login.jsx'

const LoginPage = props => {
    if (props.loggedIn) return <Redirect to="/" />

    return <Login login={props.login} />
}

const mapStateToProps = state => ({
    loggedIn: state.users.loggedIn,
})

const mapDispatchToProps = dispatch => ({
    login: (username, password) => dispatch(actions.loginRequest(username, password)),
    logout: () => dispatch(actions.logoutRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
