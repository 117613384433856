import { ACTION_TYPES } from './modal.actions'

const INITIAL_STATE = null

const reducers = {}

reducers[ACTION_TYPES.MODAL_OPEN] = (state, action) => {
    return {
        ...state,
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
    }
}

reducers[ACTION_TYPES.MODAL_CLOSE] = () => {
    return null
}

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type] || (() => state)
    return reducer(state, action)
}
