const NAMESPACE = 'CURRENCIES'

export const ACTION_TYPES = {
    CURRENCIES_REQUEST: `${NAMESPACE}/CURRENCIES_REQUEST`,
    CURRENCIES_RECEIVED: `${NAMESPACE}/CURRENCIES_RECEIVED`,
}

export const getCurrencies = culture => ({
    type: ACTION_TYPES.CURRENCIES_REQUEST,
    payload: culture,
})
export const currenciesReceived = list => ({
    type: ACTION_TYPES.CURRENCIES_RECEIVED,
    payload: list,
})
