import React from 'react'
import { connect } from 'react-redux'

import * as actions from '../../redux/destinations/destinations.actions'
import * as modalActions from '../../redux/modal/modal.actions'
import modal from '../../redux/modal/modal.types'
import DestinationNotesList from '../organisms/DestinationList/DestinationNotesList.jsx'
import Header from '../organisms/Header/Header.jsx'
import BulkUpdate from '../organisms/BulkUpdate/BulkUpdate.jsx'

class DestinationsPage extends React.Component {
    componentDidMount() {
        this.props.searchDestinationsNotes(this.props.culture)
    }

    componentDidUpdate(prevProps) {
        if (this.props.culture !== prevProps.culture) {
            this.props.searchDestinationsNotes(this.props.culture)
        }
    }

    render() {
        return (
            <div>
                <Header>
                    <div className="title">
                        <h2>Destinations ({this.props.list.length})</h2>
                    </div>
                </Header>
                <BulkUpdate
                    culture={this.props.culture}
                    selectItemsBulk={this.props.selectDestinationNoteBulk}
                    setDestinationNoteBulk={this.props.setDestinationNoteBulk}
                />
                <DestinationNotesList
                    list={this.props.list}
                    culture={this.props.culture}
                    clearDestinationNote={this.props.clearDestinationNote}
                    setDestinationNote={this.props.setDestinationNote}
                    changeDestinationNote={this.props.changeDestinationNote}
                    selectDestinationNote={this.props.selectDestinationNote}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ...state.destinationsNotes,
    culture: state.common.culture,
})

const mapDispatchToProps = dispatch => ({
    searchDestinationsNotes: culture => dispatch(actions.searchDestinationsNotes(culture)),
    clearDestinationNote: (code, culture) => dispatch(actions.clearDestinationNote(code, culture)),
    setDestinationNote: (code, culture, note) => dispatch(actions.setDestinationNote(code, culture, note)),
    changeDestinationNote: (code, culture, note) => dispatch(actions.changeDestinationNote(code, culture, note)),
    selectDestinationNote: (code, culture, selected) =>
        dispatch(actions.selectDestinationNote(code, culture, selected)),
    selectDestinationNoteBulk: (culture, selected) => dispatch(actions.selectDestinationNoteBulk(culture, selected)),
    setDestinationNoteBulk: (culture, note) =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.setDestinationNoteBulk(culture, note)),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
})

export default connect(mapStateToProps, mapDispatchToProps)(DestinationsPage)
