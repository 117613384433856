import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'

import * as modalActions from '../../../redux/modal/modal.actions'
import Button from '../../atoms/Button/Button.jsx'

const ModalUser = ({ user, closeHandler }) => {
    return (
        <Modal isOpen={true} contentLabel="User modal" className="Modal" overlayClassName="Overlay">
            <h2>
                {user.firstName} {user.lastName}
            </h2>
            <ul>
                <li>
                    Mail:{' '}
                    <a href={'mailto:' + user.userName} target="_top">
                        {user.userName}
                    </a>
                </li>
                <li>Nickname: {user.nickname}</li>
            </ul>
            <Button onClick={closeHandler} text="Close" />
        </Modal>
    )
}

const mapDispatchToProps = dispatch => ({
    closeHandler: () => dispatch(modalActions.hideModal()),
})

export default connect(null, mapDispatchToProps)(ModalUser)
