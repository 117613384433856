import React, { useState } from 'react'
import { map, reduce } from 'lodash'
import { returnStatuses, returnStatusesTranslations } from '../../../model/returns'
import Button from '../../atoms/Button/Button.jsx'
import SearchField from '../SearchField/SearchField.jsx'
import './ReturnFilters.scss'

const ReturnFilters = ({ filterByStatusHandler, search, minLengthMessage, minLength, onKeyUpHandler }) => {
    const [currentFilter, setCurrentFilter] = useState(returnStatuses.toBeDealtWith)

    const filters = reduce(
        returnStatuses,
        (acc, statusValue, status) => {
            const statusTranslation = returnStatusesTranslations[status]
            acc.push({
                status,
                statusValue,
                statusTranslation,
            })
            return acc
        },
        []
    )

    const resetFiltersAfterSearch = () => {
        setCurrentFilter('')
    }

    const onKeyUp = () => {
        onKeyUpHandler(returnStatuses.toBeDealtWith)
        setCurrentFilter(returnStatuses.toBeDealtWith)
    }

    return (
        <>
            <div className="returnFiltersContainer">
                <h3>Current filter: {currentFilter}</h3>
                <h3>Filter by:</h3>
                <div key={ReturnFilters.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {map(filters, filter => (
                        <Button
                            key={filter.statusValue}
                            className="returnFilters"
                            onClick={() => {
                                setCurrentFilter(filter.statusTranslation)
                                filterByStatusHandler(filter.statusValue)
                            }}
                            text={filter.statusTranslation}
                        />
                    ))}
                </div>
            </div>
            <SearchField
                search={search}
                minLengthMessage={minLengthMessage}
                minLength={minLength}
                onKeyUpHandler={onKeyUp}
                resetFiltersAfterSearch={resetFiltersAfterSearch}
            />
        </>
    )
}

export default ReturnFilters
