import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

const get = culture =>
    fetch(`${config.apiRoot}/v1/Destinations/${culture}/notes`, {
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })

const _setDestinationNote = (codes = [], culture, note) =>
    fetch(`${config.apiRoot}/v1/destinations/${culture}/notes`, {
        method: 'PUT',
        headers: new Headers({
            ...authHeader(),
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            note,
            codes,
        }),
    }).then(r => {
        if (!r.ok) {
            throw new ServerError(r)
        }
    })

const setDestinationNote = ({ code, culture, note }) => _setDestinationNote([code], culture, note)

const setDestinationNoteBulk = ({ codes, culture, note }) => _setDestinationNote(codes, culture, note)

export default {
    get,
    setDestinationNote,
    setDestinationNoteBulk,
}
