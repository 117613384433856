import { isNaN, isNil } from 'lodash'
import couriers from '../../model/couriers'

const validateCourier = courier => {
    let isCourierValid = true
    const errors = {}

    if (isNil(courier.name) || courier.name === '') {
        isCourierValid = false
        errors.name = 'Please enter a valid name.'
    }
    if (isNil(courier.courierId) || courier.courierId === '' || isNaN(Number(courier.courierId))) {
        isCourierValid = false
        errors.courierId = 'Please enter a valid number.'
    }

    return [isCourierValid, errors]
}

const submitCourier = async (courier, endpoint, method) => {
    const [isValid, errors] = validateCourier(courier)
    if (isValid) {
        courier.courierId = Number(courier.courierId)
        return couriers.submit(courier, endpoint, method)
    }

    return errors
}

export { submitCourier }
