import { ACTION_TYPES } from './infobox.actions'

const INITIAL_STATE = {
    list: [],
    editMode: {},
}

const reducers = {}

reducers[ACTION_TYPES.INFOBOXES_RECEIVED] = (state, action) => {
    const infoboxes = action.payload === undefined ? [] : action.payload
    return {
        ...state,
        list: infoboxes,
        editMode: infoboxes.reduce((r, b) => {
            r[b.id] = false
            return r
        }, {}),
    }
}

reducers[ACTION_TYPES.EDIT_INFOBOX] = (state, action) => ({
    ...state,
    editMode: { ...state.editMode, [action.payload]: true },
})

reducers[ACTION_TYPES.CANCEL_EDIT_INFOBOX] = (state, action) => ({
    ...state,
    editMode: { ...state.editMode, [action.payload]: false },
})

reducers[ACTION_TYPES.SAVE_EDIT_INFOBOX] = (state, action) => {
    const infoboxes = state.list.map(i => {
        return i.id === action.payload.infobox.id ? action.payload.infobox : i
    })

    return {
        ...state,
        list: infoboxes,
    }
}

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type] || (() => state)
    return reducer(state, action)
}
