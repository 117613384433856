import React from 'react'
import { connect } from 'react-redux'

import modal from '../../../redux/modal/modal.types'

export class ModalManager extends React.Component {
    render() {
        const { currentModal } = this.props

        let renderedModal

        if (currentModal) {
            const { modalType, modalProps = {} } = currentModal
            const ModalComponent = modal.componentLookupTable[modalType]

            renderedModal = <ModalComponent {...modalProps} />
        }

        return <span>{renderedModal}</span>
    }
}

const mapStateToProps = state => ({ currentModal: state.modals })

export default connect(mapStateToProps)(ModalManager)
