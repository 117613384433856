import React, { useState } from 'react'
import Modal from 'react-modal'
import { Link } from 'react-router-dom'
import Button from '../../atoms/Button/Button.jsx'

const Courier = ({ courier, url, handleDelete, updateList }) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    return (
        <>
            <div className="courier-logo-container">
                {courier.logoUrl !== '' && <img src={courier.logoUrl} alt={courier.name} />}
            </div>
            <div>{courier.name}</div>
            <div className="couriers-cta">
                <Link to={`${url}/edit/${courier.dtoGuid}`}>
                    <Button text="Edit" />
                </Link>
                <Button
                    onClick={() => {
                        setShowConfirmationModal(true)
                    }}
                    text="Delete"
                />
            </div>
            <Modal
                isOpen={showConfirmationModal}
                onRequestClose={() => {
                    setShowConfirmationModal(false)
                }}
                closeTimeoutMS={500}
                shouldFocusAfterRender={false}
                className={{
                    base: 'courier-modal',
                    afterOpen: 'courier-modal-afterOpen',
                    beforeClose: 'courier-modal-beforeClose',
                }}
                overlayClassName={{
                    base: 'courier-modal-overlay',
                    afterOpen: 'courier-modal-overlay-afterOpen',
                    beforeClose: 'courier-modal-overlay-beforeClose',
                }}
            >
                <h3>Are you sure?</h3>
                <p>You are deleting the courier {courier.name}.</p>
                <p>This operation cannot be undone.</p>
                <Button
                    onClick={() => {
                        setShowConfirmationModal(false)
                    }}
                    text="Cancel"
                />
                <Button
                    onClick={() => {
                        handleDelete(courier.dtoGuid)
                        updateList()
                        setShowConfirmationModal(false)
                    }}
                    text="Confirm"
                />
            </Modal>
        </>
    )
}

export default Courier
