import _ from 'lodash'

const userRoles = {
    utente: {
        description: 'utente',
        position: 4,
    },
    gestoreLocale: { description: 'gestoreLocale', position: 3 },
    gestoreGlobale: { description: 'gestoreGlobale', position: 2 },
    administrator: { description: 'administrator', position: 1 },
}
const isValidRole = (role, minimunAuthorizedRole) => {
    if (role === null || role === undefined || role === '') return false

    const roleCamelized = _.camelCase(role)
    if (!userRoles[roleCamelized]) {
        return false
    }

    const minExpectedRolePositionAvailable = userRoles[_.camelCase(minimunAuthorizedRole.description)].position

    for (const userRole in userRoles) {
        if (Object.hasOwnProperty.call(userRoles, userRole)) {
            const element = userRoles[userRole]
            const elementDescription = element.description
            const roleNameExistsAndMatch = elementDescription.toUpperCase() === role.toUpperCase()

            const requestedRolePositizion = userRoles[roleCamelized].position
            const rolePositionIsAuthorized = requestedRolePositizion <= minExpectedRolePositionAvailable
            if (roleNameExistsAndMatch && rolePositionIsAuthorized) {
                return true
            }
        }
    }
    return false
}
export default { userRoles, isValidRole }
