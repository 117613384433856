const NAMESPACE = 'DESTINATIONS'

export const ACTION_TYPES = {
    SEARCH_DESTINATIONSNOTES: `${NAMESPACE}/SEARCH_DESTINATIONSNOTES`,
    DESTINATIONSNOTES_RECEIVED: `${NAMESPACE}/DESTINATIONSNOTES_RECEIVED`,
    CLEAR_DESTINATIONNOTE_REQUEST: `${NAMESPACE}/CLEAR_DESTINATIONNOTE_REQUEST`,
    SET_DESTINATIONNOTE_REQUEST: `${NAMESPACE}/SET_DESTINATIONNOTE_REQUEST`,
    DESTINATIONNOTE_SET: `${NAMESPACE}/DESTINATIONNOTE_SET`,
    DESTINATIONNOTE_CHANGE: `${NAMESPACE}/DESTINATIONNOTE_CHANGE`,
    DESTINATIONNOTE_CHANGED: `${NAMESPACE}/DESTINATIONNOTE_CHANGED`,
    SELECT_DESTINATIONNOTE: `${NAMESPACE}/SELECT_DESTINATIONNOTE`,
    DESTINATIONNOTE_BULK_SELECTED: `${NAMESPACE}/DESTINATIONNOTE_BULK_SELECTED`,
    SET_DESTINATIONNOTE_BULK_REQUEST: `${NAMESPACE}/SET_DESTINATIONNOTE_BULK_REQUEST`,
    DESTINATIONNOTE_BULK_SET: `${NAMESPACE}/DESTINATIONNOTE_BULK_SET`,
}

export const searchDestinationsNotes = culture => ({
    type: ACTION_TYPES.SEARCH_DESTINATIONSNOTES,
    payload: culture,
})

export const destinationsNotesReceived = list => ({
    type: ACTION_TYPES.DESTINATIONSNOTES_RECEIVED,
    payload: list,
})

export const clearDestinationNote = (code, culture) => ({
    type: ACTION_TYPES.CLEAR_DESTINATIONNOTE_REQUEST,
    payload: { code, culture, note: '' },
})

export const setDestinationNote = (code, culture, note) => ({
    type: ACTION_TYPES.SET_DESTINATIONNOTE_REQUEST,
    payload: { code, culture, note },
})

export const destinationsNotesSet = ({ code, culture, note }) => ({
    type: ACTION_TYPES.DESTINATIONNOTE_SET,
    payload: { code, culture, note },
})

export const changeDestinationNote = (code, culture, note) => ({
    type: ACTION_TYPES.DESTINATIONNOTE_CHANGE,
    payload: { code, culture, note },
})

export const selectDestinationNote = (code, culture, selected) => ({
    type: ACTION_TYPES.SELECT_DESTINATIONNOTE,
    payload: { code, culture, selected },
})

export const selectDestinationNoteBulk = (culture, selected) => ({
    type: ACTION_TYPES.DESTINATIONNOTE_BULK_SELECTED,
    payload: { culture, selected },
})

export const setDestinationNoteBulk = (culture, note) => ({
    type: ACTION_TYPES.SET_DESTINATIONNOTE_BULK_REQUEST,
    payload: { culture, note },
})

export const destinationNoteBulkSet = ({ codes, culture, note }) => ({
    type: ACTION_TYPES.DESTINATIONNOTE_BULK_SET,
    payload: { codes, culture, note },
})
