const NAMESPACE = 'MODAL'

export const ACTION_TYPES = {
    MODAL_CLOSE: `${NAMESPACE}/MODAL_CLOSE`,
    MODAL_OPEN: `${NAMESPACE}/MODAL_OPEN`,
}

export const showModal = (modalType, modalProps) => ({
    type: ACTION_TYPES.MODAL_OPEN,
    payload: { modalType, modalProps },
})

export const hideModal = () => ({ type: ACTION_TYPES.MODAL_CLOSE })
