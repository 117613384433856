import React from 'react'
import Button from '../../atoms/Button/Button.jsx'
import '../Destinations/DestinationNote.scss'

const DestinationNote = ({
    culture,
    destination,
    clearDestinationNoteHandler,
    setDestinationNoteHandler,
    changeDestinationNoteHandler,
    selectDestinationNoteHandler,
}) => {
    return (
        <div className="destinationNote">
            <div className="checkboxContainer checkbox">
                <input
                    type="checkbox"
                    id={destination.code}
                    checked={destination.selected}
                    onChange={() => selectDestinationNoteHandler(destination.code, culture, !destination.selected)}
                />
                <label htmlFor={destination.code} />
            </div>
            <div className="label">
                <label>
                    {destination.code} - {destination.name}
                </label>
            </div>
            <input
                className="input"
                type="text"
                value={destination.note}
                onChange={e => changeDestinationNoteHandler(destination.code, culture, e.target.value)}
            />
            <div className="actions">
                <Button text="Clear" onClick={() => clearDestinationNoteHandler(destination.code, culture)} />
                <Button
                    text="Set"
                    onClick={() => setDestinationNoteHandler(destination.code, culture, destination.note)}
                />
            </div>
        </div>
    )
}

export default DestinationNote
