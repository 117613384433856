import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

export const getCurrencies = culture =>
    fetch(`${config.apiRoot}/v1/currencies/${culture}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError('Server error :(')
    })

export default { getCurrencies }
