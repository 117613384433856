import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

const get = culture =>
    fetch(`${config.apiRoot}/v1/Reviews/ToApprove/${culture}`, {
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })

const deleteReview = reviewId =>
    fetch(`${config.apiRoot}/v1/Reviews/${reviewId}`, {
        method: 'DELETE',
        headers: authHeader(),
    }).then(r => {
        if (!r.ok) throw new ServerError(r)
    })

const answerReview = (reviewId, answer, culture) =>
    fetch(`${config.apiRoot}/v1/Reviews/${reviewId}/answer`, {
        method: 'POST',
        headers: new Headers({
            ...authHeader(),
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            answer: answer,
            culture: culture,
        }),
    }).then(r => {
        if (!r.ok) throw new ServerError(r)
    })

const approveReview = reviewId =>
    fetch(`${config.apiRoot}/v1/Reviews/${reviewId}/approve`, {
        method: 'PUT',
        headers: new Headers({
            ...authHeader(),
            'Content-Type': 'application/json',
        }),
    }).then(r => {
        if (!r.ok) throw new ServerError(r)
    })
export default {
    get,
    deleteReview,
    answerReview,
    approveReview,
}
