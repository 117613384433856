import React from 'react'
import Modal from 'react-modal'

import Button from '../../atoms/Button/Button.jsx'

const Confirm = ({ confirmHandler, cancelHandler }) => {
    return (
        <Modal isOpen={true} contentLabel="Confirmation modal" className="Modal" overlayClassName="Overlay">
            <h2>Are you sure?</h2>
            <Button onClick={confirmHandler} text="Confirm" />
            <Button onClick={cancelHandler} text="Cancel" />
        </Modal>
    )
}

export default Confirm
