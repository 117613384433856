import React, { useEffect, useState } from 'react'
import { concat, filter, find, includes, isNil, map, some, sortBy } from 'lodash'
import countries from '../../model/countries'
import couriersCatalog from '../../model/couriersCatalog'
import shippingServices from '../../model/shippingServices'
import Button from '../atoms/Button/Button.jsx'
import Loading from '../molecules/Loading/Loading.jsx'
import Header from '../organisms/Header/Header.jsx'

import './CouriersPriceFinderPage.scss'
import './errors.scss'

const CouriersPriceFinderPage = () => {
    const [isLoading, setLoading] = useState(true)
    const [catalogs, setCatalogs] = useState([])
    const [countriesList, setCountriesList] = useState([])
    const [courierServices, setCourierServices] = useState([])
    const [selection, setSelection] = useState({
        country: '',
        sortBy: 'priceAscending',
    })
    const [errors, setErrors] = useState([])

    const fetchCountries = async () => {
        try {
            const fetchedCountries = await countries.getCountries('en-US')
            setCountriesList(fetchedCountries)
            setErrors([])
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
    }

    const fetchCourierServices = async () => {
        try {
            const services = await shippingServices.getShippingServices()
            setCourierServices(services)
            setErrors([])
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
        }
    }

    const fetchCatalogs = async () => {
        try {
            setLoading(true)
            const fetchedCatalogs = await couriersCatalog.getCatalogs()
            setCatalogs(fetchedCatalogs)
            setErrors([])
            setLoading(false)
        } catch (error) {
            if (!includes(errors, error.response)) {
                const newErrors = concat(errors, error.response)
                setErrors(newErrors)
            }
            setLoading(false)
        }
    }

    const getCourierServiceName = (country, services) => {
        const courierService = find(services, s => s.country === country)
        if (!isNil(courierService)) {
            const id = courierService.serviceId
            const service = find(courierServices, s => s.courierServiceId === id)

            if (!isNil(service)) {
                const name = find(service.courierServiceNames, n => n.culture === 'it-IT')

                if (!isNil(name)) {
                    return name.name
                }
            }
        }

        return ''
    }

    // const sortCatalogs = (catalogs, method) => {
    //     console.log(catalogs)
    //     let sortedCatalogs = catalogs
    //     switch (method) {
    //         case "priceAscending":
    //             sortedCatalogs = sortBy(catalogs, s => {
    //                 const sortedItems = sortBy(s.catalogItems, i => i.price)
    //                 console.log(sortedItems)
    //                 return sortedItems[0].price
    //             })
    //             break
    //         case "priceDescending":
    //             sortedCatalogs = sortBy(catalogs, s => {
    //                 const sortedItems = sortBy(s.catalogItems, i => i.price)
    //                 console.log(sortedItems)
    //                 return sortedItems[sortedItems.length - 1].price
    //             })
    //             break
    //         case "weightAscending":
    //             break
    //         default:
    //             break
    //     }
    //     console.log(sortedCatalogs)
    //     return sortedCatalogs
    // }

    useEffect(() => {
        fetchCatalogs()
        fetchCountries()
        fetchCourierServices()
    }, [])

    return isLoading ? (
        <Loading />
    ) : (
        <div>
            <Header>
                <div className="title">
                    <h2>Couriers price finder</h2>
                </div>
            </Header>
            {errors.length > 0 && (
                <div className="errors-container">
                    {map(errors, (e, idx) => (
                        <p key={idx} className="error">
                            {e}
                        </p>
                    ))}
                </div>
            )}
            <div className="priceFinder">
                <div className="priceFinder-filters">
                    <label htmlFor="country">
                        Country:
                        <select
                            name="country"
                            value={selection.country}
                            onChange={e => {
                                const country = e.target.value
                                setSelection(prevState => ({
                                    ...prevState,
                                    country: country,
                                }))
                            }}
                        >
                            <option value="">-- select --</option>
                            {map(
                                sortBy(countriesList, item => item.name),
                                (s, idx) => (
                                    <option key={idx} value={s.code}>
                                        {s.name}
                                    </option>
                                )
                            )}
                        </select>
                    </label>
                    {/* <label htmlFor="sort">
                        Sort by:
                        <select
                            name="sort"
                            value={selection.sortBy}
                            onChange={e => {
                                const sort = e.target.value
                                setSelection(prevState => ({
                                    ...prevState,
                                    sortBy: sort
                                }))
                            }}
                        >
                            <option value="priceAscending">Price ascending</option>
                            <option value="priceDescending">Price descending</option>
                            <option value="weightAscending">Weight</option>
                        </select>
                    </label> */}
                </div>
                <div className="priceFinder-buttons">
                    <Button
                        text="Print results"
                        type="button"
                        onClick={() => {
                            window.print()
                        }}
                    />
                </div>
                <div className="priceFinder-catalogs">
                    {selection.country !== '' ? (
                        <h2>Catalogs found for {find(countriesList, s => s.code === selection.country).name}:</h2>
                    ) : (
                        <h2>All catalogs:</h2>
                    )}
                    {map(
                        // sortCatalogs(
                        selection.country === ''
                            ? catalogs
                            : filter(catalogs, s => some(s.courierServices, x => x.country === selection.country)),
                        //     selection.sortBy
                        // ),
                        (catalog, idx) => (
                            <div className="priceFinder-catalog" key={idx}>
                                <h3>
                                    {catalog.name} - {catalog.zone.courier.name}
                                </h3>
                                {selection.country !== '' && (
                                    <p>
                                        Courier service:{' '}
                                        {getCourierServiceName(selection.country, catalog.courierServices)}
                                    </p>
                                )}
                                <table cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>From [kg]</th>
                                            <th>To [kg]</th>
                                            <th>Price [EUR without VAT]</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(catalog.catalogItems, (i, idx) => (
                                            <tr key={idx}>
                                                <td>{i.fromWeight}</td>
                                                <td>{i.toWeight}</td>
                                                <td>{i.price}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {catalog.volumeExtraCharge && (
                                    <p>Extra charge for voluminous parcels: {catalog.volumeExtraChargeAmount}</p>
                                )}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

export default CouriersPriceFinderPage
