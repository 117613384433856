import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'

import ModalManager from './organisms/ModalManager/ModalManager.jsx'
import LoginPage from './pages/LoginPage.jsx'
import MainPage from './pages/MainPage.jsx'

class App extends React.Component {
    render() {
        return (
            <div>
                <ModalManager />
                <Switch>
                    <Route path="/login" component={LoginPage} />
                    <Route
                        render={() =>
                            this.props.loggedIn ? <MainPage role={this.props.role} /> : <Redirect to="/login" />
                        }
                    />
                </Switch>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        messageVisible: state.common.messageVisible,
        loggedIn: state.users.loggedIn,
        role: state.users.user.role,
    }
}

export default withRouter(connect(mapStateToProps)(App))
