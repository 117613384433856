import storage from '../model/storage'

export const authHeader = () => {
    const token = storage.getToken()
    if (token) {
        return { Authorization: 'Bearer ' + token }
    } else {
        return {}
    }
}
