import { filter, findIndex, forEach, isEmpty, isNil, map, reduce, sortBy } from 'lodash'
import couriersCatalog from '../../model/couriersCatalog'

const updateItems = ({ items, newItem, index }) => {
    if (index >= items.length) {
        return addNewItem({ items, newItem })
    } else {
        return editItem({ items, newItem, index })
    }
}

const addNewItem = ({ items, newItem }) => {
    return { items: [...items, newItem], errors: {} }
}

const editItem = ({ items, newItem, index }) => {
    const newItems = map(items, (i, idx) =>
        idx === index
            ? {
                  fromWeight: newItem.fromWeight,
                  toWeight: newItem.toWeight,
                  price: newItem.price,
                  volumetricWeight: newItem.volumetricWeight,
              }
            : {
                  fromWeight: i.fromWeight,
                  toWeight: i.toWeight,
                  price: i.price,
                  volumetricWeight: i.volumetricWeight,
              }
    )
    return { items: newItems, errors: {} }
}

const deleteItem = ({ items, index }) => {
    const result = filter(items, (i, idx) => idx !== index)

    return { items: result, errors: {} }
}

const buildNewItem = ({ items, newValues, index }) => {
    let newItem = {}
    if (index >= items.length) {
        newItem[newValues.key] = newValues.value
        return newItem
    }
    newItem = items[index]
    newItem[newValues.key] = newValues.value
    return newItem
}

const validateItem = item => {
    let isItemValid = true
    const errors = {}

    if (isNil(item.fromWeight) || isNaN(Number(item.fromWeight)) || Number(item.fromWeight) < 0) {
        isItemValid = false
        errors.fromWeight = 'FromWeight is not a valid number. Use a dot for decimal.'
    }

    if (isNil(item.toWeight) || isNaN(Number(item.toWeight)) || Number(item.toWeight) < 0) {
        isItemValid = false
        errors.toWeight = 'ToWeight is not a valid number. Use a dot for decimal.'
    }

    if (isNil(item.price) || isNaN(Number(item.price)) || item.price < 0) {
        isItemValid = false
        errors.price = 'Price is not a valid number. Use a dot for decimal.'
    }

    if (Number(item.fromWeight) > Number(item.toWeight)) {
        isItemValid = false
        errors.generic = 'FromWeight cannot be higher than ToWeight.'
    }

    return [isItemValid, errors]
}

const checkForGapsInItems = items => {
    let noGapsPresent = true
    const gapsDetected = []
    const sortedItems = sortBy(items, i => Number(i.fromWeight))
    reduce(
        sortedItems,
        (prev, curr) => {
            if (Number(prev.toWeight) !== Number(curr.fromWeight)) {
                noGapsPresent = false
                gapsDetected.push({
                    from: Number(curr.fromWeight),
                    to: Number(prev.toWeight),
                    idxFrom: undefined,
                    idxTo: undefined,
                })
            }
            return curr
        },
        { toWeight: Number(sortedItems[0].fromWeight) }
    )

    forEach(gapsDetected, g => {
        const toIndex = findIndex(items, i => Number(i.toWeight) === Number(g.to))
        const fromIndex = findIndex(items, i => Number(i.fromWeight) === Number(g.from))
        g.idxTo = toIndex
        g.idxFrom = fromIndex
    })

    return [
        noGapsPresent,
        noGapsPresent ? {} : { generic: 'Make sure there are no gaps or overlaps in item lines.' },
        gapsDetected,
    ]
}

const validateItems = items => {
    if (isNil(items) || items.length < 1) return [false, { generic: 'Items cannot have 0 elements.' }]

    let areItemsValid = true
    const itemsErrors = map(items, item => {
        const [validItem, itemError] = validateItem(item)
        areItemsValid = areItemsValid && validItem
        return itemError
    })

    const [noGaps, gapsError, gapsDetected] = checkForGapsInItems(items)

    forEach(gapsDetected, g => {
        if (isNil(itemsErrors[g.idxTo])) {
            itemsErrors[g.idxTo] = {
                gapTo: `Gap or overlap detected with fromWeight of line ${g.idxFrom + 1}`,
            }
        } else {
            itemsErrors[g.idxTo].gapTo = `Gap or overlap detected with fromWeight of line ${g.idxFrom + 1}`
        }

        if (isNil(itemsErrors[g.idxFrom])) {
            itemsErrors[g.idxFrom] = {
                gapTo: `Gap or overlap detected with toWeight of line ${g.idxTo + 1}`,
            }
        } else {
            itemsErrors[g.idxFrom].gapFrom = `Gap or overlap detected with toWeight of line ${g.idxTo + 1}`
        }
    })

    return [areItemsValid && noGaps, { ...gapsError, items: itemsErrors }]
}

const validateCatalog = catalog => {
    let isCatalogValid = true
    const errors = {}

    if (isNil(catalog.name) || catalog.name === '') {
        isCatalogValid = false
        errors.name = 'Name cannot be empty.'
    }

    if (isNil(catalog.zone) || isEmpty(catalog.zone)) {
        isCatalogValid = false
        errors.zone = 'Please select a zone.'
    }

    if (isNil(catalog.courierServices) || isEmpty(catalog.courierServices)) {
        isCatalogValid = false
        errors.courierServices = 'Please select at least a courier service.'
    }
    if (
        catalog.volumeExtraCharge &&
        (isNil(catalog.volumeExtraChargeAmount) ||
            isNaN(Number(catalog.volumeExtraChargeAmount)) ||
            catalog.volumeExtraChargeAmount <= 0)
    ) {
        isCatalogValid = false
        errors.volumeExtraChargeAmount = 'Invalid amount. Make sure you use a decimal dot (e.g. 9.99 instead of 9,99).'
    }

    const [itemsValid, itemsErrors] = validateItems(catalog.catalogItems)
    isCatalogValid = isCatalogValid && itemsValid
    errors.items = itemsErrors
    return [isCatalogValid, errors]
}

const buildCatalog = catalog => {
    const newCatalog = { ...catalog }

    forEach(newCatalog.catalogItems, (item, index) => {
        newCatalog.catalogItems[index] = {
            fromWeight: Number(item.fromWeight),
            toWeight: Number(item.toWeight),
            price: Number(item.price),
            volumetricWeight: Boolean(item.volumetricWeight),
        }
    })

    newCatalog.catalogItems = sortBy(newCatalog.catalogItems, i => i.fromWeight)

    newCatalog.volumeExtraChargeAmount = newCatalog.volumeExtraCharge ? Number(newCatalog.volumeExtraChargeAmount) : 0

    return newCatalog
}

const submitCatalog = async (catalog, endpoint, method) => {
    const [isValid, errors] = validateCatalog(catalog)
    if (isValid) {
        const correctCatalog = buildCatalog(catalog)
        return couriersCatalog.submit(correctCatalog, endpoint, method)
    }

    return errors
}

export {
    updateItems,
    addNewItem,
    editItem,
    deleteItem,
    buildNewItem,
    submitCatalog,
    validateItem,
    checkForGapsInItems,
    validateItems,
    validateCatalog,
    buildCatalog,
}
