import React, { useState } from 'react'
import { filter, includes, isEmpty, isNil, map } from 'lodash'

import './Autocomplete.scss'

const Autocomplete = ({ value, placeholder, items, onChange, onSelect }) => {
    const [showList, setShowList] = useState(false)
    const [input, setInput] = useState('')

    const filterCategories = () => {
        if (isNil(input) || isEmpty(input)) return items

        return filter(items, i => includes(i.name.toLowerCase(), input.toLowerCase()))
    }

    return (
        <div>
            <div>
                <input
                    className="autocomplete-input"
                    type="text"
                    placeholder={placeholder}
                    onFocus={() => {
                        setShowList(true)
                    }}
                    onBlur={() => {
                        window.setTimeout(() => {
                            setShowList(false)
                        }, 100)
                    }}
                    value={input}
                    onChange={e => {
                        setInput(e.target.value)
                    }}
                />
            </div>
            {showList && (
                <div className="autocomplete-results">
                    {map(filterCategories(), (c, idx) => (
                        <div
                            className="autocomplete-result"
                            key={idx}
                            onClick={() => {
                                setInput(c.title)
                                onSelect(c)
                            }}
                        >
                            {c.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Autocomplete
