import React, { useState } from 'react'
import { isEmpty, isNil, join, map, reduce, slice } from 'lodash'

import config from '../../../config'
import { returnStatuses } from '../../../model/returns'
import Button from '../../atoms/Button/Button.jsx'
import './ReturnDossier.scss'

const ReturnDossier = ({
    culture,
    currencies,
    returnDossier,
    markAsApprovedAndWaitingToBeReceivedHandler,
    markAsRejectedHandler,
    markAsReceivedAndInProcessHandler,
    markAsRefundedHandler,
    viewOrderDetails,
    hideOrderDetails,
}) => {
    const [reasonForRejection, setReasonForRejection] = useState('')
    const [refundedAmount, setRefundedAmount] = useState('')
    const [currency, setCurrency] = useState(isNil(currencies[0]) ? 'EUR' : currencies[0].code)
    const [rejectWithAutomaticEmail, setRejectWithAutomaticEmail] = useState(true)

    const updateReasonForRejection = e => {
        setReasonForRejection(e.currentTarget.value)
    }

    const updateAmountRefunded = e => {
        setRefundedAmount(e.currentTarget.value)
    }

    const updateCurrency = e => {
        setCurrency(e.currentTarget.value)
    }

    const updateRejectWithAutomaticEmail = () => {
        setRejectWithAutomaticEmail(prevState => !prevState)
    }

    const formatAttributes = attributes => {
        let s = reduce(
            attributes,
            (prev, currVal, currKey) => {
                return (prev += isNil(currVal) ? `${currKey}, ` : `${currKey}: ${currVal}, `)
            },
            ''
        )
        return join(slice(s, 0, s.length - 2), '')
    }

    const getFormattedDate = date => {
        try {
            const d = new Date(date)
            return d.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
        } catch (error) {
            console.error(`Error in parsing date ${date}.`)
            return ''
        }
    }

    const shippingAddress = returnDossier.shippingAddress
    const itemsReturned = returnDossier.itemsReturned
    return (
        <div className="returnDossierContainer" key={returnDossier.id}>
            <div className="returnDossier">
                <div className="info">
                    <fieldset>
                        <legend>Applicant:</legend>{' '}
                        <label name="applicantName">
                            {shippingAddress.firstName} {shippingAddress.lastName}
                        </label>
                        <br />
                        <label className="labelData" htmlFor="phone">
                            Phone:
                        </label>{' '}
                        <label name="phone">
                            <a href={`tel:${shippingAddress.phone}`}>{shippingAddress.phone}</a>
                        </label>
                        <br />
                        <label className="labelData" htmlFor="address">
                            Address:
                        </label>{' '}
                        <label name="address">
                            {shippingAddress.street} {shippingAddress.zipCode} {shippingAddress.city} (
                            {shippingAddress.province})<br />
                            <small>
                                {shippingAddress.country} {shippingAddress.countryCode}
                            </small>
                        </label>
                        <br />
                        <label className="labelData" htmlFor="applicant">
                            <a href={`mailto:${returnDossier.applicant.email}`}>{returnDossier.applicant.email}</a>
                        </label>
                        <br />
                    </fieldset>
                </div>
                <div className="description">
                    <div>
                        <h3>Return n° {returnDossier.number}</h3>
                        <h4>Status: {returnDossier.status}</h4>
                        <h4>Return request date: {getFormattedDate(returnDossier.creationDate)}</h4>
                        <h4>Return type: {returnDossier.returnType}</h4>
                        <h4>Return manner: {returnDossier.returnManner}</h4>
                        {!isNil(returnDossier.iban) && <h4>IBAN: {returnDossier.iban}</h4>}
                        {returnDossier.status === returnStatuses.rejected && (
                            <>
                                <p>Reason for rejection: {returnDossier.reasonForRejection}</p>
                                <p>Automatic email sent: {returnDossier.rejectWithAutomaticEmail ? 'Yes' : 'No'}</p>
                            </>
                        )}
                        {returnDossier.status === returnStatuses.refunded && (
                            <p>
                                Amount refunded: {returnDossier.amountRefunded} {returnDossier.amountRefundedCurrency}
                            </p>
                        )}
                    </div>
                    <div>
                        <div>
                            <fieldset>
                                <legend>Order:</legend>
                                <label className="labelData" htmlFor="orderId">
                                    Order id:
                                </label>{' '}
                                <label name="orderId">{returnDossier.order.sourceId}</label>
                                <br />
                                <label className="labelData" htmlFor="orderNumber">
                                    Order n°:
                                </label>{' '}
                                <label name="orderNumber">{returnDossier.order.orderNumber}</label>
                                <br />
                                <label className="labelData" htmlFor="paymentType">
                                    Payment type:
                                </label>{' '}
                                <label name="paymentType">{returnDossier.order.paymentType}</label>
                                <br />
                                <label className="labelData" htmlFor="orderDate">
                                    Order date:
                                </label>{' '}
                                <label name="orderDate">{getFormattedDate(returnDossier.order.orderDate)}</label>
                                <br />
                                <label className="labelData" htmlFor="paymentId">
                                    Payment id:
                                </label>{' '}
                                <label name="orderDate">{returnDossier.order.paymentId}</label>
                                <br />
                                <br />
                                {isNil(returnDossier.order.orderDetails) ? (
                                    <Button
                                        text="View order details"
                                        onClick={() => viewOrderDetails(returnDossier.id, returnDossier.order.id)}
                                    />
                                ) : (
                                    <Button
                                        text="Hide order details"
                                        onClick={() => hideOrderDetails(returnDossier.id)}
                                    />
                                )}
                                {!isNil(returnDossier.order.orderDetails) && (
                                    <fieldset>
                                        <legend>Order details:</legend>
                                        <label className="labelData" htmlFor="orderLines">
                                            Order lines:
                                        </label>
                                        <br />
                                        <label name="orderLines">
                                            <ul className="orderDetails">
                                                {map(returnDossier.order.orderDetails.lines, (l, idx) => (
                                                    <li key={idx}>
                                                        {l.quantity}x {l.name}
                                                        {!isNil(l.attributes) && !isEmpty(l.attributes) && (
                                                            <>
                                                                <br />
                                                                {formatAttributes(l.attributes)}
                                                            </>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </label>
                                        {!isNil(returnDossier.order.orderDetails.relatedReturns) && (
                                            <>
                                                <label className="labelData" htmlFor="relatedReturns">
                                                    Related retuns:
                                                </label>
                                                <br />
                                                <label name="relatedReturns">
                                                    <ul className="orderDetails">
                                                        {map(
                                                            returnDossier.order.orderDetails.relatedReturns,
                                                            (r, idx) => (
                                                                <li key={idx}>
                                                                    <strong>Return number:</strong> {r.returnNumber}
                                                                    <br />
                                                                    <strong>Return status:</strong> {r.returnStatus}
                                                                    <br />
                                                                    <strong>Return lines:</strong>
                                                                    <br />
                                                                    <ul>
                                                                        {map(r.lines, (l, idx) => (
                                                                            <li key={idx}>
                                                                                {l.quantity}x {l.name}
                                                                                {!isNil(l.attributes) &&
                                                                                    !isEmpty(l.attributes) && (
                                                                                        <>
                                                                                            <br />
                                                                                            {formatAttributes(
                                                                                                l.attributes
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </label>
                                            </>
                                        )}
                                    </fieldset>
                                )}
                            </fieldset>
                        </div>
                        <div>
                            <fieldset>
                                <legend>Returning:</legend>
                                <ul>
                                    {itemsReturned.map((ir, idx) => (
                                        <li key={idx} className="returnedItemsData">
                                            <span>
                                                <label className="labelData" htmlFor="title" />
                                                <label name="title">
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={`${config.getSiteByCulture(culture)}${ir.slug}`}
                                                    >
                                                        {ir.title}
                                                    </a>
                                                    {!isNil(ir.attributes) && !isEmpty(ir.attributes) && (
                                                        <>
                                                            <br />
                                                            {formatAttributes(ir.attributes)}
                                                        </>
                                                    )}
                                                </label>
                                            </span>
                                            <span>
                                                <label className="labelData" htmlFor="quantity">
                                                    Quantity:
                                                </label>{' '}
                                                <label name="quantity">{ir.quantity}</label>
                                            </span>
                                            <span>
                                                <label className="labelData" htmlFor="price">
                                                    Price per unit:
                                                </label>{' '}
                                                <label name="price">
                                                    {ir.pricePerUnitTransformed} {ir.currency}
                                                </label>
                                            </span>
                                            <span>
                                                <label className="labelData" htmlFor="reason">
                                                    Reason:
                                                </label>{' '}
                                                <label name="reason">{ir.motivation}</label>
                                            </span>
                                            <span>
                                                <label className="labelData" htmlFor="additionalNotes">
                                                    Additional notes:
                                                </label>{' '}
                                                <label name="additionalNotes">{ir.additionalNotes}</label>
                                            </span>
                                            {!isNil(ir.images) && !isEmpty(ir.images) && (
                                                <span>
                                                    <label className="labelData" htmlFor="images">
                                                        Images:
                                                    </label>
                                                    <label name="images">
                                                        <div className="images-container">
                                                            {map(ir.images, (img, idx) => (
                                                                <a
                                                                    key={idx}
                                                                    href={img}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <img src={img} alt={`image-${idx + 1}`} />
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </label>
                                                </span>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </fieldset>
                        </div>
                    </div>
                    <p>
                        <strong>{returnDossier.description}</strong>
                    </p>
                </div>

                <div className="actions">
                    {returnDossier.status === returnStatuses.toBeDealtWith && (
                        <Button
                            text="Mark as approved and waiting to be received"
                            onClick={() => markAsApprovedAndWaitingToBeReceivedHandler(returnDossier.id)}
                        />
                    )}
                    {returnDossier.status === returnStatuses.approvedAndWaitingToBeReceived && (
                        <Button
                            text="Mark as received and in process"
                            onClick={() => markAsReceivedAndInProcessHandler(returnDossier.id)}
                        />
                    )}
                    {returnDossier.status === returnStatuses.receivedAndInProcess && (
                        <>
                            <label htmlFor="amountRefunded">
                                Amount refunded:
                                <input
                                    id="amountRefunded"
                                    name="amountRefunded"
                                    type="text"
                                    value={refundedAmount}
                                    onChange={updateAmountRefunded}
                                />
                            </label>
                            <label htmlFor="currency">
                                Currency:
                                <select name="currency" id="currency" value={currency} onChange={updateCurrency}>
                                    {map(currencies, (c, idx) => (
                                        <option key={idx} value={c.code}>
                                            {c.name}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <Button
                                text="Mark as refunded"
                                onClick={() => {
                                    markAsRefundedHandler(
                                        returnDossier.id,
                                        refundedAmount,
                                        currency,
                                        itemsReturned,
                                        culture
                                    )
                                    setRefundedAmount('')
                                    setCurrency(isNil(currencies[0]) ? 'EUR' : currencies[0].code)
                                }}
                            />
                        </>
                    )}
                    <hr />
                    {returnDossier.status !== returnStatuses.refunded &&
                        returnDossier.status !== returnStatuses.rejected && (
                            <>
                                <label htmlFor="reasonForRejection">
                                    Reason for rejecting:
                                    <input
                                        id="reasonForRejection"
                                        name="reasonForRejection"
                                        type="text"
                                        value={reasonForRejection}
                                        onChange={updateReasonForRejection}
                                    />
                                </label>
                                <label htmlFor="rejectWithAutomaticEmail">
                                    <input
                                        id="rejectWithAutomaticEmail"
                                        name="rejectWithAutomaticEmail"
                                        type="checkbox"
                                        checked={rejectWithAutomaticEmail}
                                        value={rejectWithAutomaticEmail}
                                        onChange={updateRejectWithAutomaticEmail}
                                    />
                                    Send automatic email
                                </label>
                                <Button
                                    text="Mark as rejected"
                                    onClick={() => {
                                        markAsRejectedHandler(
                                            returnDossier.id,
                                            reasonForRejection,
                                            rejectWithAutomaticEmail
                                        )
                                        setReasonForRejection('')
                                    }}
                                />
                            </>
                        )}
                </div>
            </div>
        </div>
    )
}

export default ReturnDossier
