import React from 'react'
import config from '../../config'
import lordgun from '../../../assets/img/lordgun.png'
import runkd from '../../../assets/img/runkd.png'
import inwild from '../../../assets/img/inwild.png'
const backgroundMap = {
    lordgun,
    runkd,
    inwild,
}
export default class LandingEmptyPage extends React.Component {
    render() {
        let Background = backgroundMap[config.currentSite]
        return (
            <div>
                <img className="siteBackground" src={Background} />
            </div>
        )
    }
}
