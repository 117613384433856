import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import config from '../../../config'
import * as commonActions from '../../../redux/common/common.actions'
import Logout from '../../molecules/Logout/Logout.jsx'
import Footer from '../../organisms/Footer/Footer.jsx'
var startsWith = require('lodash/startsWith')
var endsWith = require('lodash/endsWith')
var replace = require('lodash/replace')
import './menu.scss'
import userRoles from '../../../model/userRoles'
import GoogleTagManager from '../../GoogleTagManager.jsx'

const Menu = ({ loggedIn, roleSet, culture, onCultureChange }) => {
    const handleCultureChange = event => onCultureChange(event.target.value)
    const createSelectItems = () => {
        return config.getLocalizedWebsitesCultures().map(culture => {
            const siteByCulture = config.getSiteByCulture(culture)
            let siteDescription = startsWith(siteByCulture, 'https://www.')
                ? replace(siteByCulture, 'https://www.', '')
                : siteByCulture
            siteDescription = endsWith(siteDescription, '/') ? replace(siteDescription, '/', '') : siteDescription

            return (
                <option key={culture} value={culture}>
                    {siteDescription}
                </option>
            )
        })
    }

    // =============== ADD LINK!!! ===============
    return (
        <>
            <div className="app-menu">
                <div className="menuList">
                    <Link to={`/landingEmptyPage`}>
                        <h2>YABB</h2>
                    </Link>
                    <select name="select" className="selectMenu" value={culture} onChange={handleCultureChange}>
                        {createSelectItems()}
                    </select>
                    {userRoles.isValidRole(roleSet, userRoles.userRoles.gestoreGlobale) && (
                        <>
                            <Link to={`/categorybanners`}>
                                <p>Category banners</p>
                            </Link>
                            <Link to={`/questions`}>
                                <p>Questions</p>
                            </Link>
                            <Link to={`/feedbacks`}>
                                <p>Feedbacks</p>
                            </Link>
                            <Link to={`/destinations`}>
                                <p>Destinations</p>
                            </Link>
                            <Link to={`/reviews`}>
                                <p>Reviews</p>
                            </Link>
                            <Link to={`/users`}>
                                <p>Users</p>
                            </Link>
                            <Link to={`/infobox`}>
                                <p>Infobox</p>
                            </Link>
                            <Link to={`/reassortmentNotifications`}>
                                <p>Reassortment notifications</p>
                            </Link>
                            <Link to={`/returns`}>
                                <p>Returns</p>
                            </Link>
                            <Link to={`/couriers`}>
                                <p>Couriers</p>
                            </Link>
                            <Link to={`/shippingServices`}>
                                <p>Couriers shipping services</p>
                            </Link>
                            <Link to={`/zones`}>
                                <p>Zones</p>
                            </Link>
                            <Link to={`/couriersCatalog`}>
                                <p>Couriers price catalogs</p>
                            </Link>
                            <Link to={`/priceFinder`}>
                                <p>Couriers price finder</p>
                            </Link>
                            <Link to={`/warehousePickup`}>
                                <p>Warehouse Pickup</p>
                            </Link>
                        </>
                    )}

                    {userRoles.isValidRole(roleSet, userRoles.userRoles.gestoreLocale) && (
                        <Link to={`/sizeguides`}>
                            <p>Size guides</p>
                        </Link>
                    )}
                </div>
                <div className="menuFooter">
                    {loggedIn && <Logout />}
                    <Footer />
                </div>
            </div>
            <GoogleTagManager culture={culture} />
        </>
    )
}

const mapStateToProps = state => {
    return {
        culture: state.common.culture,
        loggedIn: state.users.loggedIn,
    }
}

const mapDispatchToProps = dispatch => ({
    onCultureChange: culture => dispatch(commonActions.changeCulture(culture)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
