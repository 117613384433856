import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

const getInfoboxes = culture => {
    return fetch(`${config.apiRoot}/v1/Infoboxes?culture=${culture}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })
}

const putInfobox = (infoboxId, infobox) => {
    return fetch(`${config.apiRoot}/v1/Infoboxes/${infoboxId}`, {
        method: 'PUT',
        headers: new Headers({
            ...authHeader(),
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(infobox),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })
}

const postInfobox = infobox => {
    fetch(`${config.apiRoot}/v1/Infoboxes/`, {
        method: 'POST',
        headers: new Headers({
            ...authHeader(),
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(infobox),
    }).then(r => {
        if (!r.ok) throw new ServerError(r)
    })
}

const deleteInfobox = infoboxId =>
    fetch(`${config.apiRoot}/v1/Infoboxes/${infoboxId}`, {
        method: 'DELETE',
        headers: authHeader(),
    }).then(r => {
        if (!r.ok) throw new ServerError(r)
    })

export default {
    getInfoboxes,
    putInfobox,
    postInfobox,
    deleteInfobox,
}
