import config from '../config'
import { authHeader } from './auth-header'
import ServerError from '../errors/ServerError'
import { reduce, join } from 'lodash'

const upload = ({ url = 'uploads', version = 'v1', formData }) => {
    const formDataObject = reduce(
        formData,
        (acc, value, key) => {
            acc.append(key, value)
            return acc
        },
        new FormData()
    )
    return fetch(`${config.apiRoot}/${version}/${url}/`, {
        method: 'POST',
        headers: authHeader(),
        body: formDataObject,
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })
}

const get = ({ url = 'nonexistent', version = 'v1', qs }) => {
    let queryStrings = reduce(
        qs,
        (acc, value, key) => {
            acc.push(`${key}=${value}`)
            return acc
        },
        []
    )
    let queryString = join(queryStrings, '&')
    return fetch(`${config.apiRoot}/${version}/${url}?${queryString}`, {
        headers: authHeader(),
    }).then(r => {
        if (r.ok) {
            return r.json()
        }
        throw new ServerError(r)
    })
}

const put = ({ url = 'nonexistent', version = 'v1', body }) => {
    return fetch(`${config.apiRoot}/${version}/${url}/`, {
        method: 'PUT',
        headers: new Headers({
            ...authHeader(),
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body),
    })
        .then(r => {
            return r.json().then(body => ({ status: r.ok, body }))
        })
        .then(messageObj => {
            if (messageObj.status === false) throw new ServerError(messageObj.body.message)
            return messageObj.body
        })
}

const post = ({ url = 'nonexistent', version = 'v1', body }) => {
    return fetch(`${config.apiRoot}/${version}/${url}/`, {
        method: 'POST',
        headers: new Headers({
            ...authHeader(),
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body),
    })
        .then(r => {
            return r.json().then(body => ({ status: r.ok, body }))
        })
        .then(messageObj => {
            if (messageObj.status === false) throw new ServerError(messageObj.body.message)
            return messageObj.body
        })
}

const remove = ({ url = 'nonexistent', version = 'v1' }) =>
    fetch(`${config.apiRoot}/${version}/${url}/`, {
        method: 'DELETE',
        headers: authHeader(),
    }).then(r => {
        if (!r.ok) throw new ServerError(r)
    })

export const http = {
    upload,
    get,
    put,
    post,
    remove,
}
