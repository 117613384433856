const colors = {
    lordgun: {
        colors: ['#FFFFFF', '#F6F6F6', '#FFE500', '#4387FC', '#D32F2F', '#1D1D1D'],
        textDefaultColor: '#1D1D1D',
        bgDefaultColor: '#FFE500',
    },
    runkd: {
        colors: ['#FFFFFF', '#F6F6F6', '#F7931E', '#451032', '#E6110E', '#1D1D1D'],
        textDefaultColor: '#FFFFFF',
        bgDefaultColor: '#F7931E',
    },
    inwild: {
        colors: ['#FFFFFF', '#F6F6F6', '#F28C0F', '#03A678', '#E6110E', '#1D1D1D'],
        textDefaultColor: '#FFFFFF',
        bgDefaultColor: '#F28C0F',
    },
}

export default colors
