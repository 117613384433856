import React, { Component } from 'react'

import './FreestyleTemplate.scss'

class FreestyleTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            html: '',
        }
    }

    handleChange(e) {
        this.setState({ html: e.target.value })
        this.props.onChangeTemplate({ html: e.target.value })
    }

    render() {
        const { html } = this.state
        return (
            <div className="banner__details">
                <textarea onChange={e => this.handleChange(e)} />
                <div
                    dangerouslySetInnerHTML={{
                        __html: html,
                    }}
                />
            </div>
        )
    }
}
export default FreestyleTemplate
