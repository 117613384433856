import React from 'react'
import './imageClickable.scss'
const ImageClickable = ({ filename, onOpen }) => {
    return (
        <a className="imageClickable" href="#" onClick={() => onOpen(filename)}>
            <img src={filename} />
        </a>
    )
}

export default ImageClickable
