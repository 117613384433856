/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { map } from 'lodash'
import WarehousePickup from '../../molecules/Couriers/WarehousePickup.jsx'

import './WarehousePickupsList.scss'

const WarehousePickupsList = ({ warehousePickups, url, handleDelete, countries, updateList }) => {
    return warehousePickups.length > 0 ? (
        <div className="container warehousePickups">
            <div className="warehousePickups-table">
                <div className="warehousePickups-table-row header">
                    <div>Logo</div>
                    <div>Name</div>
                    <div>Country</div>
                    <div>Actions</div>
                </div>
                {map(warehousePickups, (w, idx) => (
                    <div key={idx} className="warehousePickups-table-row">
                        <WarehousePickup
                            warehousePickup={w}
                            url={url}
                            handleDelete={handleDelete}
                            countries={countries}
                            updateList={updateList}
                        />
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <div className="container warehousePickups">
            <p>There are no Warehouse pickups. Create one by clicking on the button "Create new Warehouse Pickup".</p>
        </div>
    )
}

export default WarehousePickupsList
