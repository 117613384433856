import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import { filter, find, isEmpty, isNil, isNumber, join, map } from 'lodash'
import zones from '../../../model/zones.js'
import shippingServices from '../../../model/shippingServices.js'
import couriersCatalog from '../../../model/couriersCatalog.js'
import {
    buildNewItem,
    updateItems,
    deleteItem,
    submitCatalog,
} from '../../../services/couriers/couriersPriceCatalogs.js'
import { getServiceName } from '../../../services/couriers/shippingServices.js'
import Loading from '../Loading/Loading.jsx'
import CatalogFormItem from './CatalogFormItem.jsx'
import Button from '../../atoms/Button/Button.jsx'

import './CatalogForm.scss'

const CatalogForm = ({ catalogId, mode, url, updateList }) => {
    const [isLoading, setLoading] = useState(true)
    const [isSubmitSuccess, setSubmitSuccess] = useState(false)
    const [catalog, setCatalog] = useState({
        name: '',
        zone: {},
        courierServices: [],
        catalogItems: [{}],
        volumetricWeight: false,
        volumeExtraCharge: false,
        volumeExtraChargeAmount: 0,
    })
    const [zonesList, setZonesList] = useState([])
    const [courierServicesList, setCourierServicesList] = useState([])
    const [errors, setErrors] = useState({})
    const [increase, setIncrease] = useState(0)
    const buttonText = {
        create: 'Create',
        edit: 'Save',
    }
    const increaseMode = {
        percentage: 'percentage',
        absolute: 'absolute',
    }

    const submit = async () => {
        setLoading(true)
        let errors
        try {
            switch (mode) {
                case 'create':
                    errors = await submitCatalog(catalog, 'create', 'POST')
                    break
                case 'edit':
                    errors = await submitCatalog(catalog, `${catalogId}`, 'PUT')
                    break
                default:
                    break
            }
            setLoading(false)
            if (isEmpty(errors)) {
                setSubmitSuccess(true)
            }

            setErrors(errors)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const updateCatalogItems = (value, key, index) => {
        const newItem = buildNewItem({
            items: catalog.catalogItems,
            newValues: { key: key, value: value },
            index: index,
        })
        const result = updateItems({ items: catalog.catalogItems, newItem: newItem, index: index })
        setCatalog(prevState => ({
            ...prevState,
            catalogItems: result.items,
        }))
    }

    const addEmptyRow = () => {
        const result = updateItems({ items: catalog.catalogItems, newItem: {}, index: catalog.catalogItems.length })
        setCatalog(prevState => ({
            ...prevState,
            catalogItems: result.items,
        }))
    }

    const deleteRow = index => {
        const result = deleteItem({ items: catalog.catalogItems, index: index })
        setCatalog(prevState => ({
            ...prevState,
            catalogItems: result.items,
        }))
    }

    const fetchCatalog = async catalogId => {
        try {
            const catalog = await couriersCatalog.getCatalog(catalogId)
            setCatalog({
                ...catalog,
                id: catalog.id,
                name: mode === 'create' ? `${catalog.name}_copy` : catalog.name,
            })
            const services = await shippingServices.getByServiceIds(join(catalog.zone.courierServices, ','))
            setCourierServicesList(services)
        } catch (error) {
            console.error(error)
        }
    }

    const fetchZones = async () => {
        try {
            const result = await zones.getZones()
            setZonesList(result)
        } catch (error) {
            console.error(error)
        }
    }

    const updateZone = async value => {
        if (value === '') {
            setCatalog(prevState => ({
                ...prevState,
                zone: {},
                courierServices: [],
            }))
            return
        }
        const zone = find(zonesList, z => z.name === value)

        if (!isNil(zone)) {
            setCatalog(prevState => ({
                ...prevState,
                zone: zone,
                courierServices: [],
            }))
            const services = await shippingServices.getByServiceIds(join(zone.courierServices, ','))
            setCourierServicesList(services)
        }
    }

    const updateCourierServices = (courierServiceId, country) => {
        const serviceId = Number(courierServiceId)

        if (isNumber(serviceId) && !isNaN(serviceId)) {
            let servicesList = []
            if (isIncluded(catalog.courierServices, courierServiceId)) {
                servicesList = filter(catalog.courierServices, c => c.serviceId !== serviceId)
            } else {
                servicesList = catalog.courierServices
                servicesList.push({ serviceId, country })
            }

            setCatalog(prevState => ({
                ...prevState,
                courierServices: servicesList,
            }))
        }
    }

    const toggleServicesCheckboxes = () => {
        if (isToggleAllSelected()) {
            setCatalog(prevState => ({
                ...prevState,
                courierServices: [],
            }))
        } else {
            const newServices = map(courierServicesList, s => ({
                serviceId: s.courierServiceId,
                country: s.destinationCountryCode,
            }))
            setCatalog(prevState => ({
                ...prevState,
                courierServices: newServices,
            }))
        }
    }

    const isToggleAllSelected = () => {
        return catalog.courierServices.length === courierServicesList.length
    }

    const isIncluded = (collection, item) => {
        const itemPresent = find(collection, i => i.serviceId === item)
        return !isNil(itemPresent)
    }

    const updatePrice = (value, mode) => {
        if (isNumber(Number(value)) && !isNaN(Number(value))) {
            const increase = Number(value)
            const newItems = map(catalog.catalogItems, item => ({
                ...item,
                price: computeNewPrice(item.price, increase, mode),
            }))

            setCatalog(prevState => ({
                ...prevState,
                catalogItems: newItems,
            }))
        }
    }

    const computeNewPrice = (value, increase, mode) => {
        if (isNumber(Number(value)) && !isNaN(Number(value))) {
            const numericValue = Number(value)
            switch (mode) {
                case increaseMode.percentage:
                    return roundToTwoDecimalPlaces(numericValue + numericValue * increase * 0.01)
                case increaseMode.absolute:
                    return roundToTwoDecimalPlaces(numericValue + increase)
                default:
                    return numericValue
            }
        }
        return value
    }

    const roundToTwoDecimalPlaces = value => {
        if (typeof value === 'number') {
            if (!isNaN(value)) return Number(value.toFixed(2))
        } else {
            const numericValue = Number(value)
            if (isNumber(numericValue) && !isNaN(numericValue)) return Number(numericValue.toFixed(2))
        }
        return 0
    }

    const updateVolumeExtraChargeAmount = value => {
        const numericValue = Number(value)
        if (isNumber(numericValue) && !isNaN(numericValue)) {
            setCatalog(prevState => ({
                ...prevState,
                volumeExtraChargeAmount: value,
            }))
        }
    }

    useEffect(() => {
        if (!isNil(catalogId)) {
            fetchCatalog(catalogId)
        }

        fetchZones()
        setLoading(false)
    }, [])

    return isLoading ? (
        <Loading />
    ) : (
        <form className="catalog-form">
            {!isNil(errors.generic) && !isEmpty(errors.generic) && <p className="error">{errors.generic}</p>}
            <div className="catalog-form-row">
                <label className="catalog-form-label" htmlFor="name">
                    Catalog name:
                    <input
                        type="text"
                        name="name"
                        value={catalog.name}
                        onChange={e => {
                            const value = e.target.value
                            setCatalog(prevState => ({
                                ...prevState,
                                name: value,
                            }))
                        }}
                    />
                </label>
                {!isNil(errors.name) && !isEmpty(errors.name) && <p className="error">{errors.name}</p>}
            </div>
            <div className="catalog-form-row">
                <label className="catalog-form-label" htmlFor="zoneName">
                    Zone:
                    <select
                        name="zoneName"
                        onChange={e => {
                            const value = e.target.value
                            updateZone(value)
                        }}
                        value={catalog.zone.name}
                    >
                        <option value="">-- select --</option>
                        {map(zonesList, (z, idx) => (
                            <option value={z.name} key={idx}>
                                {z.name}
                            </option>
                        ))}
                    </select>
                </label>
                {!isNil(errors.zone) && !isEmpty(errors.zone) && <p className="error">{errors.zone}</p>}
            </div>
            <div className="catalog-form-row">
                <label className="catalog-form-label" htmlFor="courier">
                    Courier:{' '}
                    {isNil(catalog.zone.courier) || isNil(catalog.zone.courier.name) ? '' : catalog.zone.courier.name}
                </label>
            </div>
            <div className="catalog-form-row">
                <label className="catalog-form-label" htmlFor="courierService">
                    Courier service:
                    {courierServicesList.length > 0 ? (
                        <label
                            className="catalog-form-label"
                            htmlFor="toggleAll"
                            onClick={toggleServicesCheckboxes}
                            style={{ cursor: 'pointer' }}
                        >
                            <input
                                type="checkbox"
                                value="toggleAll"
                                name="toggleAll"
                                checked={isToggleAllSelected()}
                                readOnly
                            />
                            Select/Deselect all
                        </label>
                    ) : (
                        <></>
                    )}
                    {map(courierServicesList, (s, idx) => (
                        <label
                            key={idx}
                            className="catalog-form-label"
                            htmlFor={s.courierServiceId}
                            onClick={() => {
                                updateCourierServices(s.courierServiceId, s.destinationCountryCode)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <input
                                type="checkbox"
                                value={s.courierServiceId}
                                name={s.courierServiceId}
                                checked={isIncluded(catalog.courierServices, s.courierServiceId)}
                                readOnly
                            />
                            {`${s.destinationCountryCode} - ${getServiceName(s.courierServiceNames)}`}
                        </label>
                    ))}
                </label>
                {!isNil(errors.courierServices) && !isEmpty(errors.courierServices) && (
                    <p className="error">{errors.courierServices}</p>
                )}
            </div>
            <div className="catalog-form-row">
                <label
                    className="catalog-form-label"
                    htmlFor="volumetricWeight"
                    onClick={() => {
                        setCatalog(prevState => ({
                            ...prevState,
                            volumetricWeight: !prevState.volumetricWeight,
                        }))
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    Volumetric weight:
                    <input
                        type="checkbox"
                        name="volumetricWeight"
                        value={!catalog.volumetricWeight}
                        checked={isNil(catalog.volumetricWeight) ? false : catalog.volumetricWeight}
                        readOnly
                    />
                </label>
            </div>
            <div className="catalog-form-row">
                <label className="catalog-form-label" htmlFor="items">
                    Items:
                    {map(catalog.catalogItems, (item, idx) => (
                        <CatalogFormItem
                            key={idx}
                            index={idx}
                            item={item}
                            update={updateCatalogItems}
                            deleteItem={deleteRow}
                            errors={
                                !isNil(errors.items) && !isNil(errors.items.items) && !isEmpty(errors.items.items)
                                    ? errors.items.items[idx]
                                    : {}
                            }
                        />
                    ))}
                    <Button
                        onClick={addEmptyRow}
                        customClassName="button-round"
                        type="button"
                        text={
                            <svg height="16" width="16">
                                <path d="M0 8 L16 8"></path>
                                <path d="M8 0 L8 16"></path>
                            </svg>
                        }
                    />
                </label>
                {!isNil(errors.items) && !isNil(errors.items.generic) && (
                    <p className="error">{errors.items.generic}</p>
                )}
            </div>
            <div className="catalog-form-row">
                <label
                    className="catalog-form-label"
                    htmlFor="volumeExtraCharge"
                    onClick={() => {
                        setCatalog(prevState => ({
                            ...prevState,
                            volumeExtraCharge: !prevState.volumeExtraCharge,
                        }))
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    Extra charge for voluminous parcels:
                    <input
                        type="checkbox"
                        name="volumeExtraCharge"
                        value={!catalog.volumeExtraCharge}
                        checked={isNil(catalog.volumeExtraCharge) ? false : catalog.volumeExtraCharge}
                        readOnly
                    />
                </label>
                <label className="catalog-form-label" htmlFor="volumeExtraChargeAmount">
                    Amount (EUR without VAT):
                    <input
                        type="text"
                        name="volumeExtraChargeAmount"
                        value={catalog.volumeExtraChargeAmount}
                        onChange={e => {
                            const value = e.target.value
                            updateVolumeExtraChargeAmount(value)
                        }}
                    />
                </label>
            </div>
            <div className="catalog-form-row">
                <label className="catalog-form-label" htmlFor="increaseAll">
                    Increase all prices by:
                    <input
                        type="text"
                        name="increaseAll"
                        value={increase}
                        onChange={e => {
                            const value = e.target.value
                            setIncrease(value)
                        }}
                    />
                    <div>
                        <Button
                            type="button"
                            onClick={() => {
                                updatePrice(increase, increaseMode.percentage)
                            }}
                            text="Increase percentage"
                        />
                        <Button
                            type="button"
                            onClick={() => {
                                updatePrice(increase, increaseMode.absolute)
                            }}
                            text="Increase value"
                        />
                    </div>
                </label>
                <p>To reduce all prices, use a negative increase (e.g. -4)</p>
            </div>
            <hr />
            <Button
                text={buttonText[mode]}
                type="button"
                onClick={async () => {
                    submit()
                }}
            />
            <Link to={url}>
                <Button text="Cancel" type="button" />
            </Link>
            <Modal isOpen={isSubmitSuccess} contentLabel="Success modal" className="Modal" overlayClassName="Overlay">
                <p>Operation successful.</p>
                <Link to={url}>
                    <Button text="Back to price catalogs list" type="button" onClick={updateList} />
                </Link>
            </Modal>
        </form>
    )
}

export default CatalogForm
