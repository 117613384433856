import { filter, map } from 'lodash'
import { ACTION_TYPES } from './returns.actions'
const INITIAL_STATE = {
    list: [],
}

const reducers = {}

reducers[ACTION_TYPES.RETURNS_RECEIVED] = (state, action) => {
    let lista = action.payload === undefined ? [] : action.payload

    return {
        ...state,
        list: [...lista],
    }
}
reducers[ACTION_TYPES.APPROVEDANDWAITINGTOBERECEIVED] = (state, action) => ({
    ...state,
    list: filter(state.list, l => l.id !== action.payload.returnId),
})
reducers[ACTION_TYPES.REJECTED] = (state, action) => ({
    ...state,
    list: filter(state.list, l => l.id !== action.payload.returnId),
})
reducers[ACTION_TYPES.RECEIVEDANDINPROCESS] = (state, action) => ({
    ...state,
    list: filter(state.list, l => l.id !== action.payload.returnId),
})
reducers[ACTION_TYPES.REFUNDED] = (state, action) => ({
    ...state,
    list: filter(state.list, l => l.id !== action.payload.returnId),
})
reducers[ACTION_TYPES.ORDERDETAILS_RECEIVED] = (state, action) => ({
    ...state,
    list: map(state.list, l => {
        if (l.id === action.payload.returnId) {
            l.order.orderDetails = action.payload.result.order.orderDetails
        }
        return l
    }),
})
reducers[ACTION_TYPES.HIDEORDERDETAILS_REQUEST] = (state, action) => ({
    ...state,
    list: map(state.list, l => {
        if (l.id === action.payload) {
            l.order.orderDetails = null
        }
        return l
    }),
})

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type] || (() => state)
    return reducer(state, action)
}
