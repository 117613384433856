import React from 'react'
import { connect } from 'react-redux'

import * as modalActions from '../../redux/modal/modal.actions'
import modal from '../../redux/modal/modal.types'
import Header from '../organisms/Header/Header.jsx'
import SearchField from '../organisms/SearchField/SearchField.jsx'

import ReassortmentNotificationsList from '../organisms/ReassortmentNotificationsList/ReassortmentNotificationsList.jsx'
import * as actions from '../../redux/reassortmentNotifications/reassortmentNotifications.actions'

class ReassortmentNotificationsPage extends React.Component {
    componentDidMount() {
        this.props.setInitialItems()
    }

    render() {
        return (
            <div>
                <Header>
                    <div className="title">
                        <h2>Reassortment notifications</h2>
                    </div>
                </Header>
                <SearchField
                    search={this.props.searchReassortmentNotifications}
                    minLengthMessage="4 characters minimum - insert a segment with @ in order to search by email. Please note that email is case-sensitive at the moment."
                    minLength={4}
                />
                <ReassortmentNotificationsList
                    culture={this.props.culture}
                    reassortmentNotification={this.props.reassortmentNotification}
                    deleteReassortmentNotificationHandler={this.props.deleteReassortmentNotification}
                    openImageHandler={this.props.openImage}
                />
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    setInitialItems: () => dispatch(actions.reassortmentNotificationsReceived()),
    searchReassortmentNotifications: searchTerm => dispatch(actions.searchReassortmentNotificationsRequest(searchTerm)),
    deleteReassortmentNotification: ({ reassortmentNotificationId, lineId }) =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () =>
                    dispatch(actions.deleteReassortmentNotificationRequest({ reassortmentNotificationId, lineId })),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
    openImage: filename =>
        dispatch(
            modalActions.showModal(modal.types.OPEN_IMAGE, {
                filename,
                isOpen: true,
                closeHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
})

const mapStateToProps = state => {
    return {
        ...state.reassortmentNotification,
        culture: state.common.culture,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReassortmentNotificationsPage)
