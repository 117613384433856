import React from 'react'
import moment from 'moment'

import Button from '../../atoms/Button/Button.jsx'
import '../Answers/Answer.scss'

const Answer = ({ answer, questionId, deleteAnswerHandler, approveAnswerHandler, getUserInfoHandler }) => {
    const momentDate = moment(answer.creationDate).format('lll')

    return (
        <div className="answer">
            <div className="answerUser">
                <Button text={answer.userNickName} onClick={() => getUserInfoHandler(answer.userId)} />
                <h4>{momentDate}</h4>
            </div>
            <div className="answerText">
                <textarea rows="3" disabled value={answer.description} />
            </div>
            <div className="answerActions">
                <Button
                    text="Read"
                    onClick={() => approveAnswerHandler(questionId, answer.id)}
                    disabled={answer.approved}
                />
                <Button text="Delete" onClick={() => deleteAnswerHandler(questionId, answer.id)} />
            </div>
        </div>
    )
}

export default Answer
