import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import userRoles from '../model/userRoles'
const PrivateRoute = ({ component: Component, role, loggedIn, minimumValidRole, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            loggedIn ? (
                userRoles.isValidRole(role, minimumValidRole) ? (
                    <Component {...props} />
                ) : (
                    <div>
                        <h1>Content not authorized to be viewed</h1>
                    </div>
                )
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
)

const mapStateToProps = state => {
    return {
        loggedIn: state.users.loggedIn,
    }
}
export default withRouter(connect(mapStateToProps)(PrivateRoute))
