import React from 'react'
import { connect } from 'react-redux'

import * as actions from '../../redux/user/user.actions'
import * as modalActions from '../../redux/modal/modal.actions'
import modal from '../../redux/modal/modal.types'
import Header from '../organisms/Header/Header.jsx'
import SearchField from '../organisms/SearchField/SearchField.jsx'
import UserList from '../organisms/UserList/UserList.jsx'

class UsersPage extends React.Component {
    componentDidMount() {
        this.props.setInitialUsers()
    }

    render() {
        return (
            <div>
                <Header>
                    <div className="title">
                        <h2>Users</h2>
                    </div>
                </Header>
                <SearchField
                    search={this.props.searchUsers}
                    minLengthMessage="4 characters minimum - max 128 results returned"
                    minLength={4}
                />

                <UserList
                    users={this.props.list}
                    lock={this.props.lock}
                    unlock={this.props.unlock}
                    deleteUser={this.props.deleteUser}
                />
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    setInitialUsers: () => dispatch(actions.usersReceived([])),
    setUsers: users => dispatch(actions.usersReceived(users)),
    searchUsers: searchTerm => dispatch(actions.searchUsers(searchTerm)),
    lock: userId =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.lockUser(userId)),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
    unlock: userId =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.unlockUser(userId)),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
    deleteUser: userId =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.deleteUser(userId)),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
})

const mapStateToProps = state => {
    return {
        ...state.users,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage)
