import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'
import { forEach, isNil } from 'lodash'

const getCouriers = () => {
    return fetch(`${config.apiRoot}/v1/couriers/`, {
        method: 'GET',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return r.json()
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

const getCourier = courierId => {
    return fetch(`${config.apiRoot}/v1/couriers/${courierId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return r.json()
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

const submit = (courier, endpoint, method) => {
    const formData = new FormData()
    forEach(courier, (val, key) => {
        formData.append(key, isNil(val) ? null : val)
    })

    return fetch(`${config.apiRoot}/v1/couriers/${endpoint}`, {
        method: method,
        headers: { ...authHeader() },
        body: formData,
    }).then(async r => {
        if (r.ok) {
            return {}
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            return resp
        }
        throw new ServerError(r)
    })
}

const deleteCourier = courierId => {
    return fetch(`${config.apiRoot}/v1/couriers/${courierId}`, {
        method: 'DELETE',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return true
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

export default { getCouriers, getCourier, submit, deleteCourier }
