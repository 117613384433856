import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import Dropzone from 'react-dropzone'
import { useDropzone } from 'react-dropzone'
import { find, forEach, isEmpty, isNil, isNumber, join, map, sortBy } from 'lodash'
import config from '../../../config.js'
import couriers from '../../../model/couriers.js'
import shippingServices from '../../../model/shippingServices.js'
import { submitShippingService } from '../../../services/couriers/shippingServices.js'
import Loading from '../Loading/Loading.jsx'
import Button from '../../atoms/Button/Button.jsx'

import './ShippingServiceForm.scss'

const ShippingServiceForm = ({ shippingServiceId, mode, url, updateList, countriesList }) => {
    const [isLoading, setLoading] = useState(true)
    const [isSubmitSuccess, setSubmitSuccess] = useState(false)
    const [couriersList, setCouriersList] = useState([])
    const [shippingService, setShippingService] = useState({
        courierId: 0,
        courierServiceId: 0,
        destinationCountryCode: '',
        courierServiceDepotPickupAvailable: false,
        courierServiceEstimatedDeliveryDays: 0,
        courierServiceFreeShippingThreshold: 0,
        freeShippingThresholdEnabled: false,
        courierServiceNames: map(config.getCultures(), c => ({
            culture: c,
            name: '',
            deliveryInfo: '',
        })),
        courierServicePercentageIncrease: 0,
        logoFile: null,
        logoUrl: '',
        weightFilterEnabled: false,
        weightValidFrom: 0,
        weightValidTo: 0,
        priceFilterEnabled: false,
        priceValidFrom: 0,
        priceValidTo: 0,
    })
    const [errors, setErrors] = useState({})
    const buttonText = {
        create: 'Create',
        edit: 'Save',
    }
    const baseStyle = {
        width: 180,
        height: 180,
        borderWidth: 2,
        borderColor: '#666',
        borderStyle: 'dashed',
        borderRadius: 5,
        padding: 20,
    }
    const activeStyle = {
        borderStyle: 'solid',
        borderColor: '#6c6',
        backgroundColor: '#eee',
    }
    const rejectStyle = {
        borderStyle: 'solid',
        borderColor: '#c66',
        backgroundColor: '#eee',
    }

    const submit = async () => {
        setLoading(true)
        let errors
        try {
            switch (mode) {
                case 'create':
                    errors = await submitShippingService(shippingService, 'create', 'POST')
                    break
                case 'edit':
                    errors = await submitShippingService(shippingService, `${shippingServiceId}`, 'PUT')
                    break
                default:
                    break
            }

            setLoading(false)
            console.log(errors)
            if (isEmpty(errors)) {
                setSubmitSuccess(true)
            }

            setErrors(errors)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const fetchShippingService = async shippingServiceId => {
        try {
            const service = await shippingServices.getShippingService(shippingServiceId)
            setShippingService(service)
        } catch (error) {
            console.error(error)
        }
    }

    const fetchCouriers = async () => {
        try {
            const fetchedCouriers = await couriers.getCouriers()
            setCouriersList(fetchedCouriers)
        } catch (error) {
            console.error(error)
        }
    }

    const updateServiceName = (culture, value) => {
        const item = find(shippingService.courierServiceNames, n => n.culture === culture)

        if (!isNil(item)) {
            const newNames = map(shippingService.courierServiceNames, n => ({
                culture: n.culture,
                deliveryInfo: n.deliveryInfo,
                name: n.culture === culture ? value : n.name,
            }))

            setShippingService(prevState => ({
                ...prevState,
                courierServiceNames: newNames,
            }))
        }
    }

    const updateServiceDeliveryInfos = (culture, value) => {
        const item = find(shippingService.courierServiceNames, n => n.culture === culture)

        if (!isNil(item)) {
            const newNames = map(shippingService.courierServiceNames, n => ({
                culture: n.culture,
                name: n.name,
                deliveryInfo: n.culture === culture ? value : n.deliveryInfo,
            }))

            setShippingService(prevState => ({
                ...prevState,
                courierServiceNames: newNames,
            }))
        }
    }

    const readFile = file => {
        return new Promise((resolve, reject) => {
            // Create file reader
            let reader = new FileReader()
            // Register event listeners
            reader.addEventListener('loadend', e => resolve(e.target.result))
            reader.addEventListener('error', reject)
            // Read file
            reader.readAsArrayBuffer(file)
        })
    }

    const getAsByteArray = async file => {
        try {
            let bytes = await readFile(file)
            let uint8Array = new Uint8Array(bytes)
            let array = []
            forEach(uint8Array, b => {
                array.push(b)
            })
            return array
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const updateLogo = async acceptedFiles => {
        const imgFile = acceptedFiles[0]
        Object.assign(imgFile, {
            preview: URL.createObjectURL(imgFile),
        })

        const img = {
            name: imgFile.name,
            bytes: [],
        }
        img.bytes = await getAsByteArray(imgFile)

        setShippingService(prevState => ({
            ...prevState,
            logoFile: img,
        }))
    }

    useEffect(() => {
        if (!isNil(shippingServiceId)) {
            fetchShippingService(shippingServiceId)
        }

        fetchCouriers()
        setLoading(false)
    }, [])

    const showImageErrors = fileRejections => {
        return map(fileRejections, ({ file, errors }, idx) => (
            <p className="error" key={idx}>
                {file.name}:{' '}
                {join(
                    map(errors, e => e.message),
                    '. '
                )}
            </p>
        ))
    }

    const preview = file => (
        <div className="shippingService-form-preview">
            <div
                className="shippingService-form-preview-icon"
                onClick={() => {
                    setShippingService(prevState => ({
                        ...prevState,
                        logoFile: null,
                        logoUrl: '',
                    }))
                }}
            >
                <svg height="20" width="20">
                    <path d="M5 5 L13 13" />
                    <path d="M5 13 L13 5" />
                </svg>
            </div>
            <img src={file.preview} alt={file.name} />
            <p className="shippingService-form-preview-text">{file.name}</p>
        </div>
    )

    const { getRootProps } = useDropzone({
        // Note how this callback is never invoked if drop occurs on the inner dropzone
        onDrop: updateLogo,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
        },
        maxFiles: 1,
    })

    return isLoading ? (
        <Loading />
    ) : (
        <form className="shippingService-form">
            {!isNil(errors.generic) && !isEmpty(errors.generic) && (
                <div className="shippingService-form-row full">
                    <p className="error">{errors.generic}</p>
                </div>
            )}
            <div className="shippingService-form-row">
                <label className="shippingService-form-label" htmlFor="courierId">
                    Courier:
                    {mode === 'create' ? (
                        <select
                            name="courierId"
                            value={shippingService.courierId}
                            onChange={e => {
                                const id = Number(e.target.value)
                                if (isNumber(id) && !isNaN(id)) {
                                    setShippingService(prevState => ({
                                        ...prevState,
                                        courierId: id,
                                    }))
                                }
                            }}
                        >
                            <option value="">-- select --</option>
                            {map(couriersList, (c, idx) => (
                                <option key={idx} value={c.courierId}>
                                    {c.name}
                                </option>
                            ))}
                        </select>
                    ) : isNil(find(couriersList, c => c.courierId === shippingService.courierId)) ? (
                        ''
                    ) : (
                        ` ${find(couriersList, c => c.courierId === shippingService.courierId).name}`
                    )}
                </label>
                {!isNil(errors.courierId) && !isEmpty(errors.courierId) && <p className="error">{errors.courierId}</p>}
            </div>
            <div className="shippingService-form-row">
                {mode === 'create' ? (
                    <div className="shippingService-form-row-checkboxes">
                        <b>Country:</b>
                        {map(
                            sortBy(countriesList, item => item.name),
                            (c, idx) => (
                                <label
                                    key={idx}
                                    className="shippingService-form-label"
                                    htmlFor={c.code}
                                    onClick={() => {
                                        setShippingService(prevState => ({
                                            ...prevState,
                                            destinationCountryCode: c.code,
                                        }))
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <input
                                        name={c.code}
                                        type="radio"
                                        value={c.code}
                                        checked={c.code === shippingService.destinationCountryCode}
                                        readOnly
                                    />
                                    {c.name}
                                </label>
                            )
                        )}
                    </div>
                ) : (
                    <label className="shippingService-form-label">
                        <b>Country:</b>
                        {isNil(find(countriesList, c => c.code === shippingService.destinationCountryCode))
                            ? ''
                            : ` ${find(countriesList, c => c.code === shippingService.destinationCountryCode).name}`}
                    </label>
                )}
                {!isNil(errors.destinationCountryCode) && !isEmpty(errors.destinationCountryCode) && (
                    <p className="error">{errors.destinationCountryCode}</p>
                )}
            </div>
            <div className="shippingService-form-row">
                <label className="shippingService-form-label" htmlFor="courierServiceId">
                    Service Id:
                    <input
                        type="text"
                        value={shippingService.courierServiceId}
                        name="courierServiceId"
                        onChange={e => {
                            const id = Number(e.target.value)
                            if (isNumber(id) && !isNaN(id)) {
                                setShippingService(prevState => ({
                                    ...prevState,
                                    courierServiceId: id,
                                }))
                            }
                        }}
                    />
                </label>
                {!isNil(errors.courierServiceId) && !isEmpty(errors.courierServiceId) && (
                    <p className="error">{errors.courierServiceId}</p>
                )}
            </div>
            <div className="shippingService-form-row">
                <label className="shippingService-form-label" htmlFor="freeShippingThresholdEnabled">
                    <input
                        type="checkbox"
                        value={shippingService.freeShippingThresholdEnabled}
                        checked={shippingService.freeShippingThresholdEnabled}
                        name="freeShippingThresholdEnabled"
                        onChange={() => {
                            setShippingService(prevState => ({
                                ...prevState,
                                freeShippingThresholdEnabled: !prevState.freeShippingThresholdEnabled,
                            }))
                        }}
                    />
                    Free shipping enabled
                </label>
                <label className="shippingService-form-label" htmlFor="courierServiceFreeShippingThreshold">
                    Free shipping threshold [EUR without VAT]:
                    <input
                        type="text"
                        disabled={!shippingService.freeShippingThresholdEnabled}
                        value={shippingService.courierServiceFreeShippingThreshold}
                        name="courierServiceFreeShippingThreshold"
                        onChange={e => {
                            const freeShippingThreshold = Number(e.target.value)
                            if (isNumber(freeShippingThreshold) && !isNaN(freeShippingThreshold)) {
                                setShippingService(prevState => ({
                                    ...prevState,
                                    courierServiceFreeShippingThreshold: freeShippingThreshold,
                                }))
                            }
                        }}
                    />
                </label>
            </div>
            <div className="shippingService-form-row">
                <label className="shippingService-form-label" htmlFor="weightFilterEnabled">
                    <input
                        type="checkbox"
                        value={shippingService.weightFilterEnabled}
                        checked={shippingService.weightFilterEnabled}
                        name="weightFilterEnabled"
                        onChange={() => {
                            setShippingService(prevState => ({
                                ...prevState,
                                weightFilterEnabled: !prevState.weightFilterEnabled,
                            }))
                        }}
                    />
                    Weight filter enabled
                </label>
                <label className="shippingService-form-label" htmlFor="weightValidFrom">
                    Valid from weight (kg) (included):
                    <input
                        type="text"
                        value={shippingService.weightValidFrom}
                        disabled={!shippingService.weightFilterEnabled}
                        name="weightValidFrom"
                        onChange={e => {
                            const value = e.target.value
                            setShippingService(prevState => ({
                                ...prevState,
                                weightValidFrom: value,
                            }))
                        }}
                    />
                </label>
                <label className="shippingService-form-label" htmlFor="weightValidTo">
                    Valid to weight (kg) (included):
                    <input
                        type="text"
                        value={shippingService.weightValidTo}
                        disabled={!shippingService.weightFilterEnabled}
                        name="weightValidTo"
                        onChange={e => {
                            const value = e.target.value
                            setShippingService(prevState => ({
                                ...prevState,
                                weightValidTo: value,
                            }))
                        }}
                    />
                </label>
                {!isNil(errors.weightValidFrom) && !isEmpty(errors.weightValidFrom) && (
                    <p className="error">{errors.weightValidFrom}</p>
                )}
                {!isNil(errors.weightValidFrom) && !isEmpty(errors.weightValidFrom) && (
                    <p className="error">{errors.weightValidFrom}</p>
                )}
                {!isNil(errors.weightValidRange) && !isEmpty(errors.weightValidRange) && (
                    <p className="error">{errors.weightValidRange}</p>
                )}
            </div>
            <div className="shippingService-form-row">
                <label className="shippingService-form-label" htmlFor="priceFilterEnabled">
                    <input
                        type="checkbox"
                        value={shippingService.priceFilterEnabled}
                        checked={shippingService.priceFilterEnabled}
                        name="priceFilterEnabled"
                        onChange={() => {
                            setShippingService(prevState => ({
                                ...prevState,
                                priceFilterEnabled: !prevState.priceFilterEnabled,
                            }))
                        }}
                    />
                    Price filter enabled
                </label>
                <label className="shippingService-form-label" htmlFor="priceValidFrom">
                    Valid from cart price (EUR without VAT) (included):
                    <input
                        type="text"
                        value={shippingService.priceValidFrom}
                        disabled={!shippingService.priceFilterEnabled}
                        name="priceValidFrom"
                        onChange={e => {
                            const value = e.target.value
                            setShippingService(prevState => ({
                                ...prevState,
                                priceValidFrom: value,
                            }))
                        }}
                    />
                </label>
                <label className="shippingService-form-label" htmlFor="priceValidTo">
                    Valid to cart price (EUR without VAT) (included):
                    <input
                        type="text"
                        value={shippingService.priceValidTo}
                        disabled={!shippingService.priceFilterEnabled}
                        name="priceValidTo"
                        onChange={e => {
                            const value = e.target.value
                            setShippingService(prevState => ({
                                ...prevState,
                                priceValidTo: value,
                            }))
                        }}
                    />
                </label>
                {!isNil(errors.priceValidFrom) && !isEmpty(errors.priceValidFrom) && (
                    <p className="error">{errors.priceValidFrom}</p>
                )}
                {!isNil(errors.priceValidTo) && !isEmpty(errors.priceValidTo) && (
                    <p className="error">{errors.priceValidTo}</p>
                )}
                {!isNil(errors.priceValidRange) && !isEmpty(errors.priceValidRange) && (
                    <p className="error">{errors.priceValidRange}</p>
                )}
            </div>
            <div className="shippingService-form-row">
                <label className="shippingService-form-label" htmlFor="courierServiceEstimatedDeliveryDays">
                    Estimated delivery days:
                    <input
                        type="text"
                        value={shippingService.courierServiceEstimatedDeliveryDays}
                        name="courierServiceEstimatedDeliveryDays"
                        onChange={e => {
                            const days = Number(e.target.value)
                            if (isNumber(days) && !isNaN(days)) {
                                setShippingService(prevState => ({
                                    ...prevState,
                                    courierServiceEstimatedDeliveryDays: days,
                                }))
                            }
                        }}
                    />
                </label>
                {!isNil(errors.courierServiceEstimatedDeliveryDays) &&
                    !isEmpty(errors.courierServiceEstimatedDeliveryDays) && (
                        <p className="error">{errors.courierServiceEstimatedDeliveryDays}</p>
                    )}
            </div>
            <div className="shippingService-form-row">
                <label className="shippingService-form-label" htmlFor="courierServicePercentageIncrease">
                    Percentage increase:
                    <input
                        type="text"
                        value={shippingService.courierServicePercentageIncrease}
                        name="courierServicePercentageIncrease"
                        onChange={e => {
                            const percentageIncrease = Number(e.target.value)
                            if (isNumber(percentageIncrease) && !isNaN(percentageIncrease)) {
                                setShippingService(prevState => ({
                                    ...prevState,
                                    courierServicePercentageIncrease: percentageIncrease,
                                }))
                            }
                        }}
                    />
                </label>
            </div>
            <div className="shippingService-form-row full">
                <label
                    className="shippingService-form-label"
                    htmlFor="courierServiceDepotPickupAvailable"
                    onClick={() => {
                        setShippingService(prevState => ({
                            ...prevState,
                            courierServiceDepotPickupAvailable: !prevState.courierServiceDepotPickupAvailable,
                        }))
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    <input
                        type="checkbox"
                        name="courierServiceDepotPickupAvailable"
                        checked={shippingService.courierServiceDepotPickupAvailable}
                        readOnly
                    />
                    Depot pickup available
                </label>
            </div>
            <div className="shippingService-form-row full">
                <b>Service names:</b>
                <div className="shippingService-form-row-names">
                    {map(shippingService.courierServiceNames, (n, idx) => (
                        <fieldset key={idx}>
                            <legend>{n.culture}</legend>
                            <label className="shippingService-form-label" htmlFor={`${n.culture}-courierServiceName`}>
                                Name:
                                <input
                                    type="text"
                                    name={`${n.culture}-courierServiceName`}
                                    value={n.name}
                                    onChange={e => {
                                        const value = e.target.value
                                        updateServiceName(n.culture, value)
                                    }}
                                />
                            </label>
                            <label
                                className="shippingService-form-label"
                                htmlFor={`${n.culture}-courierServiceDeliveryInfo`}
                            >
                                Delivery info:
                                <input
                                    type="text"
                                    name={`${n.culture}-courierServiceDeliveryInfo`}
                                    value={isNil(n.deliveryInfo) ? '' : n.deliveryInfo}
                                    onChange={e => {
                                        const value = e.target.value
                                        updateServiceDeliveryInfos(n.culture, value)
                                    }}
                                />
                            </label>
                            {!isNil(errors.courierServiceNames) &&
                                !isEmpty(errors.courierServiceNames) &&
                                !isNil(errors.courierServiceNames[n.culture]) &&
                                !isEmpty(errors.courierServiceNames[n.culture]) && (
                                    <p className="error">{errors.courierServiceNames[n.culture]}</p>
                                )}
                        </fieldset>
                    ))}
                </div>
            </div>
            <div className="shippingService-form-row full">
                {!isNil(shippingService.logoFile) ||
                !(isNil(shippingService.logoUrl) || isEmpty(shippingService.logoUrl)) ? (
                    <>
                        <p>
                            <strong>Shipping service logo:</strong>
                        </p>
                        {preview(
                            isNil(shippingService.logoFile)
                                ? { name: shippingService.logoUrl, preview: shippingService.logoUrl }
                                : shippingService.logoFile
                        )}
                    </>
                ) : (
                    <Dropzone>
                        {({ getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections }) => {
                            let styles = { ...baseStyle }
                            styles = isDragActive ? { ...styles, ...activeStyle } : styles
                            styles = isDragReject ? { ...styles, ...rejectStyle } : styles

                            return (
                                <>
                                    <div {...getRootProps()} style={styles}>
                                        <input {...getInputProps()} />
                                        <h3>Upload shipping service logo!</h3>
                                        <p>JPG or GIF only</p>
                                        <div>{isDragAccept ? 'Drop' : 'Drag'} files here...</div>
                                        {isDragReject && <div>Unsupported file type...</div>}
                                    </div>
                                    {!isEmpty(fileRejections) && showImageErrors(fileRejections)}
                                </>
                            )
                        }}
                    </Dropzone>
                )}
            </div>
            <div className="shippingService-form-row full">
                <Button
                    text={buttonText[mode]}
                    type="button"
                    onClick={async () => {
                        submit()
                    }}
                />
                <Link to={url}>
                    <Button text="Cancel" type="button" />
                </Link>
            </div>
            <Modal isOpen={isSubmitSuccess} contentLabel="Success modal" className="Modal" overlayClassName="Overlay">
                <p>Operation successful.</p>
                <Link to={url}>
                    <Button text="Back to shipping services list" type="button" onClick={updateList} />
                </Link>
            </Modal>
        </form>
    )
}

export default ShippingServiceForm
