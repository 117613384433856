import React from 'react'

import '../Footer/footer.scss'
import config from '../../../config'

const Footer = () => (
    <footer>
        <p className="small">YABB © {config.getTeamName()}</p>
    </footer>
)

export default Footer
