import React from 'react'

import Review from '../Review/Review.jsx'
import '../ReviewList/ReviewList.scss'

const ReviewList = ({
    list,
    culture,
    deleteReviewHandler,
    answerReviewHandler,
    getUserInfoHandler,
    approveReviewHandler,
    openImageHandler,
}) => {
    const Reviews = list.map(review => {
        return (
            <li className="reviewList" key={review.id}>
                <Review
                    review={review}
                    culture={culture}
                    deleteReviewHandler={deleteReviewHandler}
                    answerReviewHandler={answerReviewHandler}
                    getUserInfoHandler={getUserInfoHandler}
                    approveReviewHandler={approveReviewHandler}
                    openImageHandler={openImageHandler}
                />
            </li>
        )
    })

    return (
        <div>
            <ul>{Reviews}</ul>
        </div>
    )
}

export default ReviewList
