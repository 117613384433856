import React from 'react'
import ReactModal from 'react-modal'
import getDisplayName from '../../../helpers/getDisplayName.js'

export default function withCloseOnOutside(WrappedComponent, selectData) {
    class ModalWithCloseOnOutside extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                isOpen: selectData(props),
            }

            this.handleOpenModal = this.handleOpenModal.bind(this)
            this.handleCloseModal = this.handleCloseModal.bind(this)
        }

        handleOpenModal() {
            this.setState({ isOpen: true })
        }

        handleCloseModal() {
            this.setState({ isOpen: false })
        }

        componentDidUpdate(prevProps, prevState) {
            if (prevState.isOpen !== prevProps.isOpen) {
                this.setState({ isOpen: prevProps.isOpen })
            }
        }

        render() {
            const open = this.state.isOpen
            return (
                <ReactModal
                    isOpen={open}
                    contentLabel="modal"
                    className="Modal"
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={true}
                >
                    <WrappedComponent data={this.state.data} {...this.props} />
                </ReactModal>
            )
        }
    }

    ModalWithCloseOnOutside.displayName = `ModalWithCloseOnOutside(${getDisplayName(WrappedComponent)})`
    return ModalWithCloseOnOutside
}
