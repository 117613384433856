import { delay } from 'redux-saga'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import * as actions from './infobox.actions'
import * as commonActions from '../common/common.actions'
import * as userActions from '../user/user.actions'
import { ACTION_TYPES } from './infobox.actions'
import infobox from '../../model/infobox'
import * as modalActions from '../modal/modal.actions'
import modal from '../modal/modal.types'
import { history } from '../../helpers/history'
import ServerError from '../../errors/ServerError'
import messages from '../../helpers/messages'

export function* fetchInfoboxes(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const infoboxes = yield call(infobox.getInfoboxes, action.payload)
        yield put(actions.infoboxesReceived(infoboxes))
        yield put(modalActions.hideModal())
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export function* saveEditInfobox(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        const editedInfobox = yield call(infobox.putInfobox, action.payload.infoboxId, action.payload.infobox)
        yield put(actions.saveEditInfobox(editedInfobox))
        yield put(actions.cancelEditInfobox(editedInfobox.id))
        yield put(modalActions.hideModal())
        yield put(commonActions.showMessage(messages.getModifiedSuccessMessage('Infobox')))

        yield delay(2000)
        yield put(actions.readInfoboxes(action.payload.culture))
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

function* incorrectDate() {
    try {
        yield put(commonActions.showMessage(messages.MESSAGE_INCORRECT_DATE))
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export function* saveNewInfobox(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        yield call(infobox.postInfobox, action.payload.infobox)
        // const newInfobox = yield call(infobox.postInfobox, action.payload.infobox)
        // yield put(actions.saveNewInfobox(newInfobox))
        history.push('/infobox')

        yield put(commonActions.showMessage(messages.getCreatedSuccessMessage('Infobox')))
        yield put(modalActions.hideModal())

        yield delay(2000)
        yield put(actions.readInfoboxes(action.payload.culture))
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export function* deleteInfobox(action) {
    try {
        yield put(modalActions.showModal(modal.types.LOADING))
        yield call(infobox.deleteInfobox, action.payload.infoboxId)
        yield put(modalActions.hideModal())
        history.push('/infobox')
        yield put(commonActions.showMessage(messages.getDeletedSuccessMessage('Infobox')))
        yield delay(2000)
        yield put(actions.readInfoboxes(action.payload.culture))
    } catch (e) {
        yield put(modalActions.hideModal())
        if (e instanceof ServerError && e.response.status == 401) {
            yield put(userActions.logoutRequest())
            yield put(commonActions.showMessage(messages.MESSAGE_UNAUTHORIZED))
        } else yield put(commonActions.showMessage(messages.MESSAGE_ERROR_GENERIC))
    }
}

export default function*() {
    yield takeLatest(ACTION_TYPES.READ_INFOBOXES, fetchInfoboxes)
    yield takeEvery(ACTION_TYPES.SAVE_EDIT_INFOBOX_REQUEST, saveEditInfobox)
    yield takeEvery(ACTION_TYPES.INCORRECT_DATE, incorrectDate)
    yield takeEvery(ACTION_TYPES.SAVE_NEW_INFOBOX_REQUEST, saveNewInfobox)
    yield takeEvery(ACTION_TYPES.DELETE_INFOBOX_REQUEST, deleteInfobox)
}
