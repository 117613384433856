import { find, forEach, isNil } from 'lodash'
import shippingServices from '../../model/shippingServices'

const getServiceName = (names, culture = 'it-IT') => {
    const name = find(names, n => n.culture === culture)
    if (!isNil(name)) {
        return name.name
    }

    return ''
}

const validateServiceNames = names => {
    let areNamesValid = true
    let errors = {}
    forEach(names, n => {
        const isNameValid = !isNil(n.name) && n.name !== ''
        areNamesValid = areNamesValid && isNameValid
        errors[n.culture] = isNameValid ? '' : 'Please enter a valid name'
    })

    return [areNamesValid, errors]
}

const validateService = service => {
    let isServiceValid = true
    const errors = {}

    if (service.courierId <= 0) {
        isServiceValid = false
        errors.courierId = 'Please select a courier.'
    }

    if (service.courierServiceId <= 0) {
        isServiceValid = false
        errors.courierServiceId = 'Please enter a courier service Id.'
    }

    if (isNil(service.destinationCountryCode) || service.destinationCountryCode === '') {
        isServiceValid = false
        errors.destinationCountryCode = 'Please select a country.'
    }

    if (service.courierServiceEstimatedDeliveryDays <= 0) {
        isServiceValid = false
        errors.courierServiceEstimatedDeliveryDays = 'Please enter a valid number of days.'
    }

    if (isNil(service.weightValidFrom) || service.weightValidFrom === '' || isNaN(Number(service.weightValidFrom))) {
        isServiceValid = false
        errors.weightValidFrom = 'Please enter a valid number. Use a decimal dot (".") instead of a comma (",").'
    }
    if (isNil(service.weightValidTo) || service.weightValidTo === '' || isNaN(Number(service.weightValidTo))) {
        isServiceValid = false
        errors.weightValidTo = 'Please enter a valid number. Use a decimal dot (".") instead of a comma (",").'
    }
    if (service.weightValidFrom > service.weightValidTo) {
        isServiceValid = false
        errors.weightValidRange = 'validFrom value cannot be greater than validTo value.'
    }

    if (isNil(service.priceValidFrom) || service.priceValidFrom === '' || isNaN(Number(service.priceValidFrom))) {
        isServiceValid = false
        errors.priceValidFrom = 'Please enter a valid number. Use a decimal dot (".") instead of a comma (",").'
    }
    if (isNil(service.priceValidTo) || service.priceValidTo === '' || isNaN(Number(service.priceValidTo))) {
        isServiceValid = false
        errors.priceValidTo = 'Please enter a valid number. Use a decimal dot (".") instead of a comma (",").'
    }
    if (service.priceValidFrom > service.priceValidTo) {
        isServiceValid = false
        errors.priceValidRange = 'validFrom value cannot be greater than validTo value.'
    }

    const [namesValid, namesErrors] = validateServiceNames(service.courierServiceNames)
    isServiceValid = isServiceValid && namesValid
    errors.courierServiceNames = namesErrors
    return [isServiceValid, errors]
}

const submitShippingService = async (service, endpoint, method) => {
    const [isValid, errors] = validateService(service)
    if (isValid) {
        service.weightValidFrom = Number(service.weightValidFrom)
        service.weightValidTo = Number(service.weightValidTo)
        service.priceValidFrom = Number(service.priceValidFrom)
        service.priceValidTo = Number(service.priceValidTo)

        return shippingServices.submit(service, endpoint, method)
    }

    return errors
}

export { getServiceName, submitShippingService }
