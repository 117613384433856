import React from 'react'
import { connect } from 'react-redux'

import * as actions from '../../../redux/common/common.actions'
import Button from '../../atoms/Button/Button.jsx'
import './message.scss'

const Message = ({ message, hide }) => (
    <div className="message">
        <span>{message}</span>
        <Button onClick={hide} text="Close" />
    </div>
)

const mapStateToProps = state => ({
    message: state.common.message,
})

const mapDispatchToProps = dispatch => ({
    hide: () => dispatch(actions.hideMessage()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Message)
