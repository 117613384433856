/* eslint-disable no-class-assign */
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { reduce, filter } from 'lodash'

import * as actions from '../../../redux/categoryBanner/categoryBanner.actions'
import Button from '../../atoms/Button/Button.jsx'
import FreestyleTemplate from '../../organisms/FreestyleTemplate/FreestyleTemplate.jsx'
import BaseBannerCategoryTemplate from '../../organisms/BaseBannerCategoryTemplate/BaseBannerCategoryTemplate.jsx'
import SingleBannerCategoryTemplate from '../../organisms/SingleBannerCategoryTemplate/SingleBannerCategoryTemplate.jsx'
import './CategoryBannerForm.scss'
import { availableTemplates } from '../../../../src/model/availableTemplates'

class CategoryBannerForm extends React.Component {
    constructor(props) {
        super(props)
        if (this.isEdit()) {
            this.state = {
                startDate: props.selectedbannercategory.startDate,
                endDate: props.selectedbannercategory.endDate,
                selectedcategory: this.props.selectedcategory,
                culture: this.props.culture,
                title: props.selectedbannercategory.title,
                selectedTemplateId: props.selectedbannercategory.templateId,
                html: props.selectedbannercategory.html,
                files: props.selectedbannercategory.files,
                images: [],
            }
        } else {
            const momentNow = moment()
                .add(2, 'minutes')
                .toISOString()
            const momentTomorrow = moment()
                .add(1, 'days')
                .toISOString()
            this.state = {
                startDate: momentNow,
                endDate: momentTomorrow,
                selectedcategory: this.props.selectedcategory,
                culture: this.props.culture,
                title: `${this.props.selectedcategory.slug}_${momentNow}`,
                selectedTemplateId: availableTemplates['none'].id,
                html: '',
                files: [],
                images: [],
            }
        }
    }

    onStartDateChange = date =>
        this.setState(prevState => ({
            ...prevState,
            startDate: date.format(),
        }))

    onEndDateChange = date =>
        this.setState(prevState => ({
            ...prevState,
            endDate: date.format(),
        }))

    handleSave = values => {
        let dataToPost = {
            templateId: parseInt(values.selectedTemplateId),
            categoryId: this.state.selectedcategory.value,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            culture: this.state.culture,
            html: this.state.html,
            images: this.state.files,
            title: values.title,
        }

        if (this.props.selectedbannercategory !== undefined) {
            dataToPost.id = this.props.selectedbannercategory.id
            dataToPost.templateId = this.state.selectedTemplateId
            this.props.saveEditHandle(dataToPost, this.state.culture)
        } else {
            this.props.saveNewHandle(dataToPost, this.state.culture)
        }
    }

    renderHiddenInput({ input }) {
        return (
            <div>
                <input {...input} type="hidden" />
            </div>
        )
    }

    renderInput({ input, disabled, label, type, meta: { touched, error, warning } }) {
        return (
            <div>
                <label>{label}:</label>
                <input {...input} type={type} placeholder={label} disabled={disabled} />
                <div className="validationError">
                    {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
                </div>
            </div>
        )
    }
    isEdit = () => this.props.selectedbannercategory !== undefined

    componentDidMount() {
        if (this.isEdit()) {
            let bannerCategory = this.props.selectedbannercategory
            this.props.change('title', bannerCategory.title)
            this.props.change('startDate', bannerCategory.startDate)
            this.props.change('endDate', bannerCategory.endDate)
        }
        this.props.change('title', this.state.title)
    }

    renderDate({ input, disabled, selectedDate, onDateChange, label }) {
        return (
            <div>
                <label>{label}:</label>
                <DatePicker
                    name={input.name}
                    className="calendar"
                    todayButton={'Today'}
                    selected={selectedDate}
                    onChange={onDateChange}
                    disabled={disabled}
                    utcOffset={0}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="lll"
                    withPortal
                    shouldCloseOnSelect={false}
                />
            </div>
        )
    }

    renderTemplateSelection(hasTemplateSelected) {
        if (this.isEdit()) return <div />

        const templateOptions = reduce(
            filter(availableTemplates, t => t.id !== 0),
            (acc, item, index) => {
                acc.push(
                    <label key={index} style={{ display: 'flex' }}>
                        <span style={{ width: '40%' }}>{item.description}</span>
                        <Field
                            style={{
                                marginTop: '2vh',
                            }}
                            name="selectedTemplateId"
                            component="input"
                            type="radio"
                            value={item.id.toString()}
                        />{' '}
                    </label>
                )
                return acc
            },
            []
        )
        return (
            <div>
                {parseInt(this.props.templateSelectedId) === 1 ? (
                    ''
                ) : (
                    <div className="validationError">
                        <span>Please remember to upload all banners on Amazon S3.</span>
                    </div>
                )}
                {templateOptions}
                {hasTemplateSelected ? (
                    ''
                ) : (
                    <div className="validationError">
                        <span>Select a template</span>
                    </div>
                )}
            </div>
        )
    }

    onConfirmTemplate = ({ html, files = [] }) => {
        if (html !== '' && html !== undefined && this.state.html !== html) {
            this.setState(prevState => {
                return { ...prevState, html: html, files: files }
            })
        }
    }

    render() {
        const { handleSubmit, submitting, hasTemplateSelected } = this.props
        const cancelButton = () => (
            <Link to="/categorybanners">
                <Button text="Cancel" />
            </Link>
        )

        const renderTemplate = ({ hasTemplateSelected }) => {
            const renderCategoryBannerTemplate = templateId => {
                if (parseInt(templateId) === 1) {
                    return (
                        <FreestyleTemplate
                            onChangeTemplate={({ html }) => this.onConfirmTemplate({ html, files: [] })}
                            files={this.state.files}
                        />
                    )
                }
                if (parseInt(templateId) === 2) {
                    return (
                        <BaseBannerCategoryTemplate
                            onChangeTemplate={({ html, files }) => this.onConfirmTemplate({ html, files })}
                            _files={this.state.files}
                        />
                    )
                }
                if (parseInt(templateId) === 3) {
                    return (
                        <SingleBannerCategoryTemplate
                            onChangeTemplate={({ html, files }) => this.onConfirmTemplate({ html, files })}
                            files={this.state.files}
                        />
                    )
                }
            }
            if (this.isEdit()) {
                return (
                    <div className="banner__details">
                        <h3>Preview:</h3>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.props.selectedbannercategory.html,
                            }}
                        />
                        <div>
                            <h3>Raw:</h3>
                            <textarea disabled style={{ minHeight: '400px' }}>
                                {this.props.selectedbannercategory.html}
                            </textarea>
                        </div>
                    </div>
                )
            }
            return hasTemplateSelected ? (
                renderCategoryBannerTemplate(this.props.templateSelectedId)
            ) : (
                <h2>Select a template</h2>
            )
        }

        const momentDate = this.state.startDate ? moment(this.state.startDate).utc() : null
        const momentDateLocal = momentDate ? ' (' + moment(this.state.startDate).format('LT') + ')' : ''
        const endDate = this.state.endDate ? moment(this.state.endDate).utc() : null
        const endDateLocal = endDate ? ' (' + moment(this.state.endDate).format('LT') + ')' : ''

        return (
            <div className="banner categoryBanner">
                <div>{renderTemplate({ hasTemplateSelected: this.props.hasTemplateSelected })}</div>
                <div className="banner__details">
                    <div className="list">
                        <form onSubmit={handleSubmit(this.handleSave)}>
                            <ul>
                                <li>
                                    <h2>{this.state.selectedcategory.title}</h2>
                                    <h3>{this.state.selectedcategory.slug}</h3>
                                </li>
                                <li>{this.renderTemplateSelection(hasTemplateSelected)}</li>
                                <li>
                                    <Field
                                        name="title"
                                        type="text"
                                        placeholder="Title"
                                        component={this.renderInput}
                                        label="Title"
                                    />
                                </li>
                                <li>
                                    <Field name="html" type="hidden" component={this.renderHiddenInput} />
                                </li>
                                <li>
                                    <Field
                                        name="startDate"
                                        type="date"
                                        placeholder="Start date"
                                        component={this.renderDate}
                                        label={`Start date ${momentDateLocal}`}
                                        props={{
                                            disabled: this.props.startDate !== undefined,
                                            selectedDate: momentDate,
                                            onDateChange: this.onStartDateChange,
                                        }}
                                    />
                                </li>
                                <li>
                                    <Field
                                        name="endDate"
                                        type="date"
                                        placeholder="End date"
                                        component={this.renderDate}
                                        label={`End date ${endDateLocal}`}
                                        props={{
                                            disabled: this.props.endDate !== undefined,
                                            selectedDate: endDate,
                                            onDateChange: this.onEndDateChange,
                                        }}
                                    />
                                </li>
                            </ul>

                            <div className="button-container">
                                <Button text="Save" type="submit" disabled={submitting} />
                                {cancelButton()}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    saveEditHandle: (categoryBanner, culture) => dispatch(actions.saveEditBannerRequest(categoryBanner, culture)),
    saveNewHandle: (categoryBanner, culture) => dispatch(actions.saveNewBannerRequest(categoryBanner, culture)),
})

const validate = values => {
    const errors = {}
    if (!values.title) {
        errors.title = 'Required'
    }
    if (values.selectedTemplateId === undefined || values.selectedTemplateId === '0') {
        errors.selectedTemplateId = 'selectedTemplateId'
    }
    return errors
}

const selector = formValueSelector('categoryBannerForm')
CategoryBannerForm = connect(state => {
    const validTemplate = selector(state, 'selectedTemplateId')
    return {
        templateSelectedId: validTemplate !== undefined && parseInt(validTemplate) !== 0 ? parseInt(validTemplate) : 0,
        hasTemplateSelected: validTemplate !== undefined && parseInt(validTemplate) !== 0,
    }
}, mapDispatchToProps)(CategoryBannerForm)

export default reduxForm({
    form: 'categoryBannerForm',
    validate,
})(CategoryBannerForm)
