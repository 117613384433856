import React from 'react'
import moment from 'moment'

import UrlHelper from '../../../helpers/urlHelper.js'
import Button from '../../atoms/Button/Button.jsx'
import Answer from '../../molecules/Answers/Answer.jsx'
import AnswerForm from '../../molecules/Answers/AnswerForm.jsx'
import ImageClickable from '../../molecules/Images/ImageClickable.jsx'

import '../Question/Question.scss'

const Question = ({
    culture,
    question,
    deleteQuestionHandler,
    deleteAnswerHandler,
    answerQuestionHandler,
    approveAnswerHandler,
    getUserInfoHandler,
    openImageHandler,
}) => {
    const momentDate = moment(question.creationDate).format('lll')
    const images = question.images.map(image => (
        <ImageClickable key={image} filename={image} onOpen={openImageHandler} />
    ))
    const answers = question.answers.map(answer => {
        return (
            <li key={answer.id}>
                <Answer
                    answer={answer}
                    questionId={question.id}
                    deleteAnswerHandler={deleteAnswerHandler}
                    approveAnswerHandler={approveAnswerHandler}
                    getUserInfoHandler={getUserInfoHandler}
                />
            </li>
        )
    })
    const answersId = `${question.id}-answer`
    return (
        <div className="questionContainer" key={question.id}>
            <div className="question">
                <div className="info">
                    <Button text={question.userNickName} onClick={() => getUserInfoHandler(question.userId)} />
                    <h4>{momentDate}</h4>
                </div>
                <div className="description">
                    <div>
                        <a
                            href={UrlHelper.getProductLink(culture, question.productSlug)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h3>{question.productTitle}</h3>
                        </a>
                    </div>
                    <p>
                        <strong>{question.description}</strong>
                    </p>
                    <div className="images">{images}</div>
                </div>
                <div className="actions">
                    <a href={'#' + answersId}>{'Answers (' + question.answers.length + ') '}</a>

                    <Button text="Delete" onClick={() => deleteQuestionHandler(question.id)} />
                </div>
            </div>
            <AnswerForm culture={culture} answerQuestionHandler={answerQuestionHandler} questionId={question.id} />
            <ul className="answersList" id={answersId}>
                <h3 className="answersTitle">{'Answers (' + question.answers.length + ')'}</h3>
                {answers}
            </ul>
        </div>
    )
}

export default Question
