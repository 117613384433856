import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'

import LoadingImage from '../../../../assets/img/Loading.svg'

const Loading = () => {
    return (
        <Modal isOpen={true} contentLabel="Loading modal" className="Modal" overlayClassName="Overlay">
            <img src={LoadingImage} />
        </Modal>
    )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(Loading)
