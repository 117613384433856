import { ACTION_TYPES } from './common.actions'

const INITIAL_STATE = {
    message: '',
    messageVisible: false,
    culture: 'it-IT',
}

const reducers = {}

reducers[ACTION_TYPES.SHOW_MESSAGE] = (state, action) => {
    return {
        ...state,
        message: action.payload,
        messageVisible: true,
    }
}

reducers[ACTION_TYPES.HIDE_MESSAGE] = state => {
    return {
        ...state,
        message: '',
        messageVisible: false,
    }
}

reducers[ACTION_TYPES.CHANGE_CULTURE] = (state, action) => {
    return {
        ...state,
        culture: action.payload,
    }
}

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type] || (() => state)
    return reducer(state, action)
}
