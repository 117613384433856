import config from '../../../config.js'
import React from 'react'
import { connect } from 'react-redux'
import { some } from 'lodash'
import * as actions from '../../../redux/infobox/infobox.actions'
import * as modalActions from '../../../redux/modal/modal.actions'
import modal from '../../../redux/modal/modal.types'
import Button from '../../atoms/Button/Button.jsx'
import colors from './infoboxColors.js'

import './Infobox.scss'

const Infobox = ({
    backgroundColorInfoSecondary,
    colorInfoSecondary,
    culture,
    id,
    infoSecondary,
    countdownTitle,
    validFrom,
    validTo,
    destinations,
    countries,
    handleEdit,
    handleDelete,
}) => {
    const formatDate = dateString => {
        const date = new Date(dateString)
        const dateArray = date.toDateString().split(' ')
        // format: Mon DD, YYYY HH:MM:SS.ms
        return (
            dateArray[1] +
            ' ' +
            dateArray[2] +
            ', ' +
            dateArray[3] +
            ' ' +
            date.toTimeString().split(' ')[0] +
            '.' +
            date.getMilliseconds()
        )
    }

    const status = () => {
        let activeStatus
        const now = new Date()

        const start = new Date(validFrom)
        const end = new Date(validTo)

        if (end >= now && start > now) {
            activeStatus = 'not-yet-active'
        } else if (end >= now && start <= now) {
            activeStatus = 'active'
        } else {
            activeStatus = 'inactive'
        }

        return <div className={`status ${activeStatus}`} />
    }

    const setDestinations = () => {
        let d = ''
        if (!destinations || destinations.length === 0 || some(destinations, d => !d)) {
            d = 'All.'
            return d
        }

        for (let item of destinations) {
            // item is the three letter code
            const found = countries.find(element => item === element.code)
            d += `${found.name},`
        }
        d = d.slice(0, d.length - 1).replaceAll(',', ', ')
        d += '.'
        return d
    }

    const textColorSecondary =
        colorInfoSecondary === '' ? colors[config.currentSite].textDefaultColor : '#' + colorInfoSecondary
    const bgColorSecondary =
        backgroundColorInfoSecondary === ''
            ? colors[config.currentSite].bgDefaultColor
            : '#' + backgroundColorInfoSecondary

    return (
        <>
            {status()}
            <div className="infobox-grid">
                <div className="infobox-date">
                    <p>
                        <strong>From:</strong> {formatDate(validFrom)}
                    </p>
                    <p>
                        <strong>To:</strong> {formatDate(validTo)}
                    </p>
                </div>
                <div className="infobox-infos">
                    <div className="infobox-info secondary">
                        <p>
                            <strong>Info secondary:</strong> {infoSecondary}
                        </p>
                        <p>
                            <strong>Countdown title:</strong> {countdownTitle}
                        </p>
                        <div className="colors">
                            <div className="color">
                                <p>
                                    <strong>Text color:</strong>: {textColorSecondary}
                                </p>
                                <div className="swatch" style={{ backgroundColor: textColorSecondary }} />
                            </div>
                            <div className="color">
                                <p>
                                    <strong>Background color:</strong> {bgColorSecondary}
                                </p>
                                <div className="swatch" style={{ backgroundColor: bgColorSecondary }} />
                            </div>
                        </div>
                    </div>
                    <div className="infobox-info destinations">
                        <p>
                            <strong>Destinations: </strong>
                            {setDestinations()}
                        </p>
                    </div>
                </div>
                <div className="infobox-buttons">
                    <Button text="Edit" onClick={() => handleEdit(id)} />
                    <Button text="Delete" onClick={() => handleDelete(id, culture)} />
                </div>
            </div>
        </>
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
    handleEdit: infoboxId => dispatch(actions.editInfobox(infoboxId)),
    handleDelete: (infoboxId, culture) =>
        dispatch(
            modalActions.showModal(modal.types.CONFIRM, {
                confirmHandler: () => dispatch(actions.deleteInfoboxRequest(infoboxId, culture)),
                cancelHandler: () => dispatch(modalActions.hideModal()),
            })
        ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Infobox)
