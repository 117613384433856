/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import { filter, find, forEach, includes, isEmpty, isNil, map, replace, sortBy } from 'lodash'
import zones from '../../../model/zones.js'
import { submitZone } from '../../../services/couriers/zones.js'
import Loading from '../Loading/Loading.jsx'
import Button from '../../atoms/Button/Button.jsx'

import './ZoneForm.scss'

const ZoneForm = ({ zoneId, mode, url, updateList, countries, couriers }) => {
    const emptyCourier = {
        name: '',
        courierId: 0,
        id: '',
        dtoGuid: '',
        logoUrl: '',
    }
    const [isLoading, setLoading] = useState(true)
    const [isSubmitSuccess, setSubmitSuccess] = useState(false)
    const [zone, setZone] = useState({
        name: '',
        courier: emptyCourier,
        countries: [],
    })
    const [errors, setErrors] = useState({})
    const [countriesToExclude, setCountriesToExclude] = useState([])
    const buttonText = {
        create: 'Create',
        edit: 'Save',
    }

    const submit = async () => {
        setLoading(true)
        let errors
        try {
            switch (mode) {
                case 'create':
                    errors = await submitZone(zone, 'create', 'POST')
                    break
                case 'edit':
                    errors = await submitZone(zone, `${zoneId}`, 'PUT')
                    break
                default:
                    break
            }

            setLoading(false)
            if (isEmpty(errors)) {
                setSubmitSuccess(true)
            }

            setErrors(errors)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const fetchZone = async zoneId => {
        try {
            const zone = await zones.getZone(zoneId)
            setZone({
                dtoGuid: zone.dtoGuid,
                id: zone.id,
                name: mode === 'create' ? `${zone.name}_copy` : zone.name,
                courier: zone.courier,
                countries: zone.countries,
            })
        } catch (error) {
            console.error(error)
        }
    }

    const updateCountries = value => {
        let newCountriesList = []
        if (includes(zone.countries, value)) {
            newCountriesList = filter(zone.countries, c => c !== value)
        } else {
            newCountriesList = zone.countries
            newCountriesList.push(value)
        }
        update(newCountriesList, 'countries')
    }

    const updateCourier = value => {
        const courier = value === '' ? emptyCourier : find(couriers, c => c.courierId === Number(value))

        if (!isNil(courier)) {
            update(courier, 'courier')
        }
    }

    const update = (value, key) => {
        const newState = {}

        forEach(zone, (v, k) => {
            newState[k] = k === key ? value : v
        })
        setZone(newState)
    }

    const formatErrors = errorMessage => {
        let message = errorMessage
        forEach(zone.countries, c => {
            const country = find(countries, ctry => ctry.code === c)
            if (!isNil(country)) {
                message = replace(message, c, country.name)
            }
        })
        return message
    }

    const updateCountriesToExclude = async courierId => {
        if (courierId === 0) {
            setCountriesToExclude([])
            return
        }
        const queryString = mode === 'edit' ? `?zoneId=${zoneId}` : ''
        try {
            setLoading(true)
            const countries = await zones.getCountriesToExclude(`${courierId}${queryString}`)
            setCountriesToExclude(countries)
        } catch (error) {
            setErrors(prevState => ({
                ...prevState,
                generic: error.response,
            }))
        }
        setLoading(false)
    }

    useEffect(() => {
        if (!isNil(zoneId)) {
            fetchZone(zoneId)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        updateCountriesToExclude(zone.courier.courierId)
    }, [zone.courier.courierId])

    return isLoading ? (
        <Loading />
    ) : (
        <form className="zone-form">
            {!isNil(errors.generic) && !isEmpty(errors.generic) && (
                <p className="error">{formatErrors(errors.generic)}</p>
            )}
            <div className="zone-form-row">
                <label className="zone-form-label" htmlFor="name">
                    Zone name:
                    <input
                        type="text"
                        value={zone.name}
                        name="name"
                        onChange={e => {
                            const value = e.target.value
                            update(value, 'name')
                        }}
                    />
                </label>
                {!isNil(errors.name) && !isEmpty(errors.name) && <p className="error">{errors.name}</p>}
            </div>
            <div className="zone-form-row">
                <label className="zone-form-label" htmlFor="courier">
                    Courier:
                    {mode === 'create' ? (
                        <select
                            name="courier"
                            onChange={e => {
                                const value = e.target.value
                                updateCourier(value)
                            }}
                            value={zone.courier.courierId}
                        >
                            <option value="">-- select --</option>
                            {map(couriers, (c, idx) => (
                                <option value={c.courierId} key={idx}>
                                    {c.name}
                                </option>
                            ))}
                        </select>
                    ) : (
                        ` ${zone.courier.name}`
                    )}
                </label>
                {!isNil(errors.courier) && !isEmpty(errors.courier) && <p className="error">{errors.courier}</p>}
            </div>
            <div className="zone-form-row">
                <div className="zone-form-row-countries">
                    <div className="zone-form-row-countries-item zone-form-row-checkboxes">
                        <p>
                            <strong>Countries:</strong>
                        </p>
                        {map(
                            sortBy(
                                filter(countries, x => !includes(countriesToExclude, x.code)),
                                item => item.name
                            ),
                            (c, idx) => (
                                <label
                                    key={idx}
                                    className="zone-form-label"
                                    htmlFor={c.code}
                                    onClick={() => updateCountries(c.code)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <input
                                        name={c.code}
                                        type="checkbox"
                                        checked={includes(zone.countries, c.code)}
                                        readOnly
                                    />
                                    {c.name}
                                </label>
                            )
                        )}
                    </div>
                    <div className="zone-form-row-countries-item">
                        <p>
                            <strong>Selected countries:</strong>
                        </p>
                        {!isNil(zone.countries) && !isEmpty(zone.countries) ? (
                            <>
                                <Button
                                    text="Clear selection"
                                    type="button"
                                    onClick={() => {
                                        setZone(prevState => ({
                                            ...prevState,
                                            countries: [],
                                        }))
                                    }}
                                />
                                <ul>
                                    {map(zone.countries, (c, idx) => (
                                        <li key={idx}>{find(countries, x => x.code === c).name}</li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <p>None.</p>
                        )}
                    </div>
                </div>
                {!isNil(errors.countries) && !isEmpty(errors.countries) && <p className="error">{errors.countries}</p>}
            </div>
            <Button
                text={buttonText[mode]}
                type="button"
                onClick={async () => {
                    submit()
                }}
            />
            <Link to={url}>
                <Button text="Cancel" type="button" />
            </Link>
            <Modal isOpen={isSubmitSuccess} contentLabel="Success modal" className="Modal" overlayClassName="Overlay">
                <p>Operation successful.</p>
                <Link to={url}>
                    <Button text="Back to zones list" type="button" onClick={updateList} />
                </Link>
            </Modal>
        </form>
    )
}

export default ZoneForm
