import config from '../config'
import { authHeader } from '../helpers/auth-header'
import ServerError from '../errors/ServerError'

const getShippingServices = () => {
    return fetch(`${config.apiRoot}/v1/shippingServices/`, {
        method: 'GET',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return r.json()
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

const getShippingService = shippingServiceId => {
    return fetch(`${config.apiRoot}/v1/shippingServices/${shippingServiceId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return r.json()
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

const submit = (shippingService, endpoint, method) => {
    return fetch(`${config.apiRoot}/v1/shippingServices/${endpoint}`, {
        method: method,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(shippingService),
    }).then(async r => {
        if (r.ok) {
            return {}
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            return resp
        }
        throw new ServerError(r)
    })
}

const deleteShippingService = shippingServiceId => {
    return fetch(`${config.apiRoot}/v1/shippingServices/${shippingServiceId}`, {
        method: 'DELETE',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return true
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

const getByServiceIds = serviceIds => {
    return fetch(`${config.apiRoot}/v1/shippingServices/getByIds?serviceIds=${serviceIds}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return r.json()
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

const getServicesNotInCatalogs = () => {
    return fetch(`${config.apiRoot}/v1/shippingServices/getServicesNotInCatalogs`, {
        method: 'GET',
        headers: authHeader(),
    }).then(async r => {
        if (r.ok) {
            return r.json()
        }
        if (r.status === 401) {
            alert('Unauthorized. Log out and log back in')
        } else if (r.status === 400) {
            const resp = await r.json()
            console.error(`An error occurred. Please try again. Error: ${resp.generic}`)
            throw new ServerError(resp.generic)
        }
        throw new ServerError(r)
    })
}

export default {
    getShippingServices,
    getShippingService,
    submit,
    deleteShippingService,
    getByServiceIds,
    getServicesNotInCatalogs,
}
