import Confirm from '../../components/molecules/Confirm/Confirm.jsx'
import ModalUser from '../../components/molecules/ModalUser/ModalUser.jsx'
import Loading from '../../components/molecules/Loading/Loading.jsx'
import ModalImage from '../../components/molecules/ModalImage/ModalImage.jsx'

const componentLookupTable = {
    Confirm,
    ModalUser,
    Loading,
    ModalImage,
}

const types = {
    CONFIRM: 'Confirm',
    MODAL_USER: 'ModalUser',
    LOADING: 'Loading',
    OPEN_IMAGE: 'ModalImage',
}

export default {
    componentLookupTable,
    types,
}
