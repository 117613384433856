import Modal from 'react-modal'
import 'regenerator-runtime/runtime'

import './style.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import { fork, all } from 'redux-saga/effects'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import { reducer as reduxFormReducer } from 'redux-form'

import App from './components/App.jsx'
import storage from './model/storage'

// =============== ADD REDUCERS!!! ===============
import common from './redux/common/common.reducers'
import categoryBanners from './redux/categoryBanner/categoryBanner.reducers'
import users from './redux/user/user.reducers'
import feedbacks from './redux/feedbacks/feedbacks.reducers'
import questionsAndAnswers from './redux/questionAndAnswers/questionAndAnswers.reducers'
import reviews from './redux/reviews/reviews.reducers'
import modals from './redux/modal/modal.reducers'
import destinationsNotes from './redux/destinations/destinations.reducers'
import reassortmentNotification from './redux/reassortmentNotifications/reassortmentNotifications.reducers'
import infobox from './redux/infobox/infobox.reducers'
import countries from './redux/countries/countries.reducers'
import returns from './redux/returns/returns.reducers'
import currencies from './redux/currencies/currencies.reducers'

// =============== ADD SAGAS!!! ===============
import categoryBannerSagas from './redux/categoryBanner/categoryBanner.sagas'
import userSagas from './redux/user/user.sagas'
import questionAndAnswersSagas from './redux/questionAndAnswers/questionAndAnswers.sagas'
import feedbacksSagas from './redux/feedbacks/feedbacks.sagas'
import destinationsSagas from './redux/destinations/destinations.sagas'
import reviewsSagas from './redux/reviews/reviews.sagas'
import reassortmentNotificationsSagas from './redux/reassortmentNotifications/reassortmentNotifications.sagas'
import infoboxSagas from './redux/infobox/infobox.sagas'
import countriesSagas from './redux/countries/countries.sagas'
import returnsSagas from './redux/returns/returns.sagas'
import currenciesSagas from './redux/currencies/currencies.sagas'

import storageMiddleware from './redux/storageMiddleware'
import { history } from './helpers/history'

const sagaMiddleware = createSagaMiddleware()

// =============== ADD SAGAS!!! ===============
const rootSaga = function*() {
    yield all([
        fork(categoryBannerSagas),
        fork(userSagas),
        fork(questionAndAnswersSagas),
        fork(feedbacksSagas),
        fork(destinationsSagas),
        fork(reviewsSagas),
        fork(reassortmentNotificationsSagas),
        fork(infoboxSagas),
        fork(countriesSagas),
        fork(returnsSagas),
        fork(currenciesSagas),
    ])
}

// =============== ADD REDUCERS!!! ===============
const reducers = combineReducers({
    categoryBanners,
    common,
    users,
    feedbacks,
    questionsAndAnswers,
    modals,
    destinationsNotes,
    reviews,
    form: reduxFormReducer,
    reassortmentNotification,
    infobox,
    countries,
    returns,
    currencies,
})

document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
        storage
            .get()
            .then(res => {
                res.modals = undefined
                storage.set(res).then(() => {
                    // window.location.reload()
                })
            })
            .catch(() => {
                window.location.reload()
            })
    }
})
storage.get().then(initialState => {
    const store = createStore(
        reducers,
        initialState,
        compose(
            applyMiddleware(sagaMiddleware, storageMiddleware, logger)
            //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    )

    sagaMiddleware.run(rootSaga)

    const app = (
        <Router history={history}>
            <Provider store={store}>
                <App />
            </Provider>
        </Router>
    )
    Modal.setAppElement('#root')
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(app, document.getElementById('root'))
})
